import {useRef} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {selectCurrentTheme, setThemeAsync, THEME_AUTO, THEME_DARK, THEME_LIGHT} from '../redux/themeSlice';

import {Menu} from "primereact/menu";
import {Button} from "primereact/button";
import {SvgIcon} from "../component/SvgIcon";


function ThemeSwitcher() {

    const dispatch = useDispatch();
    const {t} = useTranslation();

    const themeSwitcherMenu = useRef(null);

    /**
     * Current Theme Name: THEME_LIGHT / THEME_DARK / THEME_AUTO
     * @type {string}
     * */
    const currentTheme = useSelector(selectCurrentTheme);

    const themeItems = [
        {
            value: THEME_LIGHT,
            icon: "#sun-fill",
            label: t("theme.light")
        },
        {
            value: THEME_DARK,
            icon: "#moon-stars-fill",
            label: t("theme.dark")
        },
        {
            value: THEME_AUTO,
            icon: "#circle-half",
            label: t("theme.auto")
        }
    ];
    let currentThemeItem = themeItems.filter((item) => item.value === currentTheme)[0];
    if (!currentThemeItem) {
        console.error(`Invalid theme item: ${currentTheme}`);
        currentThemeItem = themeItems[0];
    }
    const currentThemeIcon = (<SvgIcon svgRef={currentThemeItem.icon}/>);

    const model = themeItems.map((item) => {
        return {
            label: item.label,
            icon: (<SvgIcon svgRef={item.icon} className="mr-2"/>),
            command: () => dispatch(setThemeAsync(item.value))
        };
    });

    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" className="hidden">
                <symbol id="circle-half" viewBox="0 0 16 16" fill="currentColor">
                    <path d="M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z"/>
                </symbol>
                <symbol id="moon-stars-fill" viewBox="0 0 16 16" fill="currentColor">
                    <path
                        d="M6 .278a.768.768 0 0 1 .08.858 7.208 7.208 0 0 0-.878 3.46c0 4.021 3.278 7.277 7.318 7.277.527 0 1.04-.055 1.533-.16a.787.787 0 0 1 .81.316.733.733 0 0 1-.031.893A8.349 8.349 0 0 1 8.344 16C3.734 16 0 12.286 0 7.71 0 4.266 2.114 1.312 5.124.06A.752.752 0 0 1 6 .278z"/>
                    <path
                        d="M10.794 3.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387a1.734 1.734 0 0 0-1.097 1.097l-.387 1.162a.217.217 0 0 1-.412 0l-.387-1.162A1.734 1.734 0 0 0 9.31 6.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387a1.734 1.734 0 0 0 1.097-1.097l.387-1.162zM13.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.156 1.156 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.156 1.156 0 0 0-.732-.732l-.774-.258a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732L13.863.1z"/>
                </symbol>
                <symbol id="sun-fill" viewBox="0 0 16 16" fill="currentColor">
                    <path
                        d="M8 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
                </symbol>
            </svg>
            <Menu id="themeSwitcherMenu" model={model} popup ref={themeSwitcherMenu} popupAlignment="right"/>
            <Button id="themeSwitcherButton" icon={currentThemeIcon} className="ml-1"
                    onClick={(e) => themeSwitcherMenu.current.toggle(e)}
                    aria-controls="themeSwitcherMenu" aria-haspopup text size="small"/>
        </>
    );
}

export default ThemeSwitcher;