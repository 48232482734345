import {useSelector} from "react-redux";
import {selectComparativeValueAvailable, selectComparativeValueData, selectLoading} from "../../redux/valueIndicationSlice";
import IndicationTable from "./IndicationTable";
import MissingDataIndicator from "../../component/MissingDataIndicator";
import LoadingIndicator from "../../component/LoadingIndicator";


export default function TabComparativeValue() {

    const comparativeValueData = useSelector(selectComparativeValueData);
    const comparativeValueAvailable = useSelector(selectComparativeValueAvailable);
    const loading = useSelector(selectLoading);

    return (
        <>
            <LoadingIndicator loading={loading} />
            <MissingDataIndicator dataAvailable={comparativeValueAvailable || loading}/>
            <IndicationTable endpoint="COMPARATIVE_VALUE" indicationDetails={comparativeValueData} />
        </>
    );

}