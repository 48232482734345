import I18nErrorMessage from "./I18nErrorMessage";

/**
 * Indicator, that an error occurred.
 * @see I18nErrorMessage
 */
function ErrorIndicator({enabled, titleI18nKey, i18nKey, i18nArgs, hasContactLink, errorObject}) {

    let i18nKeyEffective = i18nKey;

    if (typeof errorObject !== 'undefined') {
        if (typeof errorObject !== 'object' || errorObject === null) {
            // Error object was provided, but was empty - no error stored
            return null;
        }
        i18nKeyEffective = errorObject.error;

    } else if (typeof enabled !== 'undefined' && !enabled) {
        // No error object was provided, but an enabled-flag, which was not set
        return null;
    }

    if (!i18nKeyEffective) {
        // No error key was set
        return null;
    }

    return (
        <div className="flex-grow-1 flex flex-column justify-content-center align-items-center">
            <I18nErrorMessage enabled={enabled} titleI18nKey={titleI18nKey} i18nKey={i18nKey} i18nArgs={i18nArgs}
                              hasContactLink={hasContactLink} className="p-5" errorObject={errorObject} />
        </div>
    );
}

export default ErrorIndicator;