import {createSlice} from "@reduxjs/toolkit";

// TODO: todo: combine with OBJECT_TYPE (timelines)?

/**
 * @typedef {"WHG_K" | "EZFH_K" | "MFHWGH_K" | "GRST_K"} Segment
 */

/** @type {Segment} */
const DEFAULT_SEGMENT = "WHG_K";

export const objectParametersSlice = createSlice({
    name: 'objectParameters',
    initialState: {
        /** @type {Segment} */
        segment: DEFAULT_SEGMENT,
        /** @type {object} */
        params: {},
        /** @type {object} */
        metaParams: {}
    },
    reducers: {
        resetObjectParameters: (state) => {
            state.segment = DEFAULT_SEGMENT;
            state.params = {};
            state.metaParams = {};
        },
        setSegmentAndReset: (state, action) => {
            state.segment = action.payload.segment;
            state.params = {};
            state.metaParams = {};
        },
        setSingleParam: (state, action) => {
            const {value, parameter, meta} = action.payload;
            const valueMap = meta ? state.metaParams : state.params;
            if (typeof value === 'undefined' || value === null) {
                valueMap[parameter] = undefined;
            } else {
                valueMap[parameter] = value;
            }
        },
        setSegmentAndParameters: (state, action) => {
            state.segment = action.payload.segment;
            state.params = {...action.payload.params};
            state.metaParams = {}; // ???
            // TODO: Compute Meta-Params?
        }
    },
    selectors: {
        /** @return {string} */
        selectSegment: (state) => state.segment,
        /** @return {object} */
        selectAllParams: (state) => state.params,
        /**
         * Select single parameter or meta parameter value
         * @param state redux state
         * @param {string} parameter parameter name to query
         * @param {boolean} [meta] if true, use meta parameter
         * @return {*|null}
         */
        selectSingleParam: (state, parameter, meta) => {
            const currentValue = meta ? state.metaParams[parameter] : state.params[parameter];
            if (typeof currentValue === 'undefined') {
                return null;
            }
            return currentValue;
        },
        /**
         * Select single meta parameter value
         * @param state redux state
         * @param {string} parameter meta parameter name to query
         * @return {*|null}
         */
        selectSingleMetaParam: (state, parameter) => {
            const currentValue = state.metaParams[parameter];
            if (typeof currentValue === 'undefined') {
                return null;
            }
            return currentValue;
        }
    }
})

export const {
    resetObjectParameters,
    setSegmentAndReset,
    setSingleParam,
    setSegmentAndParameters
} = objectParametersSlice.actions;

export const {
    selectSegment,
    selectAllParams,
    selectSingleParam
} = objectParametersSlice.selectors;

/**
 * Get selector for a single/specific parameter
 * @param {string} parameter parameter name to query
 * @param {boolean} [meta] if true, use meta parameter
 * @return {(state: any) => *} selector function
 */
export const selectSingleParamCurried = (parameter, meta) => (state) => selectSingleParam(state, parameter, meta);

export default objectParametersSlice.reducer;
