/**
 * Convert Snake Case (e.g. "param_name") to Camel Case (e.g. "paramName")
 * @param {string} str input string in Snake Case
 * @return {string} output string in Camel Case
 */
export const snakeToCamel = str => {
    if (!str || typeof str !== 'string')
        return str;

    return str.toLowerCase().replace(/([-_][a-z])/g, group =>
        group
            .toUpperCase()
            .replace('-', '')
            .replace('_', '')
    );
}