const API_PATH = "/reva-api";

const PATH_AUTHORIZE = '/reva/authorize?jwt=true';
const PATH_GEOREF = '/v1/georef';
const PATH_LOCATIONINFO = '/v1/locationInformation';
const PATH_VALUE_INDICATION = '/v1/indicate/value';
const PATH_IMMODROP = '/reva/immodrop/extract';
const PATH_INDICATION_SPEC = '/reva/indication/spec';
const PATH_WMTS = '/reva/gs/wmts';

export class WebError {
    /**
     * Web-Error from response.
     * @param status {number} http status code
     * @param [json] {object} optional JSON response
     */
    constructor(status, json) {
        this.status = status;
        this.json = json;
        this.message = "Error " + status;
    }

    /**
     * Get optional custom error message.
     * @return {string|null} returns custom error from response JSON, or null
     */
    getCustomErrorMessage() {
        if (typeof this.json === 'object' && this.json.error) {
            return this.json.error;
        }
        return null;
    }
}

/**
 * Error if the response did not contain a valid JSON, although it was expected.
 * @param status {number} http status code
 */
export class InvalidResponseError extends WebError {
}

/**
 * @typedef ApiTokenCredentials
 * @property {string} token
 */

/**
 * @typedef ApiBasicCredentials
 * @property {string} username
 * @property {string} password
 */

/**
 * @typedef {ApiTokenCredentials|ApiBasicCredentials} ApiCredentials
 */

/**
 * Service API.
 */
export default class ServiceApi {

    /**
     * Create service API.
     * @param [apiUrl] {string} the base URL of the backend server
     * @constructor
     */
    constructor(apiUrl) {
        this.apiUrl = apiUrl || API_PATH;
    }

    /**
     * Construct API Path URL.
     * @param {string} path relative path
     * @param {string} [queryParams] optional query params
     * @return {string} full URL for target
     */
    apiTarget(path, queryParams) {
        return this.apiUrl + path + (typeof queryParams === "string" ? queryParams : "");
    }

    /**
     * Perform login.
     * @param authModel {AuthenticationModel} authentication model
     * @return {Promise<object>} Promise with authorization result JSON, if the login was successful
     */
    async performLogin(authModel) {
        const url = this.apiTarget(PATH_AUTHORIZE);
        /** @type {ApiBasicCredentials} */
        const credentials = {username: authModel.username, password: authModel.password}
        return await this._fetchGET(url, credentials);
    }

    /**
     * Perform initial login from stored token.
     * @param {string} token JWT token
     * @return {Promise<object>} Promise with authorization result JSON, if the login was successful
     */
    async performInitialLogin(token) {
        const url = this.apiTarget(PATH_AUTHORIZE);
        /** @type {ApiTokenCredentials} */
        const credentials = {token}
        return await this._fetchGET(url, credentials);
    }

    /**
     * Perform GeoRef Request.
     * @param {ApiTokenCredentials} credentials credentials for the request
     * @param {GeoRefModel} geoRefModel model for geo referencing
     * @return {Promise<object>} Promise with GeoRef result
     */
    async performGeoRef(credentials, geoRefModel) {
        const url = this.apiTarget(PATH_GEOREF, geoRefModel.getQueryParameters());
        return await this._fetchGET(url, credentials);
    }

    /**
     * Perform Location Information Request.
     * @param {ApiTokenCredentials} credentials credentials for the request
     * @param {LocationInformationModel} locationInfoModel
     * @return {Promise<object>} Promise with LocationInfo result
     */
    async performLocationInformation(credentials, locationInfoModel) {
        const url = this.apiTarget(PATH_LOCATIONINFO, locationInfoModel.getQueryParameters());
        return await this._fetchGET(url, credentials);
    }

    /**
     * Perform Value Indication Request.
     * @param {ApiTokenCredentials} credentials credentials for the request
     * @param {ValueIndicationModel} valueIndicationModel
     * @return {Promise<object>} Promise with value indication result
     */
    async performValueIndication(credentials, valueIndicationModel) {
        const url = this.apiTarget(PATH_VALUE_INDICATION, valueIndicationModel.getQueryParameters());
        return await this._fetchPOST(url, credentials, valueIndicationModel.getPayload());
    }

    /**
     * Perform ImmoDrop extraction request.
     * @param {ApiTokenCredentials} credentials credentials for the request
     * @param {ImmoDropModel} immodropModel
     * @return {Promise<object>} Promise with extraction response
     */
    async performImmodropExtraction(credentials, immodropModel) {
        const url = this.apiTarget(PATH_IMMODROP, immodropModel.getQueryParameters());
        return await this._fetchGET(url, credentials);
    }

    /**
     * Load REVA Indication Spec.
     * @param {ApiTokenCredentials} credentials credentials for the request
     * @return {Promise<object>} Promise with spec
     */
    async loadIndicationSpec(credentials) {
        const url = this.apiTarget(PATH_INDICATION_SPEC);
        return await this._fetchGET(url, credentials);
    }

    /**
     * Load Geoserver WMS tile
     * @param {ApiTokenCredentials} credentials credentials for the request
     * @param {string} relativeUrl URL determined by OpenLayers
     * @return {Promise<string>} Promise with the Image Data-URL
     */
    async loadGeoServerTile(credentials, relativeUrl) {
        const url = this.apiTarget(relativeUrl);
        const blob = await this._fetchGETBinary(url, credentials);
        return URL.createObjectURL(blob);
    }

    /**
     * Load Geoserver WMTS Capabilities
     * @param {ApiTokenCredentials} credentials credentials for the request
     * @param {WmtsCapabilitiesModel} capabilitiesModel
     */
    async loadGeoServerWmtsCapabilities(credentials, capabilitiesModel) {
        //https://geoserver.value-marktdaten.de:444/geoserver/gwc/service/wmts?layer=reva%3Awohnlagen_mikro_decorated&style=&tilematrixset=WebMercatorQuad&Service=WMTS&Request=GetCapabilities
        const url = this.apiTarget(PATH_WMTS, capabilitiesModel.getQueryParameters());
        const binary = await this._fetchGETBinary(url, credentials);
        return binary.text();
    }

    /**
     * Internal GET request with fetch API (JSON data).
     * @param {string} url full query URL
     * @param {ApiCredentials} credentials authentication credentials
     * @return {Promise<object>}
     * @private
     */
    async _fetchGET(url, credentials) {
        const responsePromise = fetch(url, {
            mode: "cors",
            headers: {
                "Authorization": this._authorizationHeader(credentials),
                "Accept": "application/json"
            },
            redirect: "error",
            cache: "no-cache",
            referrerPolicy: "no-referrer"
        });
        const response = await responsePromise;
        return await this._handleWebResponse(response);
    }

    /**
     * Internal POST request with fetch API (JSON data).
     * @param {string} url full query URL
     * @param {ApiCredentials} credentials authentication credentials
     * @param {object} payload POST body payload
     * @return {Promise<object>}
     * @private
     */
    async _fetchPOST(url, credentials, payload) {
        const responsePromise = fetch(url, {
            method: "POST",
            mode: "cors",
            headers: {
                "Authorization": this._authorizationHeader(credentials),
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            redirect: "error",
            cache: "no-cache",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(payload)
        });
        const response = await responsePromise;
        return await this._handleWebResponse(response);
    }

    /**
     * Internal GET request with fetch API (Non-JSON data, binary).
     * @param {string} url full query URL
     * @param {ApiCredentials} credentials authentication credentials
     * @param {string} [accept] optional Accept header
     * @return {Promise<Blob>}
     * @private
     */
    async _fetchGETBinary(url, credentials, accept) {
        const responsePromise = fetch(url, {
            mode: "cors",
            headers: {
                "Authorization": this._authorizationHeader(credentials),
                "Accept": (accept || "*/*")
            },
            redirect: "error",
            cache: "no-cache",
            referrerPolicy: "no-referrer"
        });
        const response = await responsePromise;
        return await this._handleWebBinaryResponse(response);
    }

    /**
     * Create authorization header from credentials.
     * @param {ApiCredentials} credentials authentication credentials
     * @return {string} Authorization header
     * @private
     */
    _authorizationHeader(credentials) {
        if (credentials.token) {
            return "Bearer " + credentials.token;
        } else if (credentials.username && credentials.password) {
            return "Basic " + btoa(credentials.username + ":" + credentials.password)
        }
        throw new Error("Invalid credentials: " + JSON.stringify(credentials))
    }

    /**
     * Handle response from API.
     * @param response {Response}
     * @return {Promise<object>} result JSON, or error
     * @private
     */
    async _handleWebResponse(response) {
        const json = await this._readJson(response);
        if (response.status !== 200) {
            throw new WebError(response.status, json);
        }
        return json;
    }

    /**
     * Handle response from API, with Non-JSON (Binary) Responses.
     * @param response {Response}
     * @return {Promise<Blob>} result Blob, or error
     * @private
     */
    async _handleWebBinaryResponse(response) {
        if (response.status !== 200) {
            throw new WebError(response.status, {statusText: response.statusText, error: response.text()});
        }
        return response.blob();
    }

    /**
     * Read JSON from response.
     * @param response {Response}
     * @return {Promise<object>} result JSON, or error
     * @private
     */
    async _readJson(response) {
        try {
            return await response.json();
        } catch (e) {
            console.log('Invalid response is not a JSON', e)
            throw new InvalidResponseError(response.status);
        }
    }
}
