import {ValidationError, ValidationErrorI18n} from "./Validation";
import {ErrorI18n} from "../i18n";

export class GeneralModel {

    /**
     * Validate input data.
     */
    validate() {
        console.log(`Missing implementation for validate() in model ${this}`);
    }

    /**
     * @callback HandleValidationError
     * @param {string|null} error Error i18n Key
     * @param {object} [errorArgs] Error Args (i18n)
     * @param {string[]} failedFields Array of invalid fields found during validation
     */

    /**
     * Validate input data, and dispatch error if not successful.
     * @param {HandleValidationError} handleError error response function
     * @return {boolean} true if validate was successful
     */
    validateAndDispatchError(handleError) {
        try {
            this.validate();
            return true;
        } catch (e) {
            if (e instanceof ValidationErrorI18n) {
                handleError(e.message, e.messageArgs, e.failedFields());
            } else if (e instanceof ValidationError) {
                handleError(null, null, e.failedFields());
            } else if (e instanceof ErrorI18n) {
                handleError(e.message, e.messageArgs, []);
            } else {
                console.log(`Unexpected error during validation of ${this}`, e);
                handleError(null, null, []);
            }
        }
        return false;
    }

}