import {createSlice} from '@reduxjs/toolkit'
import {login, logout} from "./authSlice";
import {AuthenticationModel} from '../model/AuthenticationModel'
import {ErrorI18n} from "../i18n";
import {parseToken} from "../util/tokenUtil";
import {clearSpec, querySpecAsync} from "./specSlice";
import {removeDistinctAddress} from "./addressSlice";
import {queryCapabilitiesAsync} from "./wmtsSlice";

/**
 * @typedef {"init"|"idle"|"loading"|"failed"} LoginStatus
 */

export const loginSlice = createSlice({
    name: 'login',
    initialState: {
        error: '',
        errorArgs: {},
        errorHasContactLink: false,
        status: 'init'
    },
    reducers: {
        setIdle: (state) => {
            state.error = '';
            state.errorArgs = {};
            state.errorHasContactLink = false;
            state.status = 'idle';
        },
        setLoading: (state) => {
            state.error = '';
            state.errorArgs = {};
            state.errorHasContactLink = false;
            state.status = 'loading';
        },
        setError: (state, action) => {
            state.error = action.payload.error;
            state.errorArgs = action.payload.errorArgs || {};
            state.errorHasContactLink = !!action.payload.errorHasContactLink;
            state.status = 'failed';
        }
    },
    selectors: {
        /** @return {LoginStatus} */
        selectStatus: (state) => state.status,
        /** @return {string} */
        selectError: (state) => state.error,
        /** @return {object} */
        selectErrorArgs: (state) => state.errorArgs,
        /** @return {boolean} */
        selectErrorHasContactLink: (state) => state.errorHasContactLink
    }
})

export const {
    setIdle,
    setLoading,
    setError
} = loginSlice.actions

const KEY_STORAGE_TOKEN = 'reva_session_token';

/**
 * Read currently stored session from storage.
 * @param {boolean} isDev flag if environment id development
 * @return {null | {token: string, parsedToken: ParsedJwtToken}}
 */
const getStoredSession = (isDev) => {
    const token = localStorage.getItem(KEY_STORAGE_TOKEN);
    if (!token) {
        if (isDev) {
            console.log("No stored session token found")
        }
        return null;
    }
    const parsedToken = parseToken(token);
    if (!parsedToken) {
        if (isDev) {
            console.log("Stored session token was not valid")
        }
        clearStoredSession(isDev);
        return null;
    }
    return {token, parsedToken};
}

/**
 * Clear previously stores session token (after logout).
 * @param {boolean} isDev flag if environment id development
 */
const clearStoredSession = (isDev) => {
    if (isDev) {
        console.log("Clearing stored session token from local storage ...")
    }
    localStorage.removeItem(KEY_STORAGE_TOKEN);
}

/**
 * Store session token is local storage, to styl logged in.
 * @param {boolean} isDev flag if environment id development
 * @param {string} token JWT token from login
 */
const setStoredSession = (isDev, token) => {
    const parsedToken = parseToken(token)
    if (!parsedToken) {
        if (isDev) {
            console.warn("Could not store JWT token: was not valid or empty")
        }
        return;
    }
    localStorage.setItem(KEY_STORAGE_TOKEN, token);
    if (isDev) {
        console.log(`Storing JWT token for user ${parsedToken.user} with expire date: ${parsedToken.expireDate}`);
    }
}

/**
 * Handle (likely) successful login.
 * @param {AuthResponse} authPayload
 * @param {AppEnvironment} env
 * @param {Dispatch} dispatch
 */
const loginResponseFlow = (authPayload, env, dispatch) => {
    if (!authPayload.token) {
        throw new Error("No token was generated on login!");
    }
    setStoredSession(env.isDevelopmentMode, authPayload.token);
    dispatch(setIdle());
    dispatch(login(authPayload));
    dispatch(querySpecAsync())
    dispatch(queryCapabilitiesAsync())
}

export const tryPerformInitialLogin = () => async (dispatch, getState, extraArgument) => {
    const {env, serviceApi} = extraArgument;
    if (env.isDevelopmentMode) {
        console.log('Trying to perform initial login from stored session ...')
    }
    const initialToken = getStoredSession(env.isDevelopmentMode);
    if (!initialToken) {
        if (env.isDevelopmentMode) {
            console.log('No (valid) stored session token found.')
        }
        dispatch(setIdle());
        return;
    }
    if (initialToken.parsedToken.expired) {
        if (env.isDevelopmentMode) {
            console.log(`Stored session token is expired since ${initialToken.parsedToken.expireDate.toString()}`);
        }
        clearStoredSession(env.isDevelopmentMode);
        dispatch(setError({error: 'login.error.expired', errorHasContactLink: false}));
        return;
    }

    try {
        if (env.isDevelopmentMode) {
            console.log(`Found initial stored token - try to auto-login '${initialToken.parsedToken.user}' ...`);
        }
        const apiResponse = await serviceApi.performInitialLogin(initialToken.token);
        const authPayload = await AuthenticationModel.parseResponseJson(apiResponse);
        loginResponseFlow(authPayload, env, dispatch);
    } catch (err) {
        if (env.isDevelopmentMode) {
            console.log(`Failed to auto-login '${initialToken.parsedToken.user}' from stored token.`, err);
        }
        clearStoredSession(env.isDevelopmentMode);
        dispatch(setError({error: 'login.error.expired', errorHasContactLink: false}));
    }
}

export const tryPerformLoginAsync = (username, password) => async (dispatch, getState, extraArgument) => {
    const {env, serviceApi} = extraArgument;
    const authModel = new AuthenticationModel(username, password);
    if (!authModel.validateAndDispatchError((error, errorArgs) => dispatch(setError({error, errorArgs})))) {
        return;
    }
    dispatch(setLoading());
    try {
        if (env.isDevelopmentMode) {
            console.log(`Performing login with username ${username}`)
        }
        const apiResponsePromise = serviceApi.performLogin(authModel);
        const authPayload = await authModel.handleResponse(apiResponsePromise);
        loginResponseFlow(authPayload, env, dispatch);
    } catch (err) {
        if (err instanceof ErrorI18n) {
            dispatch(setError({error: err.message, errorArgs: {...err.messageArgs}, errorHasContactLink: true}));
        } else {
            dispatch(setError({error: 'general.error.custom', errorArgs: {errorMessage: err.message}, errorHasContactLink: true}));
        }
    }
}

export const performLogout = () => async (dispatch, getState, extraArgument) => {
    const {env} = extraArgument;
    clearStoredSession(env.isDevelopmentMode);
    dispatch(logout());

    // Clear all user entered data
    dispatch(clearSpec())
    dispatch(removeDistinctAddress())
}

export const {
    selectStatus,
    selectError,
    selectErrorArgs,
    selectErrorHasContactLink
} = loginSlice.selectors;

export default loginSlice.reducer
