import i18n from 'i18next';
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import {format as formatDate, isDate} from "date-fns";
import {de as localeDE, enUS as localeEN} from "date-fns/locale";

const dateFmtLocales = {en: localeEN, de: localeDE};

/**
 * Initialize i18n
 * @param env {AppEnvironment} environment
 */
export async function initializeI18n(env) {
    return i18n
        .use(LanguageDetector)
        .use(Backend)
        .use(initReactI18next)
        .init({
            debug: env.isDevelopmentMode,
            fallbackLng: 'en',
            supportedLngs: ['en', 'de'],
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
                format: (value, format, lng) => {
                    if (isDate(value)) {
                        const locale = dateFmtLocales[lng];
                        return formatDate(value, format, {locale});
                    }
                }
            },
            backend: {
                queryStringParams: {
                    v: env.gitCommitHash || 'dev' // Force browser to download newer translation files after git changes
                }
            }
        });
}


export function ErrorI18n(errorKey, errorArgs) {
    this.message = errorKey;
    this.messageArgs = errorArgs || {};
    this.stack = (new Error()).stack;
}

ErrorI18n.prototype = Object.create(Error.prototype);
ErrorI18n.prototype.name = "ErrorI18n";

export default i18n;