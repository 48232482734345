import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {selectUsername} from "../redux/authSlice";

import {Tooltip} from "primereact/tooltip";

function MissingPermissionTooltipContent() {

    const {t} = useTranslation();
    const username = useSelector(selectUsername);

    return (
        <>
            <div className="mx-3 mt-2 mb-4 text-xl">
                <span className="pi pi-lock text-xl mr-3"></span>
                {t("general.missingPermissionTitle")}
            </div>
            <p className="m-3">
                {t('general.missingPermission1')}<strong>{username}</strong>{t('general.missingPermission2')}
            </p>
            <p className="m-3 mt-0">
                {t('general.missingPermissionContact')}
                <a href="https://www.value-marktdaten.de/contact" target="_blank" rel="noreferrer"
                   style={{color: "inherit"}}
                   className="p-button-label ml-2 text-war">{t('general.contact')}</a>
            </p>
        </>
    );
}

function MissingPermissionTooltip({target, position, appendTo}) {
    if (!appendTo) {
        appendTo = document.getElementsByClassName("App").item(0);
    }
    return (
        <Tooltip target={target} position={position} autoHide={false} className="warnTooltip" appendTo={appendTo}>
            <MissingPermissionTooltipContent/>
        </Tooltip>
    )
}

export default MissingPermissionTooltip;