import {useTranslation} from "react-i18next";
import {Card} from "primereact/card";
import {PrimeIcons} from "primereact/api";
import {formatLatLon} from "../../util/numberFormat";

/**
 * Display Card with address meta.
 * @param {GeoRefAddress|null} addressMeta selected address meta
 * @param {string} [className] optional addition CSS classes
 * @param {(GeoRefAddress) => void} [onSelectAddress] event handler for clicking on the address card
 * @param {(GeoRefAddress) => void} [onHoverAddress] event handler for hovering over the address card
 * @constructor
 */
export default function AddressCard({addressMeta, className, onSelectAddress, onHoverAddress}) {

    const {i18n} = useTranslation();

    if (!addressMeta)
        return null;

    const onAddressClick = () => {
        if (typeof onSelectAddress === 'function') {
            onSelectAddress(addressMeta);
        }
    };

    const onAddressMouseEnter = () => {
        if (typeof onHoverAddress === 'function') {
            onHoverAddress(addressMeta);
        }
    };

    return (
        <Card className={'AddressCard surface-100' + (className ? ' ' + className : '')}
              onClick={onAddressClick} onMouseEnter={onAddressMouseEnter}>
            <div className="font-bold text-lg mb-2 pb-2 border-bottom-1">
                <span className={PrimeIcons.MAP_MARKER + " mr-2"}/>
                {i18n.language === 'en' ? addressMeta.displayNameEN : addressMeta.displayNameDE}
            </div>
            <div className="mb-1">
                <span className={PrimeIcons.MAP + " mr-2"}/>
                {formatLatLon(addressMeta.lat, i18n.language)} / {formatLatLon(addressMeta.lon, i18n.language)}
            </div>
            <div className="pl-4">
                {addressMeta.street}<br/>
                {addressMeta.postcode} {addressMeta.municipality}
            </div>
        </Card>
    );
}