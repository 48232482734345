import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectPrivileges, selectUsername} from "../redux/authSlice";
import {checkPermissionRule} from "../model/permissions";
import {useLocation, useNavigate, useNavigation} from "react-router-dom";
import {addAddressQueryFromLocation} from "../router";
import {Button} from "primereact/button";

/**
 * Indicator, that not enough data is available
 * @param {boolean} dataAvailable flag if enough data is available to show the children
 * @param {JSX.Element} children
 * @return {JSX.Element} rendered indicator
 */
function MissingDataIndicator({dataAvailable, children}) {

    const {t} = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    if (dataAvailable) {
        return children; // Don't show error
    }

    const onNavigateToObjectInputs = () => {
        navigate("/object" + addAddressQueryFromLocation(location));
    }

    return (
        <div className="flex-grow-1 flex flex-column justify-content-center align-items-center">
            <div className="p-inline-message p-component p-inline-message-warn block">
                <div className="m-3 mb-5 text-2xl">
                    <span className="pi pi-lock text-2xl mr-3"></span>
                    {t("general.missingDataTitle")}
                </div>
                <p className="m-3 mt-0">
                    {t('general.missingDataContent')}
                </p>
                <p className="m-3 mt-5">
                    <Button label={t('object.objectParameters')} severity="secondary"
                            onClick={onNavigateToObjectInputs}/>
                </p>
            </div>
        </div>
);
}

export default MissingDataIndicator;