import {formatIndex, formatValue, formatPricePerArea} from "../util/numberFormat";
import {Legend} from "chart.js";

/**
 * @typedef {Object<TIMELINE_SEGMENT, AggregatedTimelineSegmentData>} AggregatedTimelineData
 */

/**
 * @typedef {Object<REGIONAL_LEVEL, AggregatedTimeline>} AggregatedTimelineSegmentData
 */

/**
 * @typedef {AggregatedTimelineValue[]} AggregatedTimeline
 */

/**
 * @typedef {object} AggregatedTimelineValue
 * @property {string} startOfPeriod
 * @property {number} year
 * @property {number} indexLow
 * @property {number} index
 * @property {number} indexHigh
 * @property {number} valueLow
 * @property {number} value
 * @property {number} valueHigh
 */

/**
 * @typedef {AggregatedTimelineValueFormatted[]} AggregatedTimelineFormatted
 */

/**
 * @typedef {object} AggregatedTimelineValueFormatted
 * @property {string} year
 * @property {string} indexLow
 * @property {string} index
 * @property {string} indexHigh
 * @property {string} indexRange
 * @property {string} valueLow
 * @property {string} value
 * @property {string} valueHigh
 * @property {string} valueRange
 */

/**
 * @typedef {"FLAT" | "EFH/ZFH" | "MFH/WGH" | "OFFICE/PRACTICE"} OBJECT_TYPE
 */

/**
 * @typedef {"POSTCODE" | "DISTRICT" | "REGION" | "STATE"} REGIONAL_LEVEL
 */

/**
 * @typedef {"WHG_K" | "WHG_M" | "EZFH_K" | "MFHWGH_K" | "BUERO_K" | "BUERO_M"} TIMELINE_SEGMENT
 */

/**
 * @typedef {"sale" | "rent"} SEGMENT_TYPE
 */

/** @type {OBJECT_TYPE} */
const OBJECT_TYPE_FLAT = "FLAT";
/** @type {OBJECT_TYPE} */
const OBJECT_TYPE_EFH_ZFH = "EFH/ZFH";
/** @type {OBJECT_TYPE} */
const OBJECT_TYPE_MFH_WGH = "MFH/WGH";
/** @type {OBJECT_TYPE} */
const OBJECT_TYPE_OFFICE = "OFFICE/PRACTICE";

/** @type {OBJECT_TYPE[]} */
export const OBJECT_TYPES = [OBJECT_TYPE_FLAT, OBJECT_TYPE_EFH_ZFH, OBJECT_TYPE_MFH_WGH, OBJECT_TYPE_OFFICE];

/** @type {Record<OBJECT_TYPE, string>} */
export const OBJECT_TYPE_NAMES_I18N = {
    "FLAT": "object_types.flat",
    "EFH/ZFH": "object_types.EFH_ZFH",
    "MFH/WGH": "object_types.MFH_WGH",
    "OFFICE/PRACTICE": "object_types.office_practice"
}

/** @type {Record<OBJECT_TYPE, Record<SEGMENT_TYPE, TIMELINE_SEGMENT|null>>} */
const SEGMENT_BY_OBJECT_TYPE = {
    "FLAT": {
        "sale": "WHG_K",
        "rent": "WHG_M"
    },
    "EFH/ZFH": {
        "sale": "EZFH_K",
        "rent": null
    },
    "MFH/WGH": {
        "sale": "MFHWGH_K",
        "rent": "WHG_M"
    },
    "OFFICE/PRACTICE": {
        "sale": "BUERO_K",
        "rent": "BUERO_M"
    }
}

/** @type {REGIONAL_LEVEL} */
const REGIONAL_LEVEL_POSTCODE = "POSTCODE";
/** @type {REGIONAL_LEVEL} */
const REGIONAL_LEVEL_DISTRICT = "DISTRICT";
/** @type {REGIONAL_LEVEL} */
const REGIONAL_LEVEL_REGION = "REGION";
/** @type {REGIONAL_LEVEL} */
const REGIONAL_LEVEL_STATE = "STATE";

/** @type {REGIONAL_LEVEL[]} */
export const REGIONAL_LEVELS = [REGIONAL_LEVEL_POSTCODE, REGIONAL_LEVEL_DISTRICT, REGIONAL_LEVEL_REGION, REGIONAL_LEVEL_STATE];

/** @type {Object<REGIONAL_LEVEL, {code: number, name: string, address_field: string}>} */
export const REGIONAL_LEVEL_DATA = {
    "POSTCODE": {
        code: 2,
        nameI18n: "regional_levels.postcode",
        address_field: "postcode"
    },
    "DISTRICT": {
        code: 4,
        nameI18n: "regional_levels.district",
        address_field: "district_name"
    },
    "REGION": {
        code: 5,
        nameI18n: "regional_levels.region",
        address_field: "region_name"
    },
    "STATE": {
        code: 6,
        nameI18n: "regional_levels.state",
        address_field: "state_name"
    }
}

/** @type {Object<number, REGIONAL_LEVEL>} */
const REGIONAL_LEVEL_BY_CODE = {
    2: REGIONAL_LEVEL_POSTCODE,
    4: REGIONAL_LEVEL_DISTRICT,
    5: REGIONAL_LEVEL_REGION,
    6: REGIONAL_LEVEL_STATE
}

/**
 * @typedef {object} TimelineTableData
 * @property {TimelineTableHeaderData[]} headers
 * @property {string[]} columns
 * @property {object[]} rows
 */

/**
 * @typedef {object} TimelineTableHeaderData
 * @property {string} categoryHeader
 * @property {string[]} columnHeaders
 */

/**
 * Sorter for Timeline row data by year (reverse).
 */
const sortByYearDesc = (a, b) => {
    if (a.year < b.year)
        return 1;
    if (a.year > b.year)
        return -1;
    return 0;
}

/**
 * Sorter for Timeline  data by start of period.
 */
const sortByStartOfPeriodAsc = (a, b) => {
    if (a.start_of_period < b.start_of_period)
        return -1;
    if (a.start_of_period > b.start_of_period)
        return 1;
    return 0;
}

/**
 * Calculate indexed number.
 * @param {number} number
 * @param {number} indexBase base for index calculation
 * @return {number}
 */
const toIndexed = (number, indexBase) => {
    return number * 100 / indexBase;
}

/**
 * Format timeline.
 * @param {AggregatedTimeline} timeline
 * @param {RevaLanguage} language
 * @param {boolean} noFraction render no Fraction digits (e.g. for purchase prices)
 * @return {AggregatedTimelineFormatted}
 */
const fmtTimeline = (timeline, language, noFraction) => {
    if (!timeline || !timeline.length)
        return [];
    return timeline.map(v => {
        const valueLow = formatValue(v.valueLow, language, noFraction);
        const valueHigh = formatValue(v.valueHigh, language, noFraction);
        const indexLow = formatIndex(v.indexLow, language);
        const indexHigh = formatIndex(v.indexHigh, language);
        return {
            year: v.year.toFixed(0),
            value: formatValue(v.value, language, noFraction),
            valueLow: valueLow,
            valueHigh: valueHigh,
            valueRange: valueLow + " - " + valueHigh,
            index: formatIndex(v.index, language),
            indexLow: indexLow,
            indexHigh: indexHigh,
            indexRange: indexLow + " - " + indexHigh
        }
    })
}

/**
 * @typedef RevaChartStyle
 * @property {string[]} colors
 * @property {string} colorLow
 * @property {string} colorAvg
 * @property {string} colorHigh
 * @property {string} fillLow
 * @property {string} fillHigh
 * @property {string} grid
 * @property {string} ticks
 * @property {number} tension
 * @property {number} tooltipColorBoxBorder
 * @property {string} tooltipColorBoxBorderColor
 */



/** @type {RevaChartStyle} */
export const CHART_STYLE_LIGHT = {
    colors: ["#6600E2", "#272D3F", "#5DA5DA", "#9d9d9d"],
    colorLow: "rgba(255,255,255,0)",
    fillLow: "rgba(102,0,226,0.1)",
    colorAvg: "#6600E2",
    colorHigh: "rgba(255,255,255,0)",
    fillHigh: "rgba(102,0,226,0.1)",
    grid: "--surface-border",
    ticks: "--text-color-secondary",
    tension: 0.4,
    tooltipColorBoxBorder: 2,
    tooltipColorBoxBorderColor: '#ffffff'
}

/** @type {RevaChartStyle} */
export const CHART_STYLE_DARK = {
    colors: ["#6600E2", "#e5e5e5", "#5DA5DA", "#5e5e5e"],
    colorLow: "rgba(255,255,255,0)",
    fillLow: "rgba(125,27,248,0.2)",
    colorAvg: "#6600E2",
    colorHigh: "rgba(255,255,255,0)",
    fillHigh: "rgba(125,27,248,0.2)",
    grid: "--surface-d",
    ticks: "--text-color-secondary",
    tension: 0.4,
    tooltipColorBoxBorder: 0,
    tooltipColorBoxBorderColor: "rgba(255,255,255,0)"
}

/**
 * Get corresponding Chart style.
 * @param {boolean} isDarkTheme
 * @return {RevaChartStyle}
 */
export function getChartStyle(isDarkTheme) {
    return isDarkTheme ? CHART_STYLE_DARK : CHART_STYLE_LIGHT;
}

/**
 * Get color for chartStyle.
 * @param {string} colorStr color String
 * @return {*|string} resolved color
 */
export function getColor(colorStr) {
    if (!colorStr) {
        console.warn(`Invalid colorStr: ${colorStr}`)
        return "#000000";
    }
    if (colorStr.startsWith("--")) {
        return getComputedStyle(document.documentElement).getPropertyValue(colorStr);
    }
    if (!colorStr.startsWith("#") && !colorStr.startsWith("rgb(") && !colorStr.startsWith("rgba(")) {
        console.warn(`Invalid colorStr: ${colorStr}`)
    }
    return colorStr;
}

/**
 * Aggregate raw timeline Data from LocationInformation API to an enhanced format usable in REVA.
 * @param {LocationInfoTimelineData} timelineData
 * @return {AggregatedTimelineData}
 */
export function aggregateTimelineData(timelineData) {

    const resultTimelineData = {};

    if (!timelineData || !Array.isArray(timelineData.segments)) {
        return resultTimelineData;
    }

    /**
     * Compile timelines values
     * @param {LocationInfoTimelineValue[]} timelineValues
     * @return {AggregatedTimelineValue[]}
     */
    const aggregateTimeline = (timelineValues) => {
        if (!timelineValues || !Array.isArray(timelineValues) || !timelineValues.length)
            return [];
        // noinspection JSUnresolvedReference
        /** @type {LocationInfoTimelineValue[]} */
        const timelineValuesSorted = timelineValues.toSorted(sortByStartOfPeriodAsc);
        const indexBase = timelineValuesSorted[0].value || 1;
        return timelineValuesSorted.map(tlValue => {
            return {
                startOfPeriod: tlValue.start_of_period,
                year: new Date(tlValue.start_of_period).getFullYear(),
                valueLow: tlValue.value_low,
                value: tlValue.value,
                valueHigh: tlValue.value_high,
                indexLow: toIndexed(tlValue.value_low, indexBase),
                index: toIndexed(tlValue.value, indexBase),
                indexHigh: toIndexed(tlValue.value_high, indexBase)
            };
        })
    };

    /**
     * Compile segment data to regional levels
     * @param {LocationInfoTimeline[]} segmentTimelines
     * @return {AggregatedTimelineSegmentData}
     */
    const aggregateRegionalLevels = (segmentTimelines) => {
        const result = {};
        for (const regionalData of segmentTimelines) {
            if (regionalData.period !== 1) {
                console.warn("Found timeline with period != 1. Unexpected, ignoring ...")
                continue;
            }
            const regionalLevel = REGIONAL_LEVEL_BY_CODE[regionalData.regional_level];
            if (!regionalLevel) {
                console.warn(`Found unexpected regional level ${regionalData.regional_level} in timeline, ignoring ...`)
                continue;
            }
            result[regionalLevel] = aggregateTimeline(regionalData.values);
        }
        return result;
    };

    for (const segmentData of timelineData.segments) {
        resultTimelineData[segmentData.segment] = aggregateRegionalLevels(segmentData.timeline);
    }

    return resultTimelineData;
}

export class TimeLinesModel {
    /**
     *  Initialize model.
     * @param {AggregatedTimelineData|null} aggregatedTimelineData
     */
    constructor(aggregatedTimelineData) {
        this.aggregatedTimelineData = aggregatedTimelineData;
    }

    /**
     * Check if a timeline segment is available in the timeline data.
     * @param {TIMELINE_SEGMENT} segment
     * @return {boolean}
     */
    hasSegment(segment) {
        if (!segment || !this.aggregatedTimelineData)
            return false;
        return typeof this.aggregatedTimelineData[segment] === 'object';
    }

    /**
     * Check if a combination of timeline segment and regional level is available in the timeline data.
     * @param {TIMELINE_SEGMENT} segment
     * @param {REGIONAL_LEVEL} regionalLevel
     * @return {boolean}
     */
    hasRegionalLevel(segment, regionalLevel) {
        if (!this.hasSegment(segment) || !regionalLevel)
            return false;
        return Array.isArray(this.aggregatedTimelineData[segment][regionalLevel])
            && this.aggregatedTimelineData[segment][regionalLevel].length > 0;
    }

    /**
     * Get list of object types available in the timeline data.
     * @return {OBJECT_TYPE[]}
     */
    getObjectTypes() {
        return OBJECT_TYPES.filter(objectType => {
            const {sale, rent} = this.getTimelineSegments(objectType);
            return this.hasSegment(sale) || this.hasSegment(rent);
        });
    }

    /**
     * Get segments for "sale" or "rent" segment types.
     * @param {OBJECT_TYPE} objectType
     * @return {{"sale": TIMELINE_SEGMENT|null, "rent": undefined|TIMELINE_SEGMENT|null}}
     */
    getTimelineSegments(objectType) {
        return SEGMENT_BY_OBJECT_TYPE[objectType] || {"sale": null, "rent": null};
    }

    /**
     * Get available regional Levels for at least one of the segments for the object type.
     * @param {OBJECT_TYPE} objectType
     * @return {REGIONAL_LEVEL[]}
     */
    getSupportedRegionalLevelsForObjectType(objectType) {
        const {sale: segmentSale, rent: segmentRent} = this.getTimelineSegments(objectType);
        return this.getSupportedRegionalLevels(segmentSale, segmentRent);
    }

    /**
     * Get available regional Levels for at least one of the segments.
     * @param {TIMELINE_SEGMENT|null} [segmentSale]
     * @param {TIMELINE_SEGMENT|null} [segmentRent]
     * @return {REGIONAL_LEVEL[]}
     */
    getSupportedRegionalLevels(segmentSale, segmentRent) {
        return REGIONAL_LEVELS.filter(regionalLevel =>
            this.hasRegionalLevel(segmentSale, regionalLevel) || this.hasRegionalLevel(segmentRent, regionalLevel));
    }

    /**
     * Get aggregated timeline data.
     * @param {TIMELINE_SEGMENT} segment
     * @param {REGIONAL_LEVEL} regionalLevel
     * @return {AggregatedTimeline}
     */
    getTimeline(segment, regionalLevel) {
        if (!this.hasRegionalLevel(segment, regionalLevel)) {
            console.warn(`Requested timeline for segment=${segment}, regionalLevel=${regionalLevel}, which does not exist`);
            return [];
        }
        return this.aggregatedTimelineData[segment][regionalLevel];
    }

    /**
     * Get aggregated timeline data, sorted by year descending.
     * @param {TIMELINE_SEGMENT} segment
     * @param {REGIONAL_LEVEL} regionalLevel
     * @return {AggregatedTimeline}
     */
    getTimelineDescending(segment, regionalLevel) {
        return this.getTimeline(segment, regionalLevel).toSorted(sortByYearDesc);
    }

    /**
     * Get data for timeline table.
     * @param {OBJECT_TYPE} objectType selected object type
     * @param {boolean} indexed
     * @param {REGIONAL_LEVEL|null} regionalLevel optional regional level (if null, returns a comparison/overview over all regional levels)
     * @param {i18n} i18n i18next object
     * @return {TimelineTableData|null} table data, or null if no applicable values available
     */
    getTimelineTableData(objectType, indexed, regionalLevel, i18n) {
        if (!this.aggregatedTimelineData)
            return null;
        const {t} = i18n;
        const {sale: segmentSale, rent: segmentRent} = this.getTimelineSegments(objectType);
        if (!this.hasSegment(segmentSale) && !this.hasSegment(segmentRent))
            return null;
        if (regionalLevel && !this.getSupportedRegionalLevels(segmentSale, segmentRent).includes(regionalLevel))
            return null;

        /** @type {TimelineTableHeaderData[]} */
        const headers = [];
        /** @type {string[]} */
        const columns = ["year"];

        /**
         * @typedef {object} TimelineTableRowBuilder
         * @property {AggregatedTimelineFormatted} timeline
         * @property {string[]} timelineColumns
         * @property {Object<string, string>} mappedColumns
         */

        /** @type {TimelineTableRowBuilder[]} */
        const rowBuilders = [];

        const unit = (indexed) ? " (" + t("market.timelines.indexed") + ")" : " in €/m²";
        const valuePrefix = (indexed) ? "index" : "value";

        /**
         * Process a possible segment.
         * @param {TIMELINE_SEGMENT} segment
         * @param {SEGMENT_TYPE} segmentType
         */
        const processSegment = (segment, segmentType) => {
            if (this.hasSegment(segment)) {
                if (regionalLevel && this.hasRegionalLevel(segment, regionalLevel)) {
                    const timeline = fmtTimeline(this.getTimelineDescending(segment, regionalLevel), i18n.language, segmentType === "sale");
                    const timelineColumns = [valuePrefix + "Range", valuePrefix];
                    const mappedColumns = {};
                    for (const col of timelineColumns) {
                        mappedColumns[col] = segment + regionalLevel + col
                    }

                    headers.push({
                        categoryHeader: (segmentType === "rent" ? t("market.timelines.rent_prices") : t("market.timelines.sales_prices")) + unit,
                        columnHeaders: [t("market.timelines.span"), indexed ? t("market.timelines.index") : t("market.timelines.price")]
                    })
                    rowBuilders.push({timeline, timelineColumns, mappedColumns});

                } else if (!regionalLevel) {
                    const regionalLevels = this.getSupportedRegionalLevels(segment);
                    const columnHeaders = [];
                    for (const regionalLevel of regionalLevels) {
                        const timeline = fmtTimeline(this.getTimelineDescending(segment, regionalLevel), i18n.language, segmentType === "sale");
                        const timelineColumns = [valuePrefix];
                        const mappedColumns = {};
                        mappedColumns[valuePrefix] = segment + regionalLevel + valuePrefix

                        columnHeaders.push(t(REGIONAL_LEVEL_DATA[regionalLevel].nameI18n));
                        rowBuilders.push({timeline, timelineColumns, mappedColumns});
                    }
                    headers.push({
                        categoryHeader: (segmentType === "rent" ? t("market.timelines.rent_prices") : t("market.timelines.sales_prices")) + unit,
                        columnHeaders
                    })
                }
            }
        }

        processSegment(segmentSale, "sale");
        processSegment(segmentRent, "rent");

        if (rowBuilders.length < 1)
            return null;

        for (const rowBuilder of rowBuilders) {
            const {timelineColumns, mappedColumns} = rowBuilder;
            for (const col of timelineColumns) {
                columns.push(mappedColumns[col]);
            }
        }

        /** @type {object[]} */
        let rows = rowBuilders[0].timeline.map((tl, index) => {
            const row = {year: tl.year};
            for (const rowBuilder of rowBuilders) {
                const {timeline, timelineColumns, mappedColumns} = rowBuilder;
                if (index >= timeline.length) {
                    console.warn(`Invalid index ${index}: timelines are not all equally long!`)
                    continue;
                }
                for (const col of timelineColumns) {
                    row[mappedColumns[col]] = timeline[index][col];
                }
            }
            return row;
        });


        return {headers, columns, rows};
    }

    /**
     * Get Timeline Chart.
     * @param {SEGMENT_TYPE} segmentType
     * @param {TIMELINE_SEGMENT} segment
     * @param {boolean} indexed
     * @param {REGIONAL_LEVEL} regionalLevel
     * @param {LocationInfoAddress} addressData
     * @param {RevaChartStyle} chartStyle
     * @param {function(key: string): string} t translation function
     * @return {{datasets: *[]}|null}
     */
    getTimelineChartData(segmentType, segment, indexed, regionalLevel, addressData, chartStyle, t) {
        const keyValue = indexed ? "index" : "value";
        const keyValueLow = keyValue + "Low";
        const keyValueHigh = keyValue + "High";
        const datasets = [];
        const yearAsDate = year => `${year}-01-01`
        if (regionalLevel) {
            if (!this.hasRegionalLevel(segment, regionalLevel))
                return null;

            const timeLine = this.getTimeline(segment, regionalLevel);

            datasets.push({
                label: indexed ? t('market.timelines.index') :  t('market.timelines.price'),
                borderColor: getColor(chartStyle.colorAvg),
                fill: false,
                tension: chartStyle.tension,
                data: timeLine.map(tl => {
                    return {
                        x: yearAsDate(tl.year), y: tl[keyValue]
                    }
                })
            });
            datasets.push({
                label: t('market.timelines.low'),
                borderColor: getColor(chartStyle.colorLow),
                backgroundColor: getColor(chartStyle.fillLow),
                fill: 0,
                tension: chartStyle.tension,
                pointStyle: false,
                showLine: false,
                data: timeLine.map(tl => {
                    return {
                        x: yearAsDate(tl.year), y: tl[keyValueLow]
                    }
                })
            });
            datasets.push({
                label: t('market.timelines.high'),
                borderColor: getColor(chartStyle.colorHigh),
                backgroundColor: getColor(chartStyle.fillHigh),
                fill: 0,
                tension: chartStyle.tension,
                pointStyle: false,
                showLine: false,
                data: timeLine.map(tl => {
                    return {
                        x: yearAsDate(tl.year), y: tl[keyValueHigh]
                    }
                })
            });

        } else {
            if (!this.hasSegment(segment))
                return null;

            let colorIndex = 0;

            const regionalLevels = this.getSupportedRegionalLevels(segment);
            for (const regionalLevel of regionalLevels) {
                const timeLine = this.getTimeline(segment, regionalLevel);

                datasets.push({
                    label: t(REGIONAL_LEVEL_DATA[regionalLevel].nameI18n) + " " + addressData[REGIONAL_LEVEL_DATA[regionalLevel].address_field],
                    borderColor: getColor(chartStyle.colors[colorIndex++ % chartStyle.colors.length]),
                    tension: chartStyle.tension,
                    data: timeLine.map(tl => {
                        return {
                            x: yearAsDate(tl.year), y: tl[keyValue]
                        }
                    })
                });
            }

        }
        return {datasets};
    }

    /**
     * Get Timeline Chart options.
     * @param {SEGMENT_TYPE} segmentType
     * @param {boolean} indexed
     * @param {REGIONAL_LEVEL|null} regionalLevel
     * @param {RevaChartStyle} chartStyle
     * @param {i18n} i18n i18next object
     * @return {object}
     */
    getTimelineChartOptions(segmentType, indexed, regionalLevel, chartStyle, i18n) {
        /** @type {function(value: number): string} */
        let fmtNumber;
        if (indexed) {
            fmtNumber = (value) => formatIndex(value, i18n.language)
        } else {
            fmtNumber = (value) => formatPricePerArea(value, i18n.language, segmentType === 'sale')
        }
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            animation: false,
            interaction: {
                mode: 'x',
                intersect: false
            },
            scales: {
                x: {
                    type: 'timeseries',
                    ticks: {
                        color: getColor(chartStyle.ticks)
                    },
                    grid: {
                        color: getColor(chartStyle.grid)
                    },
                    time: {
                        unit: 'year',
                        tooltipFormat: 'yyyy'
                    }
                },
                y: {
                    beginAtZero: true,
                    grace: '10%',
                    ticks: {
                        color: getColor(chartStyle.ticks),
                        callback: fmtNumber
                    },
                    grid: {
                        color: getColor(chartStyle.grid)
                    }
                }
            },
            plugins: {
                legend: {},
                tooltip: {
                    callbacks: {
                        label: (context) => {
                            let label = context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                                label += fmtNumber(context.parsed.y);
                            }
                            return label;
                        }
                    }
                }
            }
        };
        if (regionalLevel) {
            // Show no legend
            options.plugins.legend.display = false;
            // Show no colored boxes in tooltip (only 1 dataset visible)
            options.plugins.tooltip.displayColors = false;
            // Only show first dataset (value, Average) in Tooltip
            options.plugins.tooltip.filter = (context) => {
                return context.datasetIndex === 0;
            };
            // Show span after value label
            options.plugins.tooltip.callbacks.afterLabel = (context) => {
                if (context.datasetIndex !== 0)
                    return null;
                const low = fmtNumber(context.chart.config.data.datasets[1].data[context.dataIndex].y);
                const high = fmtNumber(context.chart.config.data.datasets[2].data[context.dataIndex].y);
                return `${i18n.t("market.timelines.span")}: ${low} - ${high}`;
            }
        } else {
            options.plugins.legend = {
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'line',
                    borderWidth: 5,
                    pointStyleWidth: 20,
                    generateLabels: (chart) => {
                        return Legend.defaults.labels.generateLabels(chart).map(label => {
                            return {...label, lineWidth: 4};
                        });
                    }
                }
            };
            options.plugins.tooltip.boxPadding = 4;
            options.plugins.tooltip.boxHeight = 4 + chartStyle.tooltipColorBoxBorder;
            options.plugins.tooltip.boxWidth = 20 + (2 * chartStyle.tooltipColorBoxBorder);
            options.plugins.tooltip.callbacks.labelColor = (context) => {
                return {
                    borderColor: chartStyle.tooltipColorBoxBorder
                        ? getColor(chartStyle.tooltipColorBoxBorderColor)
                        : context.dataset.borderColor,
                    backgroundColor: context.dataset.borderColor,
                    borderWidth: chartStyle.tooltipColorBoxBorder,
                    borderRadius: 0,
                };
            }
        }
        return options;
    }
}
