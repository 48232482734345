import {useTranslation} from "react-i18next";

/**
 * Indicator, that data is still being loaded.
 * @param {boolean} loading only show loading indicator, if loading is true
 * @return {ReactNode | null} rendered indicator
 */
function LoadingIndicator({loading}) {

    const {t} = useTranslation();

    if (!loading)
        return null;

    return (
        <div className="flex-grow-1 flex flex-column justify-content-center align-items-center">
            <div className="pi pi-spin pi-spinner text-8xl"/>
            <div className="text-4xl mt-4">{t("general.loading")}</div>
        </div>
    );
}

export default LoadingIndicator;