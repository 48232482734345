import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {
    selectAddress,
    selectError,
    selectLoading,
    selectMissingPermissions,
    selectTimelineDataAggregated
} from "../../redux/locationInfoSlice";
import {selectDarkTheme} from "../../redux/themeSlice";
import {useState} from "react";
import {
    getChartStyle,
    OBJECT_TYPE_NAMES_I18N,
    OBJECT_TYPES,
    REGIONAL_LEVEL_DATA,
    REGIONAL_LEVELS,
    TimeLinesModel
} from "../../model/TimeLinesModel";

import {Dropdown} from "primereact/dropdown";
import {Checkbox} from "primereact/checkbox";
import {SelectButton} from "primereact/selectbutton";
import {DataTable} from "primereact/datatable";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import {Column} from "primereact/column";
import {Chart} from "primereact/chart";

import LoadingIndicator from "../../component/LoadingIndicator";
import MissingPermissionIndicator from "../../component/MissingPermissionIndicator";
import ErrorIndicator from "../../component/ErrorIndicator";

import 'chartjs-adapter-moment';

/** @typedef {import("primereact/selectbutton").SelectButtonChangeEvent} SelectButtonChangeEvent */
/** @typedef {import("../../model/TimeLinesModel").OBJECT_TYPE} OBJECT_TYPE */

/**
 * @param {TimelineTableData|null} timelineTableData
 * @param {OBJECT_TYPE} objectType
 * @param {REGIONAL_LEVEL|null} regionalLevel
 * @constructor
 */
function TimelinesTable({timelineTableData, objectType, regionalLevel}) {

    /** @type {LocationInfoAddress} */
    const addressData = useSelector(selectAddress);

    const {t} = useTranslation();

    if (!timelineTableData || !addressData)
        return null;

    const regionalInfo = (regionalLevel) ? (
        <span> ({t(REGIONAL_LEVEL_DATA[regionalLevel].nameI18n)} {addressData[REGIONAL_LEVEL_DATA[regionalLevel].address_field]})</span>
    ) : (
        <span> ({t("market.timelines.comparison_regional_levels")})</span>
    );

    const {headers, columns, rows} = timelineTableData;

    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header={t("market.timelines.year")} rowSpan={2} />
                {headers.map(h=> (
                    <Column key={h.categoryHeader} header={h.categoryHeader} colSpan={h.columnHeaders.length} />
                ))}
            </Row>
            <Row>
                {headers.flatMap(h => h.columnHeaders).map(h => (
                    <Column key={h} header={h} />
                ))}
            </Row>
        </ColumnGroup>
    );

    /** @type {Column[]} */
    const columnElements = columns.map(c => (<Column key={c} field={c}/>));

    return (
        <>
            <h3>{t("market.timelines.price_development")}</h3>
            <div className="text-500 mb-3">
                {t(OBJECT_TYPE_NAMES_I18N[objectType])}
                {regionalInfo}
            </div>
            <div className="border-round surface-100 p-3">
                <DataTable value={rows} headerColumnGroup={headerGroup}>
                    {columnElements}
                </DataTable>
            </div>
        </>
    );
}

/**
 * @param {AggregatedTimelineData} timelineData
 * @param {OBJECT_TYPE} objectType
 * @param {SEGMENT_TYPE} segmentType
 * @param {boolean} indexed
 * @param {REGIONAL_LEVEL|null} regionalLevel
 * @return {JSX.Element|null}
 * @constructor
 */
function TimelinesChart({timelineData, objectType, segmentType, indexed, regionalLevel}) {

    /** @type {LocationInfoAddress} */
    const addressData = useSelector(selectAddress);

    const {i18n, t} = useTranslation();

    /** @type {boolean} */
    const isDarkTheme = useSelector(selectDarkTheme);

    const timelineModel = new TimeLinesModel(timelineData);
    const chartStyle = getChartStyle(isDarkTheme);
    const segment = timelineModel.getTimelineSegments(objectType)[segmentType];

    let header = (segmentType === 'sale') ? t("market.timelines.sales_prices") : t("market.timelines.rent_prices");
    if (indexed) {
        header += ` (${t("market.timelines.indexed")})`;
    }

    const chartData = timelineModel.getTimelineChartData(segmentType, segment, indexed, regionalLevel, addressData, chartStyle, t);

    if (!chartData)
        return null;

    const chartOptions = timelineModel.getTimelineChartOptions(segmentType, indexed, regionalLevel, chartStyle, i18n);

    const regionalInfo = (regionalLevel) ? (
        <span> ({t(REGIONAL_LEVEL_DATA[regionalLevel].nameI18n)} {addressData[REGIONAL_LEVEL_DATA[regionalLevel].address_field]})</span>
    ) : null;

    return (
        <div className="border-round surface-100 p-3 mb-3">
            <h4 className="mt-0 mb-3">
                {header}
                <span className="text-sm ml-3 text-600">{t("market.timelines.for")} {t(OBJECT_TYPE_NAMES_I18N[objectType])}{regionalInfo}</span>
            </h4>
            <div className="w-full h-25rem surface-card">
                <Chart type="line" className="w-full h-full" data={chartData} options={chartOptions}/>
            </div>
        </div>
    );
}

/**
 * @param {AggregatedTimelineData} timelineData
 * @return {JSX.Element}
 * @constructor
 */
function TimelinesData({timelineData}) {

    const {t, i18n} = useTranslation();

    const timelineModel = new TimeLinesModel(timelineData);

    const supportedObjectTypes = timelineModel.getObjectTypes();
    if (!supportedObjectTypes.length) {
        supportedObjectTypes.push(OBJECT_TYPES[0]);
    }

    const [objectType, setObjectType] = useState(supportedObjectTypes[0]);

    const [indexed, setIndexed] = useState(false);

    const objectTypeOptions = supportedObjectTypes.map(value => {
        return {
            label: t(OBJECT_TYPE_NAMES_I18N[value]),
            value
        };
    });

    const existingRegionalLevels = timelineModel.getSupportedRegionalLevelsForObjectType(objectType);

    const [regionalLevel, setRegionalLevel] = useState(existingRegionalLevels.length ? existingRegionalLevels[0] : "");
    const [regionalLevelsAll, setRegionalLevelsAll] = useState(false);
    const effectiveRegionalLevel = regionalLevelsAll ? null : regionalLevel;

    const changeObjectType = (e) => {
        const newObjectType = e.value;
        setObjectType(newObjectType);
        const existingRegionalLevelsForNew = timelineModel.getSupportedRegionalLevelsForObjectType(newObjectType);
        setRegionalLevel(existingRegionalLevelsForNew.length ? existingRegionalLevelsForNew[0] : "")
    }

    /**
     * @param {SelectButtonChangeEvent} e
     */
    const updateRegionalLevel = (e) => {
        if (e.value && existingRegionalLevels.includes(e.value)) {
            setRegionalLevel(e.value);
        }
    };

    const timelineTableData = timelineModel.getTimelineTableData(objectType, indexed, effectiveRegionalLevel, i18n);

    if (!timelineData)
        return null;

    const regionLevelSelections = REGIONAL_LEVELS.map(level => {
        const levelData = REGIONAL_LEVEL_DATA[level]
        return {
            label: t(levelData.nameI18n),
            value: level,
            disabled: !existingRegionalLevels.includes(level)
        }
    });

    return (
        <>
            <div className="grid">
                <div className="col-12 lg:col-5">

                    <h3>{t("market.timelines.object")}</h3>
                    <div className="flex flex-wrap gap-3 mb-3 align-items-center">
                        <Dropdown value={objectType} options={objectTypeOptions} className="w-full md:w-14rem"
                                  onChange={changeObjectType}/>

                        <div className="ml-3 flex-nowrap">
                            <Checkbox inputId="indexed" name="indexed" value={indexed}
                                      onChange={(e) => setIndexed(e.checked)} checked={indexed} />
                            <label htmlFor="indexed" className="ml-3">{t("market.timelines.indexed")}</label>
                            </div>
                    </div>
                    <h3>{t("market.timelines.regional_level")}</h3>
                    <div className="flex flex-wrap gap-3 mb-3 align-items-center">
                        <SelectButton value={effectiveRegionalLevel} onChange={updateRegionalLevel}
                                      options={regionLevelSelections} optionDisabled="disabled"
                                      disabled={regionalLevelsAll}
                                      className="w-full md:w-auto"/>

                        <div className="ml-3 flex-nowrap">
                            <Checkbox inputId="compareRegionalLevels" name="compareRegionalLevels" value={regionalLevelsAll}
                                      onChange={(e) => setRegionalLevelsAll(e.checked)} checked={regionalLevelsAll} />
                            <label htmlFor="compareRegionalLevels" className="ml-3">{t("market.timelines.comparison_regional_levels")}</label>
                        </div>
                    </div>

                    <TimelinesTable objectType={objectType} regionalLevel={effectiveRegionalLevel}
                                    timelineTableData={timelineTableData}/>

                </div>
                <div className="col-12 lg:col-7 mt-3 lg:mt-0">
                    <TimelinesChart timelineData={timelineData} objectType={objectType} segmentType="sale"
                                    indexed={indexed} regionalLevel={effectiveRegionalLevel}/>
                    <TimelinesChart timelineData={timelineData} objectType={objectType} segmentType="rent"
                                    indexed={indexed} regionalLevel={effectiveRegionalLevel}/>
                </div>
            </div>
        </>
    );
}

export default function TabTimeLines() {

    const timelineLoading = useSelector(selectLoading);
    const timeLineError = useSelector(selectError);
    const missingPermissions = useSelector(selectMissingPermissions);

    /** @type {AggregatedTimelineData|null} */
    const timelineData = useSelector(selectTimelineDataAggregated);

    return (
        <>
            <ErrorIndicator errorObject={timeLineError} titleI18nKey="market.timelines.error"/>
            <LoadingIndicator loading={timelineLoading}/>
            <MissingPermissionIndicator missingPermissions={missingPermissions}/>
            {timelineData ? (<TimelinesData timelineData={timelineData}/>) : null}
        </>
    )
}