import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {EPSG_3857, LayerFactory, logMapDebug} from "../../util/maps";
import OpenLayersMap from "../../component/OpenLayersMap";
import {selectError, selectLoading, selectMissingPermissions, selectPoiData} from "../../redux/poisSlice";
import {useState} from "react";
import {SelectButton} from "primereact/selectbutton";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import LoadingIndicator from "../../component/LoadingIndicator";
import MissingPermissionIndicator from "../../component/MissingPermissionIndicator";
import ErrorIndicator from "../../component/ErrorIndicator";
import {LAYER_POIS_DECORATED, selectCapabilities} from "../../redux/wmtsSlice";
import {fromLonLat} from "ol/proj";
import {boundingExtent, buffer} from "ol/extent";
import {Tag} from "primereact/tag";
import {ColumnGroup} from "primereact/columngroup";
import {Row} from "primereact/row";
import * as GeoserverLayoutConfigurator from "ol/source/OSM";

/** @typedef {import("ol/format").GeoJSON} GeoJSON */

function PoisLocationLegend() {
    return (
        <>
            <h2>Erläuterung</h2>
            <p>
                Grundlage der Infrastruktur Karte sind ca 2.3 Mio. Objekte der OpenStreetMap Datenbank.
            </p>
            <p>
                Dargestellt werden alle Objekte, für die bezüglich der angefragten Adresse und mindestens eines Fuß-,
                Rad- oder
                Autopfades der geringste zeitliche Aufwand berechnet wurde.
            </p>
        </>
    );
}

function PoisTables({vehicle, types, pois, selectedFeature, rowEvent}) {

    const {i18n, t} = useTranslation();

    function featureToRowMapper(feature, vehicleMeta) {
        if (!feature.properties)
            return {}
        const point = feature.geometry.coordinates;
        const properties = feature.properties
        return {
            key: properties["type_" + i18n.language],
            name: properties["name"],
            duration: properties[vehicleMeta + "_duration_min"],
            distance: properties[vehicleMeta + "_path_length_m"],
            category: properties["category_" + i18n.language],
            latlon: point,
            category_key: properties["category_en"]
        }
    }

    const tableMapper = (vehicleMeta) => {
        const values = types
            .map(c => vehicleMeta + '_' + c)
            .filter(c => pois[c])
            .map(c => pois[c])
            .map(feature => featureToRowMapper(feature, vehicleMeta));
        //sortiere zunächst nach duration und dann nach distance
        return values.sort((e1, e2) => {
            if (e1.duration && !e2.duration)
                return -1;
            if (e2.duration && !e1.duration)
                return 1;
            if (e1.duration === e2.duration)
                return e1.distance - e2.distance;
            return e1.duration - e2.duration
        });
    }
    const rows = tableMapper(vehicle);
    let selectedRow = selectedFeature ? Array.from(rows).filter(r => r.key === selectedFeature["type_" + i18n.language])[0] : null;
    const categoryTemplate = (feature) => {
        const classname = feature.category_key?.toLowerCase().replace(' ', '')
        if (!classname)
            return <p></p>
        return <Tag value={feature.category} className={classname}></Tag>;
    };
    const headerGroup = (
        <ColumnGroup>
            <Row>
                <Column header={t('pois.table_header.type')} field="key" sortable rowSpan={2}></Column>
                <Column header={t('pois.table_header.name')} field="name" sortable rowSpan={2}></Column>
                <Column header={t('pois.table_header.distance')} colSpan={2} style={{border: "none"}}></Column>
                <Column header={t('pois.table_header.category')} field="category" sortable rowSpan={2}></Column>
            </Row>
            <Row>
                <Column field="distance" header={t('pois.table_header.distance_unit')} sortable></Column>
                <Column field="duration" header={t('pois.table_header.duration_unit')} sortable></Column>
            </Row>
        </ColumnGroup>
    );
    return (
        <DataTable value={rows} headerColumnGroup={headerGroup} selectionMode='single' size="small" className="text-sm" scrollable
                   scrollHeight="flex" onRowSelect={rowEvent} selection={selectedRow}>
            <Column field="key"></Column>
            <Column field="name"></Column>
            <Column field="distance"></Column>
            <Column field="duration"></Column>
            <Column body={categoryTemplate}></Column>
        </DataTable>
    );

}

function PoisMapAndTable() {
    const {i18n, t} = useTranslation();

    const vehicles = ['car', 'bike', 'foot'];
    const vehicleToLabel = {
        'car': t('pois.vehicle_option.car'),
        'bike': t('pois.vehicle_option.bike'),
        'foot': t('pois.vehicle_option.foot'),

    }
    const poisData = useSelector(selectPoiData);

    const capabilities = useSelector(selectCapabilities);

    const layerCapabilities = capabilities?.[LAYER_POIS_DECORATED];

    const vehicleItems = vehicles.map(
        v => {
            return {value: v, label: vehicleToLabel[v]};
        }
    )
    const [vehicle, setVehicle] = useState('car')
    const [extent, setExtent] = useState(undefined);
    const [highlightedPoi, setHighlightedPoi] = useState(-1)
    const [selectedFeature, setSelectedFeature] = useState(undefined);


    if (!layerCapabilities) {
        logMapDebug(`Keine Eintrag für Layer ${LAYER_POIS_DECORATED} in Capabilities gefunden`)
        return null;
    }

    if (!poisData) {
        return null;
    }


    /**
     * Calculate zoom level from GeoRef poisData.
     * @param {GeoRefAddress} addressMeta
     * @return {number} zoom level
     */
    const zoomFromCandidate = (addressMeta) => {
        return 16.3;
    };

    /**
     * @param {SelectButtonChangeEvent} e
     */
    const updateVehicle = (e) => {
        logMapDebug("Setze Vehicle auf  " + e.value + " Features");
        if (e.value && vehicles.includes(e.value)) {
            setVehicle(e.value);
            setHighlightedPoi(-1);
            setSelectedFeature(undefined);
        }
    };

    const addressMeta = poisData.address;
    const groupedPois = poisData.groupedPois;
    let source;
    const features = poisData.types.map(t => vehicle + '_' + t).map(t => groupedPois[t]).filter(t => t);
    source = {type: 'FeatureCollection', features: features};
    let info = document.getElementById('info');
    let currentFeature;


    const displayFeatureInfo = function (map, pixel) {
        try {
            const feature = map.forEachFeatureAtPixel(pixel, (feature, layer) => {
                return feature;
            });
            if (!feature) {
                setHighlightedPoi(-1);
                return
            }
            if (feature !== currentFeature) {
                //für PoIs Tooltip aus Kategorie und Namen bilden
                if (!feature.id_) {
                    setSelectedFeature(feature.values_);
                }
                //für angefragte Adresse Tooltip aus den Adressdaten bilden
                else if (feature.id_ === 'marker_point') {
                }
            }
            currentFeature = feature;
            const featureKey = feature.values_["type_" + i18n.language];
            const index = poisKeys.indexOf(featureKey);
            setHighlightedPoi(index);
            setExtent([0, 0, 0, 0])
        } catch (e) {
            console.warn(e)
        }

    };
    const clickHandler = (map, evt) => {
        if (evt.dragging) {
            currentFeature = undefined;
            return;
        }
        const pixel = map.getEventPixel(evt.originalEvent);
        displayFeatureInfo(map, pixel);
    }

    const mapModel = {
        view: {
            ...addressMeta,
            zoom: zoomFromCandidate(addressMeta),
            resetCenterOnChange: true,
            extentToFit: extent,
            fixedZoom: true
        },
        layers: [
            LayerFactory.createGeoserverWmtsLayer(LAYER_POIS_DECORATED, addressMeta, undefined, "pois_decorated", undefined, {
               ...layerCapabilities,
                params : {
                   attributions : '© <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
                }
            }),
            //TODO fix brd_negativ Layer in Proxy or Geoserver
            //LayerFactory.createGeoserverWmsLayer("reva:brd_negativ", undefined),
            LayerFactory.createCircleLayer(addressMeta, [500, 1000, 2000, 5000], i18n),
            LayerFactory.createPoisLayer(vehicle, source, [highlightedPoi]),
            LayerFactory.createMarkerLayer(addressMeta, i18n, 'pois_requested_address', highlightedPoi > -1)
        ],
        eventHandlers: {
            click_event: clickHandler,
        }

    }

    const poisKeys = features.map(f => f.properties["type_" + i18n.language]);
    const rowEvent = (event) => {
        //Transformation nach Webmercator für Einheitslänge~1m
        const coord1 = fromLonLat([poisData.address.lon, poisData.address.lat], EPSG_3857)
        const coord2 = fromLonLat(event.data.latlon, EPSG_3857)
        const deltaX = Math.abs(coord1[0] - coord2[0])
        const deltaY = Math.abs(coord1[1] - coord2[1])
        //Berechne Kartenausschnitt für Latlon mit Mittelpunkt abgefragte Adresse
        var ext = boundingExtent([[coord1[0] - deltaX, coord1[1] - deltaY], [coord1[0] + deltaX, coord1[1] + deltaY]]);
        //Berechne Buffer aus den Kantenlängen des Kartenausschnitts, damit Punkte am Rand gut sichtbar sind
        const dist = Math.hypot(ext[2] - ext[0], ext[3] - ext[1])
        ext = buffer(ext, dist * 0.1)
        setExtent(ext);
        const index = poisKeys.indexOf(event.data.key);
        setSelectedFeature(event.data.category_key);
        setHighlightedPoi(index);
    };

    return (
        <div className="flex h-full">
            <div className="w-3 flex flex-column" style={{minWidth: "40em"}}>

                <SelectButton value={vehicle} options={vehicleItems} onChange={updateVehicle}
                              className="mb-3 flex-0"/>
                <div className="flex-1 relative">
                    <div className="absolute h-full w-full border-round surface-100 p-3">
                            <PoisTables vehicle={vehicle} types={poisData.types} pois={poisData.groupedPois}
                                        selectedFeature={selectedFeature}
                                        rowEvent={rowEvent}/>
                        </div>
                    </div>
                </div>
                <div className="OpenLayersMapBackdrop w-9 ml-3">
                <OpenLayersMap model={mapModel} legend={<PoisLocationLegend/>}/>
            </div>
        </div>
    );
}


export default function TabPoisLocation() {

    const poisLoading = useSelector(selectLoading);
    const poisError = useSelector(selectError);
    const missingPermissions = useSelector(selectMissingPermissions);

    return (
        <>
            <div id="info" className="tooltip"></div>
            <ErrorIndicator errorObject={poisError} titleI18nKey="pois.error"/>
            <LoadingIndicator loading={poisLoading}/>
            <MissingPermissionIndicator missingPermissions={missingPermissions}/>
            <PoisMapAndTable/>
        </>
    );
}