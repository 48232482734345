import {selectByLanguage} from "../util/language";
import {formatTypedValue, parseGuiType} from "../util/numberFormat";
import {GeneralModel} from "./GeneralModel";
import {WebError} from "../api";
import {ErrorI18n} from "../i18n";

/**
 * @typedef {"INCOME_VALUE"|"REAL_ASSET_VALUE"|"COMPARATIVE_VALUE"} RevaIndicationEndpoint
 */

/**
 * @typedef {object} RevaSpec
 * @property {Object.<RevaIndicationEndpoint, RevaSpecOutputPage>} pages
 * @property {Object} inputs
 */

// TODO: TypeDefs for Inputs

/**
 * @typedef {object} RevaSpecOutputPage
 * @property {RevaSpecOutputColumn[]} columns
 * @property {RevaSpecOutputRowGroup[]} rowGroups
 * @property {RevaSpecOutputRow[]} rows
 */

/**
 * @typedef {object} RevaSpecOutputColumn
 * @property {string} field field in row data to select definition for cell
 * @property {string} headerDE column header in language=DE
 * @property {string} headerEN column header in language=EN
 */

/**
 * @typedef {object} RevaSpecOutputRowGroup
 * @property {string} group key to identify the group when referenced from row
 * @property {string} headerDE sub-header in language=DE
 * @property {string} headerEN sub-header in language=EN
 * @property {string[]|null} hideUnlessApiParametersExisting optional list of parameters, of which at least one must have an existing value in order to display the group
 */

/**
 * @typedef {object} RevaSpecOutputRow
 * @property {string} group reference to a row group
 * @property {string} titleDE
 * @property {string} titleEN
 * @property {boolean} [important] optional Flag to render row in bold
 * @property {boolean} [underline] optional Flag to render line below row
 *
 * @property {RevaSpecOutputRef|null} [factor]
 * @property {RevaSpecOutputRef|null} [market_value_factor]
 * @property {RevaSpecOutputRef|null} [market_value]
 * @property {RevaSpecOutputRef|null} [lending_value_factor]
 * @property {RevaSpecOutputRef|null} [lending_value]
 *
 * @property {string[]|null} hideUnlessApiParametersExisting optional list of parameters, of which at least one must have an existing value in order to display the group
 */

/*
 * Display formats:
 * CATEGORY: show titleDE/EN of corresponding category
 * INTEGER: int, no decimal places, grouping on
 * YEAR: int, no decimal places, no grouping
 * DATE: formatted as date in locale (dd.MM.yyyy or yyyy-MM-dd)
 * DOUBLE: double, Default=2 decimal places, grouping on
 * DOUBLE(x) like DOUBLE(0) or DOUBLE(2): use exactly x decimal places, grouping on
 * DOUBLE(x-y) like DOUBLE(0-2): use between x and y decimal places, grouping on
 * DOUBLE(+) or DOUBLE(+-) or INT(+-): modification to always display sign
 * DOUBLE(+-,4) or DOUBLE(+;2-5): multiple modification separated by ; or ,
 */

/**
 * @typedef {object} RevaSpecOutputRef
 * @property {string} field JSON field to display
 * @property {string} format display format
 * @property {string} [unitDE] unit (in language DE)
 * @property {string} [unitEN] unit (in language EN)
 * @property {boolean} hideIfEmpty flag, if empty values should be hidden (or displayed as Zero, if flag is false)
 * @property {string|null} [valueIfEmpty] optional value to display instead, if value is empty
 * @property {RevaSpecOutputCategory[]} [categories] optional categories (only relevant if format=CATEGORY)
 */

/**
 * @typedef {object} RevaSpecOutputCategory
 * @property {number} category
 * @property {string} titleDE
 * @property {string} titleEN
 */

/** @type {RevaSpecOutputColumn[]} */
const COLUMNS = [
    { headerDE: "Anzahl / Ansatz", headerEN: "Count / Factor", field: "factor" },
    { headerDE: "Wertansatz Marktwert", headerEN: "Market Value Factor", field: "market_value_factor" },
    { headerDE: "Marktwert", headerEN: "Market Value", field: "market_value" },
    { headerDE: "Wertansatz Beleihungswert", headerEN: "Lending Value Factor", field: "lending_value_factor" },
    { headerDE: "Beleihungswert", headerEN: "Lending Value", field: "lending_value" }
];

/**
 * @typedef {object} ValueIndicationDataRow
 * @property {string} group
 * @property {string} title
 * @property {boolean} important
 * @property {string|null} [factor]
 * @property {string|null} [market_value_factor]
 * @property {string|null} [market_value]
 * @property {string|null} [lending_value_factor]
 * @property {string|null} [lending_value]
 */

export class SpecModel extends GeneralModel {

    /**
     * Handle and validate response data.
     * @param apiResponsePromise {Promise<object>} response promise from API call
     * @return {Promise<RevaSpec>}
     */
    async handleResponse(apiResponsePromise) {
        try {
            const apiResponse = await apiResponsePromise;
            return {
                pages: {
                    "COMPARATIVE_VALUE": this._convertPage(apiResponse?.reva?.COMPARATIVE_VALUE),
                    "INCOME_VALUE": this._convertPage(apiResponse?.reva?.INCOME_VALUE),
                    "REAL_ASSET_VALUE": this._convertPage(apiResponse?.reva?.REAL_ASSET_VALUE)
                },
                inputs: {
                    "VALUE": this._convertInputs(apiResponse?.avm?.VALUE)
                }
            };
        } catch (e) {
            if (e instanceof WebError) {
                switch (e.status) {
                    case 401: throw new ErrorI18n('general.error.401');
                    case 403: throw new ErrorI18n('general.error.403');
                    case 409: throw new ErrorI18n('address.error.409');
                    case 422: throw new ErrorI18n('address.error.422');
                    case 503: throw new ErrorI18n('general.error.503');
                    case 500: throw new ErrorI18n('general.error.unspecific');
                    default:
                }
                if (e.getCustomErrorMessage()) {
                    throw new ErrorI18n('general.error.custom', {errorMessage: e.getCustomErrorMessage()});
                }
            }
            throw new ErrorI18n('general.error.invalid');
        }
    }

    /**
     * Convert a page from API result to internal format.
     * @param {object} endpointSpec
     * @return {RevaSpecOutputPage}
     * @private
     */
    _convertPage(endpointSpec) {
        /** @type {RevaSpecOutputRowGroup[]} */
        let rowGroups = [];
        /** @type {RevaSpecOutputRow[]} */
        let rows = [];
        if (endpointSpec && typeof endpointSpec === "object" && Array.isArray(endpointSpec["groups"]) && Array.isArray(endpointSpec["rows"])) {
            /** @type {object[]} */
            const specGroups = endpointSpec["groups"];
            /** @type {object[]} */
            const specRows = endpointSpec["rows"];
            rowGroups = specGroups.map((groupSpec) => this._convertGroup(groupSpec));
            rows = specRows.map((rowSpec) => this._convertRow(rowSpec));
        } else {
            console.warn("Found invalid response for spec page:", endpointSpec);
        }
        return {
            columns: COLUMNS,
            rowGroups,
            rows
        }
    }

    /**
     * Convert a row group from API result to internal format.
     * @param {object} groupSpec
     * @return {RevaSpecOutputRowGroup}
     * @private
     */
    _convertGroup(groupSpec) {
        return {
            group: groupSpec["group"],
            headerDE: groupSpec["titleDe"],
            headerEN: groupSpec["titleEn"],
            hideUnlessApiParametersExisting: groupSpec["hideUnlessApiParametersExisting"]
        }
    }

    /**
     * Convert a row from API result to internal format.
     * @param {object} rowSpec
     * @return {RevaSpecOutputRow}
     * @private
     */
    _convertRow(rowSpec) {
        return {
            group: rowSpec["group"],
            titleDE: rowSpec["titleDe"],
            titleEN: rowSpec["titleEn"],
            important: rowSpec["formatBold"],
            underline: rowSpec["formatLineBelow"],
            factor: this._convertOutputRef(rowSpec["factor"]),
            market_value_factor: this._convertOutputRef(rowSpec["marketValueFactor"]),
            market_value: this._convertOutputRef(rowSpec["marketValue"]),
            lending_value_factor: this._convertOutputRef(rowSpec["lendingValueFactor"]),
            lending_value: this._convertOutputRef(rowSpec["lendingValue"]),
            hideUnlessApiParametersExisting: rowSpec["hideUnlessApiParametersExisting"]
        }
    }

    /**
     * Convert an output ref from API result to internal format.
     * @param {object} outputSpec
     * @return {RevaSpecOutputRef|null}
     * @private
     */
    _convertOutputRef(outputSpec) {
        if (!outputSpec || typeof outputSpec !== "object") {
            return null;
        }
        return {
            field: outputSpec["apiParam"],
            format: outputSpec["displayFormat"],
            unitDE: outputSpec["unitDe"],
            unitEN: outputSpec["unitEn"],
            hideIfEmpty: outputSpec["hideIfEmpty"],
            valueIfEmpty: outputSpec["valueIfEmpty"],
            categories: this._convertCategories(outputSpec["categories"])
        }
    }

    /**
     * Convert an output ref from API result to internal format.
     * @param {object[]|null} [categoriesSpec]
     * @return {RevaSpecOutputCategory[]|null}
     * @private
     */
    _convertCategories(categoriesSpec) {
        if (!Array.isArray(categoriesSpec)) {
            return null;
        }
        return categoriesSpec.map((categorySpec) => {
            return {
                category: categorySpec["categoryNumber"],
                titleDE: categorySpec["nameDe"],
                titleEN: categorySpec["nameEn"]
            }
        });
    }

    _convertInputs(specJson) {
        if (typeof specJson !== 'object' || !Array.isArray(specJson.segments)) {
            console.warn("Invalid spec?", specJson);
        }
        const inputsBySegmentArr = specJson.segments.map(segment => {
            let inputs = specJson.inputParameters[segment];
            if (!Array.isArray(inputs)) {
                inputs = [];
            }
            const inputMeta = inputs.map(input => {
                const {parameter, required, type, minValue, maxValue, nameDe, nameEn, unitDe, unitEn, categories} = input;
                let convertedCategories = null;
                if (Array.isArray(categories)) {
                    convertedCategories = categories.map(category => {
                        const {categoryNumber, shortNameDe, shortNameEn, titleDe, titleEn} = category;
                        return {categoryNumber, shortNameDe, shortNameEn, titleDe, titleEn};
                    });
                }
                const parameterMeta = {parameter, required, type, minValue, maxValue, nameDe, nameEn, unitDe, unitEn, categories: convertedCategories};
                return [parameter, parameterMeta];
            })
            return [segment, Object.fromEntries(inputMeta)];
        });
        return Object.fromEntries(inputsBySegmentArr);
    }

}

/**
 * Compute and aggregate the data for indication details to the row-data to display it as a table
 * @param {string} lang language
 * @param {RevaSpec} spec specification
 * @param {RevaIndicationEndpoint} endpoint for the indication details
 * @param {object} indicationDetails details for the endpoint, containing key-value pairs of the data from the API
 * @return {ValueIndicationDataRow[]} rows to render
 */
export function aggregateIndicationTableData(lang, spec, endpoint, indicationDetails) {
    if (!spec || !endpoint || !spec.pages || !spec.pages[endpoint]) {
        console.warn("invalid call to aggregateIndicationTableData()", arguments);
        return [];
    }
    /** @type {RevaSpecOutputPage} */
    const pageSpec = spec.pages[endpoint];
    if (!Array.isArray(pageSpec.rowGroups) || !Array.isArray(pageSpec.rows) || !Array.isArray(pageSpec.columns)) {
        console.warn("invalid call to aggregateIndicationTableData()", arguments);
        return [];
    }

    return pageSpec.rowGroups.filter(group => {
        if (Array.isArray(group.hideUnlessApiParametersExisting) && group.hideUnlessApiParametersExisting.length > 0) {
            return group.hideUnlessApiParametersExisting.some(param => indicationDetails.hasOwnProperty(param) && indicationDetails[param] !== null)
        }
        return true;
    }).flatMap(group => {
        const rows = pageSpec.rows.filter(row => row.group === group.group);
        return rows.filter(row => {
            // Filter out rows with an explicit list of required parameters
            if (Array.isArray(row.hideUnlessApiParametersExisting) && row.hideUnlessApiParametersExisting.length > 0) {
                const anyParamExisting = row.hideUnlessApiParametersExisting.some(param => indicationDetails.hasOwnProperty(param) && indicationDetails[param] !== null);
                if (!anyParamExisting) {
                    return false;
                }
            }
            // Only show rows with at least 1 visible cell
            for (const col of pageSpec.columns) {
                if (row[col.field]) {
                    /** @type {RevaSpecOutputRef} */
                    const cell = row[col.field];
                    // check if cell has something to display and is visible
                    if (typeof cell.field === "string") {
                        if (indicationDetails.hasOwnProperty(cell.field) && indicationDetails[cell.field] !== null)
                            return true; // Has value to display
                        if (!cell.hideIfEmpty)
                            return true; // displays either zero value or default value
                    }
                }
            }
            return false;
        }).map(row => {
            let result = {
                group: selectByLanguage(lang, group.headerDE, group.headerEN),
                title: selectByLanguage(lang, row.titleDE, row.titleEN),
                important: row.important,
                underline: row.underline
            }
            for (const col of pageSpec.columns) {
                if (row[col.field]) {
                    /** @type {RevaSpecOutputRef} */
                    const cell = row[col.field];
                    let renderedValue = null;
                    if (typeof cell.field === "string") {
                        if (indicationDetails.hasOwnProperty(cell.field) && indicationDetails[cell.field] !== null) {
                            const responseValue = indicationDetails[cell.field];
                            renderedValue = formatCell(lang, responseValue, cell);
                        } else if (typeof cell.valueIfEmpty === "string") {
                            renderedValue = cell.valueIfEmpty;
                        } else if (!cell.hideIfEmpty) {
                            const fallbackValue = 0.0;
                            renderedValue = formatCell(lang, fallbackValue, cell);
                        }
                    }
                    if (renderedValue !== null) {
                        result[col.field] = renderedValue;
                    }
                }
            }
            return result;
        });
    })
}

/**
 * Format a cell value.
 * @param {RevaLanguage} lang language
 * @param {*} value JSON value for rendered field (guaranteed non-null)
 * @param {RevaSpecOutputRef} cell cell reference definition
 * @return {string} rendered value
 */
function formatCell(lang, value, cell) {
    const guiType = parseGuiType(cell.format, cell.unitDE, cell.unitEN, cell.categories);
    return formatTypedValue(lang, value, guiType);
}