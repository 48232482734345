import {Message} from "primereact/message";
import {useTranslation} from "react-i18next";
import UiFragment from "./UiFragment";

/**
 * Standardized error message panel for translatable error
 * @param {boolean} [enabled] optional enabled-flag, which needs to be truthy in order to render the message
 * @param {string|null} [titleI18nKey] translation key of error title. If empty, no title will be displayed
 * @param {string|null} i18nKey translation key to render. If empty, no error message will be rendered
 * @param {object|null} [i18nArgs] optional arguments to the translation key
 * @param {boolean} [hasContactLink] optional flag, if a contact link should also be displayed
 * @param {string} [className] optional class name for the messages. If missing, the message will be full-width.
 * @param {ApiQueryError|null} [errorObject] instead of parameters enabled, i18nKey, i18nArgs and hasContactLink, an error object can be given
 * @return {JSX.Element|null}
 * @constructor
 */
export default function I18nErrorMessage({enabled, titleI18nKey, i18nKey, i18nArgs, hasContactLink, className, errorObject}) {

    const {t} = useTranslation();

    let i18nKeyEffective = i18nKey;
    let i18nArgsEffective = i18nArgs;
    let hasContactLinkEffective = hasContactLink;

    if (typeof errorObject !== 'undefined') {
        if (typeof errorObject !== 'object' || errorObject === null) {
            // Error object was provided, but was empty - no error stored
            return null;
        }
        i18nKeyEffective = errorObject.error;
        i18nArgsEffective = errorObject.errorArgs;
        hasContactLinkEffective = errorObject.hasContactLink;

    } else if (typeof enabled !== 'undefined' && !enabled) {
        // No error object was provided, but an enabled-flag, which was not set
        return null;
    }

    if (!i18nKeyEffective) {
        // No error key was set
        return null;
    }

    const classNameEffective = (typeof className !== 'string') ? "w-full" : className;

    const messageNode = titleI18nKey ? (
        <div className={"p-inline-message p-component p-inline-message-error block " + classNameEffective}>
            <div className="mb-3 text-2xl">
                <span className="pi pi-times-circle text-2xl mr-3"></span>
                {t(titleI18nKey)}
            </div>
            <span>{t(i18nKeyEffective, i18nArgsEffective)}</span>
        </div>
    ) : (
        <Message severity="error" text={t(i18nKeyEffective, i18nArgsEffective)} className={classNameEffective}/>
    );

    return (
        <>
            {messageNode}
            <UiFragment rendered={hasContactLinkEffective}>
                <Message severity="warn" className={classNameEffective + " mt-2"} content={(
                    <span>
                        {t('general.contactOnError')}
                        <a href="https://www.value-marktdaten.de/contact" target="_blank" rel="noreferrer"
                           style={{color: "inherit"}}
                           className="p-button-label no-underline ml-2 text-war">{t('general.contact')}</a>
                    </span>)}
                />
            </UiFragment>
        </>
    );
}