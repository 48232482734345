import logoLight from '../images/logo_value_marktdaten.png';
import logoDark from '../images/logo_value_marktdaten_dark.png';

import {useRef, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from "react-i18next";

import {selectUsername} from '../redux/authSlice'
import {selectAddressData} from "../redux/addressSlice";
import {selectDarkTheme} from "../redux/themeSlice";
import {performLogout} from "../redux/loginSlice";

import {Button} from "primereact/button";
import {ConfirmPopup} from "primereact/confirmpopup";
import {handlePreventDefault} from "../util/events";

import {useNavigate, useMatch} from "react-router-dom";
import {addAddressQuery} from "../router";
import {formatLatLon} from "../util/numberFormat";

/**
 * Sidebar/Nav-Bar.
 * @return {JSX.Element} MainView element
 * @constructor
 */
function Header() {

    const {t, i18n} = useTranslation();

    const navigate = useNavigate();

    /** @type {boolean} */
    const isDarkTheme = useSelector(selectDarkTheme);
    /** @type {string} */
    const username = useSelector(selectUsername);
    /** @type {SelectAddressData|null} */
    const addressData = useSelector(selectAddressData);

    const signOutButton = useRef(null);
    const [confirmVisible, setConfirmVisible] = useState(false);

    const dispatch = useDispatch()

    const matchPathAddress = useMatch("/address/*");

    const onLogoutClick = () => {
        dispatch(performLogout());

        // Navigate to initial view (with no selected address)
        navigate("/address")
    };

    let addressElement = null;
    if (!matchPathAddress && addressData) {
        const addressRoute = "/address" + addAddressQuery(addressData);
        addressElement = (
            <div className="mt-2 text-lg">
                <span className="pi pi-map-marker mr-2"></span>
                <a className="text-color focus:text-primary-500 hover:text-primary-500 no-underline"
                    href={addressRoute} onClick={handlePreventDefault(() => navigate(addressRoute))}>
                    {addressData.addressQueryClean} (Lat: {formatLatLon(addressData.lat, i18n.language)} / Lon: {formatLatLon(addressData.lon, i18n.language)})
                </a>
            </div>
        );
    }

    return (
        <header className="AppHeader flex-grow-0">
            <div className="flex p-0 surface-ground">
                <div className="AppHeaderLogo flex-grow-0 flex-shrink-0 p-3 align-self-center flex justify-content-center w-20rem">
                    <img src={isDarkTheme ? logoDark : logoLight} className="App-logo m-auto" height="70" alt="LOGO"/>
                </div>
                <div className="AppHeaderTitle flex-grow-1 px-3 py-1 align-self-center">
                    <span className="text-3xl">Real Estate Valuation Assistant</span>
                    {addressElement}
                </div>
                <div className="AppHeaderUser flex-grow-0 flex-shrink-0 p-3 align-self-center">
                    <ConfirmPopup target={signOutButton.current} visible={confirmVisible}
                                  icon="pi pi-exclamation-triangle"
                                  onHide={() => setConfirmVisible(false)}
                                  accept={onLogoutClick}
                                  message={t('header.signOutConfirm')}
                                  acceptClassName="p-button-danger"
                                  acceptLabel={t('header.signOutConfirmYes')}
                                  rejectLabel={t('header.signOutConfirmNo')}/>
                    <Button icon="pi pi-user" size="small" onClick={() => setConfirmVisible(true)}
                            label={t('header.signOut', {username})} ref={signOutButton} text />
                </div>
            </div>
        </header>
    );
}

export default Header;
