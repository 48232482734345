import {configureStore} from '@reduxjs/toolkit';
import authSlice from './authSlice';
import loginSlice from './loginSlice';
import themeSlice from './themeSlice';
import envSlice from "./envSlice";
import addressSlice from "./addressSlice";
import locationInfoSlice from "./locationInfoSlice";
import poisSlice from "./poisSlice";
import specSlice from "./specSlice";
import valueIndicationSlice from "./valueIndicationSlice";
import objectParametersSlice from "./objectParametersSlice";
import importSlice from "./importSlice";
import wmtsSlice from "./wmtsSlice";

/**
 * @typedef RevaExtraArgs
 * @property {AppEnvironment} env environment
 * @property {ServiceApi} serviceApi service API
 */

/** @typedef {import("redux-thunk/src/types").ThunkAction} ThunkAction */

/**
 * @template T
 * @typedef {ThunkAction<T,*,RevaExtraArgs,*>} RevaThunkAction
 */

/**
 * @typedef ApiQueryError
 * @property {string} error
 * @property {object|null} errorArgs
 * @property {boolean} hasContactLink
 */

/**
 * Initialize Redux Store.
 * @param {AppEnvironment} env app environment
 * @param {ServiceApi} serviceApi service API
 */
export default function initStore(env, serviceApi) {
    return configureStore({
        reducer: {
            auth: authSlice,
            login: loginSlice,
            theme: themeSlice,
            env: envSlice,
            spec: specSlice,
            address: addressSlice,
            locationInfo: locationInfoSlice,
            pois: poisSlice,
            wmts: wmtsSlice,
            valueIndication: valueIndicationSlice,
            objectParameters: objectParametersSlice,
            import: importSlice
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                thunk: {
                    extraArgument: { env, serviceApi }
                }
            })
    });
};
