import {selectByLanguage} from "./language";

const INTL_DE_INDEX = new Intl.NumberFormat("de-DE", {minimumFractionDigits: 1, maximumFractionDigits: 1});
const INTL_EN_INDEX = new Intl.NumberFormat("en-US", {minimumFractionDigits: 1, maximumFractionDigits: 1});

const INTL_DE_VALUE = new Intl.NumberFormat("de-DE", {minimumFractionDigits: 2, maximumFractionDigits: 2});
const INTL_EN_VALUE = new Intl.NumberFormat("en-US", {minimumFractionDigits: 2, maximumFractionDigits: 2});

const INTL_DE_VALUE_NO_LIMIT = new Intl.NumberFormat("de-DE", {maximumFractionDigits: 6});
const INTL_EN_VALUE_NO_LIMIT = new Intl.NumberFormat("en-US", {maximumFractionDigits: 6});

const INTL_DE_VALUE_NO_FRAC = new Intl.NumberFormat("de-DE", {minimumFractionDigits: 0, maximumFractionDigits: 0});
const INTL_EN_VALUE_NO_FRAC = new Intl.NumberFormat("en-US", {minimumFractionDigits: 0, maximumFractionDigits: 0});

/**
 * Format index value.
 * @param {number} value the value to format
 * @param {RevaLanguage} [language] optional language identifier
 * @return {string} formatted String
 */
export function formatIndex(value, language) {
    if (typeof value !== 'number')
        return "";
    const nf = selectByLanguage(language, INTL_DE_INDEX, INTL_EN_INDEX);
    return nf.format(value);
}

/**
 * Format value.
 * @param {number} value the value to format
 * @param {RevaLanguage} [language] optional language identifier
 * @param {boolean} [noFraction] optional flag: render values only as integer (no fraction digits) (default is false)
 * @return {string} formatted String
 */
export function formatValue(value, language, noFraction) {
    if (typeof value !== 'number')
        return "";
    const nf = noFraction
        ? selectByLanguage(language, INTL_DE_VALUE_NO_FRAC, INTL_EN_VALUE_NO_FRAC)
        : selectByLanguage(language, INTL_DE_VALUE, INTL_EN_VALUE);
    return nf.format(value);
}

/**
 * Format Latitude or Longitude value.
 * @param {number} value the value to format
 * @param {RevaLanguage} [language] optional language identifier
 * @return {string} formatted String
 */
export function formatLatLon(value, language) {
    if (typeof value !== 'number')
        return "";
    const nf = selectByLanguage(language, INTL_DE_VALUE_NO_LIMIT, INTL_EN_VALUE_NO_LIMIT);
    return nf.format(value);
}

/**
 * Format price (in Euro).
 * @param {number} value the value to format
 * @param {RevaLanguage} [language] optional language identifier
 * @param {boolean} [noFraction] optional flag: render values only as integer (no fraction digits) (default is false)
 * @param {string} [currency] optional currency (default is "€")
 * @return {string} formatted String
 */
export function formatPrice(value, language, noFraction, currency) {
    if (typeof value !== 'number')
        return "";
    return formatValue(value, language, noFraction) + " " + (currency || "€");
}

/**
 * Format price per area(in Euro).
 * @param {number} value the value to format
 * @param {RevaLanguage} [language] optional language identifier
 * @param {boolean} [noFraction] optional flag: render values only as integer (no fraction digits) (default is false)
 * @param {string} [unit] optional unit (default is "€/m²")
 * @return {string} formatted String
 */
export function formatPricePerArea(value, language, noFraction, unit) {
    if (typeof value !== 'number')
        return "";
    return formatValue(value, language, noFraction) + " " + (unit || "€/m²");
}

/**
 * @typedef {object} RevaGuiType
 * @property {"CATEGORY"|"DATE"|"NUMBER"|"EMPTY"} baseType
 * @property {number} [decimalPlacesMin] minimal number of decimal place (only relevant if baseType=NUMBER)
 * @property {number} [decimalPlacesMax] maximal number of decimal place (only relevant if baseType=NUMBER)
 * @property {boolean} [alwaysShowSign] flag to always render sign (only relevant if baseType=NUMBER)
 * @property {boolean} [grouped] flag to group by thousands separator (only relevant if baseType=NUMBER)
 * @property {string} [unitDE] unit (in language DE)
 * @property {string} [unitEN] unit (in language EN)
 * @property {RevaSpecOutputCategory[]} [categories] optional categories (only relevant if baseType=CATEGORY)
 */

/**
 * Parse a GUI type from the given format options of the Spec.
 * @param {string} format display format
 * @param {string|null} [unitDE] unit (in language DE)
 * @param {string|null} [unitEN] unit (in language EN)
 * @param {RevaSpecOutputCategory[]} [categories] optional categories (only relevant if format=CATEGORY)
 * @return {RevaGuiType}
 */
export function parseGuiType(format, unitDE, unitEN, categories) {
    let baseType = format;
    let opts = [];
    if (format.includes("(") && format.endsWith(")")) {
        const split = format.substring(0, format.length - 1).split("(", 2).map(item => item.trim());
        baseType = split[0];
        opts = split[1].split(/[,;]/).map(item => item.trim()).filter(item => !!item);
    }

    /**
     * @param {string} [error] optional error
     * @return {RevaGuiType}
     */
    const createEmpty = (error) => {
        console.warn(`Could not parse GUI Type "${format}${error ? ", because " + error : ""}"`);
        return {baseType: "EMPTY"};
    }

    /**
     * @param {number} decimalPlacesMin
     * @param {number} decimalPlacesMax
     * @param {boolean} alwaysShowSign
     * @param {boolean} grouped
     * @return {RevaGuiType}
     */
    const createNumberFormat = (decimalPlacesMin, decimalPlacesMax, alwaysShowSign, grouped) => {
        return {baseType: "NUMBER", decimalPlacesMin, decimalPlacesMax, alwaysShowSign, grouped, unitDE, unitEN};
    };

    const createNumberFormatWithArgs = (defaultDecimalPlaces, grouped) => {
        let decimalPlacesMin = defaultDecimalPlaces;
        let decimalPlacesMax = defaultDecimalPlaces;
        let alwaysShowSign = false;
        for (const opt of opts) {
            if (opt === "+" || opt === "+-") {
                alwaysShowSign = true;
                continue;
            }
            const matchedNr = opt.match(/^(\d+)$/);
            if (matchedNr) {
                decimalPlacesMin = parseInt(matchedNr[1], 10);
                decimalPlacesMax = decimalPlacesMin;
                continue;
            }
            const matchedNrRange = opt.match(/^(\d+)-(\d+)$/);
            if (matchedNrRange) {
                decimalPlacesMin = parseInt(matchedNrRange[1], 10);
                decimalPlacesMax = parseInt(matchedNrRange[2], 10);
            }
        }
        return createNumberFormat(decimalPlacesMin, decimalPlacesMax, alwaysShowSign, grouped);
    };

    switch (baseType.toUpperCase()) {
        case "CATEGORY":
            if (!categories || categories.length <= 1) {
                return createEmpty("no categories were provided!");
            }
            return {baseType: "CATEGORY", categories};
        case "YEAR":
            return createNumberFormat(0, 0, false, false);
        case "DATE":
            return {baseType: "DATE", unitDE, unitEN};
        case "DOUBLE":
            return createNumberFormatWithArgs(2, true);
        case "INTEGER":
            return createNumberFormatWithArgs(0, true);
        default:
            return createEmpty();
    }
}

/**
 * Format a value by a GUI type.
 * @param {RevaLanguage} lang language
 * @param {any} value value to render
 * @param {RevaGuiType} guiType gui type describing format
 * @return {string} rendered value
 */
export function formatTypedValue(lang, value, guiType) {
    if (typeof value === "undefined" || value === null || !guiType || guiType.baseType === "EMPTY") {
        return "";
    }
    // TODO: check value types
    switch (guiType.baseType) {
        case "CATEGORY":
            const foundCategory = guiType.categories.find(cat => cat.category === value);
            if (!foundCategory) {
                console.warn(`Could not resolve category for value ${value}`, guiType.categories);
                return selectByLanguage(lang, "Kategorie ", "Category ") + value;
            }
            return selectByLanguage(lang, foundCategory.titleDE, foundCategory.titleEN);
        case "DATE":
            if (lang === "en")
                return value; // is already in ISO date style
            return new Intl.DateTimeFormat("de", {dateStyle: "medium"}).format(new Date(value))
        case "NUMBER":
            const fmt = new Intl.NumberFormat(lang, {
                minimumFractionDigits: guiType.decimalPlacesMin,
                maximumFractionDigits: guiType.decimalPlacesMax,
                useGrouping: guiType.grouped,
                signDisplay: guiType.alwaysShowSign ? "always" : "auto"
            });
            let formatted = fmt.format(value);
            const unit = selectByLanguage(lang, guiType.unitDE, guiType.unitEN);
            if (unit) {
                formatted += " " + unit;
            }
            return formatted;
        default:
            console.warn(`Invalid GUI base type ${guiType.baseType} for value ${value}`);
            return "";
    }
}
