import {selectCredentials} from "./authSlice";
import TileState from 'ol/TileState';

/** @typedef {import("ol/ImageTile").ImageTile} ImageTile */
/** @typedef {import("ol/Tile").Tile} Tile */

/**
 * Async thunk to load a geoserver tile.
 * @param {Tile|ImageTile} tile the tile that is loaded
 * @param {string} url loaded URL for geoserver
 * @return {RevaThunkAction<void>}
 */
export const loadGeoserverTileAsync = (tile, url) => async (dispatch, getState, extraArgument) => {
    const {serviceApi} = extraArgument;
    const credentials = selectCredentials(getState());
    if (!credentials) {
        console.warn("Can't load Geoserver tile without valid credentials!");
        return;
    }
    try {
        // console.debug(`Geoserver Tile query: ${url}`);
        tile.getImage().src = await serviceApi.loadGeoServerTile(credentials, url);
        tile.setState(TileState.LOADED);
    } catch (err) {
        console.warn(`Error loading geoserver tile: ${err.message}`);
        tile.setState(TileState.ERROR);
    }
}