import Header from "./Header";
import {Sidebar} from "./Sidebar";
import {Outlet, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {removeDistinctAddress, searchAddressAsync, selectInitialized, selectLoading} from "../redux/addressSlice";
import {selectLoggedIn} from "../redux/authSlice";
import {useEffect, useRef} from "react";
import {getAddressQueryFromLocation} from "../router";
import {selectDevelopMode} from "../redux/envSlice";

function App() {

    const dispatch = useDispatch();

    const isDevelop = useSelector(selectDevelopMode);
    const loggedIn = useSelector(selectLoggedIn);
    const addressInitialized = useSelector(selectInitialized);
    const addressLoading = useSelector(selectLoading);
    const location = useLocation();
    const addressInitializing = useRef(false);

    // Load initial address data after login if the URL contains the address query (aka. Permalinks)
    useEffect(() => {
        if (!addressInitialized && !addressLoading && loggedIn && !addressInitializing.current) {
            addressInitializing.current = true;
            const addressQuery = getAddressQueryFromLocation(location);
            if (!addressQuery || !addressQuery.addressQuery) {
                if (isDevelop) {
                    console.log("Empty address query parameter - initialize with empty address")
                }
                dispatch(removeDistinctAddress());
            } else {
                if (isDevelop) {
                    console.log(`Found address query parameter: address=${addressQuery.addressQuery} (lat=${addressQuery.lat ? addressQuery.lat : 'null'}, lon=${addressQuery.lon ? addressQuery.lon : 'null'})`);
                }
                dispatch(searchAddressAsync(addressQuery.addressQuery, addressQuery.lat, addressQuery.lon));
            }
        }

    }, [loggedIn, addressInitialized, addressLoading, location, dispatch, isDevelop]);

    return (
        <>
            <Header/>
            <div className="MainView flex-grow-1 flex-shrink-0">
                <div className="flex flex-row w-full h-full">
                    <Sidebar/>
                    <Outlet/>
                </div>
            </div>
        </>
    );
}

export default App;
