import {ErrorI18n} from "../i18n";

export class FailedValidation {
    /**
     * Create container for failed validation
     * @param field {string} field identified
     * @param [message] {string} optional message key for customized error message
     * @param [messageArgs] {object} optional arguments for message key
     */
    constructor(field, message, messageArgs) {
        this.field = field;
        this.message = message || null;
        /** @type {object|null} */
        this.messageArgs = (typeof messageArgs === 'object') ? {...messageArgs} : null;
    }
}

/**
 * @return {FailedValidation[]}
 * @private
 */
function _mapFailedValidations(failedValidations) {
    if (Array.isArray(failedValidations)) {
        return failedValidations.map(failedValidation => {
            if (failedValidation instanceof FailedValidation) {
                return failedValidation;
            }
            if (typeof failedValidation === 'string') {
                return new FailedValidation(failedValidation);
            }
            return null;
        }).filter(fv => !!fv);
    }
    if (failedValidations instanceof FailedValidation) {
        return [failedValidations];
    }
    if (typeof failedValidations === 'string') {
        return [new FailedValidation(failedValidations)];
    }
    return [];
}

export class ValidationError extends Error {
    /**
     * Create Validation error, without message.
     * @param failedValidations {FailedValidation[]|FailedValidation|string} failed validations
     */
    constructor(failedValidations) {
        super();
        this.failedValidations = _mapFailedValidations(failedValidations);
    }

    /**
     * Return all fields with validation errors.
     * @return {string[]} list of fields with validation errors
     */
    failedFields() {
        return this.failedValidations.map(f => f.field)
    }
}

export class ValidationErrorI18n extends ErrorI18n {

    /**
     * Create Validation error with message.
     * @param failedValidations {FailedValidation[]|FailedValidation|string} failed validations
     * @param errorKey {string} error key (i18n)
     * @param [errorArgs] {object} optional error args (i18n)
     */
    constructor(failedValidations, errorKey, errorArgs) {
        super(errorKey, errorArgs);
        this.failedValidations = _mapFailedValidations(failedValidations);
    }

    /**
     * Return all fields with validation errors.
     * @return {string[]} list of fields with validation errors
     */
    failedFields() {
        return this.failedValidations.map(f => f.field);
    }
}