import {useRouteError} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Header from "./Header";
import {Sidebar} from "./Sidebar";
import {useSelector} from "react-redux";
import {selectAdmin} from "../redux/authSlice";
import {selectDevelopMode} from "../redux/envSlice";

function PageErrorDetails() {

    const error = useRouteError();

    const isAdmin = useSelector(selectAdmin);
    const isDevelop = useSelector(selectDevelopMode);

    if (!isAdmin && !isDevelop) {
        return null;
    }

    return (
        <div style={{
            backgroundColor: "rgb(68,5,12)",
            color: "rgb(252, 207, 207)",
            padding: "1rem",
            marginTop: "2rem"
        }}>

            <div style={{color: "rgb(232, 59, 70)", fontSize: "1.2em", marginBottom: "1rem", fontFamily: "sans-serif"}}>
                {error.error.message}
            </div>

            <pre>{error.error.stack}</pre>
        </div>
    );
}

function PageError() {

    const {t, i18n} = useTranslation();

    const error = useRouteError();
    console.error(error);

    let errorToDisplay = error.statusText || error.message;
    let errorDescriptionToDisplay = null;
    if (i18n.exists(`error.${error.status}`)) {
        errorToDisplay = t(`error.${error.status}`);
        errorDescriptionToDisplay = t(`error.${error.status}Description`);
    }

    return (
        <main role="main" className="flex-grow-1 pt-3 px-4 py-4 w-12">
            <div className="flex flex-column w-full h-full">
                <h2 className="flex-0 flex mt-0">
                    <div className="flex-0 HeaderText">{t('error.error')}: {errorToDisplay}</div>
                </h2>
                <p>
                    <strong>{t('error.anErrorOccurred')}</strong>
                </p>
                <p>
                    <i>{errorDescriptionToDisplay}</i>
                </p>
                <PageErrorDetails/>
            </div>
        </main>
    );
}

export default function AppError() {
    return (
        <>
            <Header/>
            <div className="MainView flex-grow-1 flex-shrink-0">
                <div className="flex flex-row w-full h-full">
                    <Sidebar/>
                    <PageError/>
                </div>
            </div>
        </>
    );
};
