import {createSlice} from '@reduxjs/toolkit'

export const THEME_LIGHT = "light";
export const THEME_DARK = "dark";
export const THEME_AUTO = "auto";

// Per Default included in index.html
export const INITIAL_IMPORTED_THEME = THEME_LIGHT;
// ID of <Link/> Element in index.html containing the theme import
export const THEME_LINK_ID = "theme-link";

export const THEMES = {
    "light": "lara-light-indigo",
    "dark": "lara-dark-indigo"
}

const VALID_THEMES = [THEME_LIGHT, THEME_DARK, THEME_AUTO];

export const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        currentTheme: INITIAL_IMPORTED_THEME,
        appliedTheme: THEMES[INITIAL_IMPORTED_THEME],
        isDarkTheme: false
    },
    reducers: {
        updateTheme: (state, action) => {
            state.currentTheme = action.payload.theme;
            state.appliedTheme = action.payload.appliedTheme;
            state.isDarkTheme = (state.appliedTheme === THEMES[THEME_DARK]);
        },
    },
    selectors: {
        /** @return {string} */
        selectCurrentTheme: (state) => state.currentTheme,
        /** @return {string} */
        selectAppliedTheme: (state) => state.appliedTheme,
        /** @return {boolean} */
        selectDarkTheme: (state) => state.isDarkTheme
    }
})

export const {updateTheme} = themeSlice.actions;

export const {
    selectCurrentTheme,
    selectAppliedTheme,
    selectDarkTheme
} = themeSlice.selectors

const KEY_STORAGE_THEME = 'reva_theme';
const getStoredTheme = () => {
    const storedTheme = localStorage.getItem(KEY_STORAGE_THEME);
    if (storedTheme && THEMES[storedTheme]) {
        return storedTheme;
    }
    return THEME_AUTO;
}
const setStoredTheme = (theme) => {
    if (theme === THEME_AUTO) {
        // Auto - remove preference
        localStorage.removeItem(KEY_STORAGE_THEME);
    } else {
        localStorage.setItem(KEY_STORAGE_THEME, theme);
    }
}

const getPreferredTheme = () => {
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? THEME_DARK : THEME_LIGHT;
}

export const getThemeToApply = (theme) => {
    let effectiveTheme = theme;
    if (effectiveTheme === THEME_AUTO) {
        effectiveTheme = getPreferredTheme();
    }
    return THEMES[effectiveTheme];
}

const changePrimeReactTheme = (appliedTheme, themeToApply) => {
    const linkElement = document.getElementById(THEME_LINK_ID);
    const cloneLinkElement = linkElement.cloneNode(true);
    const newThemeUrl = linkElement.getAttribute('href').replace(appliedTheme, themeToApply);
    cloneLinkElement.setAttribute('id', THEME_LINK_ID + '-clone');
    cloneLinkElement.setAttribute('href', newThemeUrl);
    cloneLinkElement.addEventListener('load', () => {
        linkElement.remove();
        cloneLinkElement.setAttribute('id', THEME_LINK_ID);
    });
    const linkElementParentNode = linkElement.parentNode;
    if (linkElementParentNode) {
        linkElementParentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);
    }
}

/**
 * Thunk to change the theme, depending on current theme.
 * @param newTheme {string} new Theme ID (THEME_LIGHT, THEME_DARK or THEME_AUTO)
 * @return {Function} Redux Thunk
 */
export const setThemeAsync = (newTheme) => async (dispatch, getState, extraArgument) => {
    if (!VALID_THEMES.includes(newTheme)) {
        console.error(`Called setThemeAsync() with invalid theme ${newTheme}`);
        return;
    }
    const { env } = extraArgument;
    const state = getState();
    const currentTheme = selectCurrentTheme(state);
    const appliedTheme = selectAppliedTheme(state);

    if (currentTheme === newTheme) {
        if (env.isDevelopmentMode) {
            console.debug(`New theme ${newTheme} is identical to current theme.`);
        }
        return;
    }
    if (env.isDevelopmentMode) {
        console.log(`Setting theme to ${newTheme}`);
    }
    setStoredTheme(newTheme);
    const themeToApply = getThemeToApply(newTheme);
    if (themeToApply !== appliedTheme) {
        changePrimeReactTheme(appliedTheme, themeToApply);
    }
    await dispatch(updateTheme({theme: newTheme, appliedTheme: themeToApply}));
}

export const initializeThemeAsync = () => async (dispatch) => {
    await dispatch(setThemeAsync(getStoredTheme()));
}

export default themeSlice.reducer
