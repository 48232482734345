import {createBrowserRouter, redirect} from "react-router-dom";
import App from "./app/App";
import AppError from "./app/AppError";
import PageAddress from "./app/PageAddress";
import PageLocation from "./app/PageLocation";
import PageMarket from "./app/PageMarket";
import PageValue from "./app/PageValue";
import TabMicroLocation from "./app/location/TabMicroLocation";
import TabMacroLocation from "./app/location/TabMacroLocation";
import TabPoisLocation from "./app/location/TabPoisLocation";
import TabTimeLines from "./app/market/TabTimelines";
import TabComparativeValue from "./app/value/TabComparativeValue";
import TabIncomeValue from "./app/value/TabIncomeValue";
import TabRealAssetValue from "./app/value/TabRealAssetValue";
import {
    checkPermissionRule,
    PERMISSION_RULE_LOCATION_MACRO,
    PERMISSION_RULE_LOCATION_MICRO,
    PERMISSION_RULE_LOCATION_POIS,
    PERMISSION_RULE_MARKET_TIMELINES
} from "./model/permissions";
import PageObject from "./app/PageObject";

/**
 * @typedef SubRoute
 * @property {string} url URL to redirect to
 * @property {PermissionRule} [permission] optional permission to check
 * @property {Selector} [available] optional redux selector, to check for availability
 */

export const routerFactory = (privileges) => {

    /**
     * select first available of existing sub-routes (or first, if all unavailable)
     * @param {Request} request
     * @param {SubRoute[]} subRoutes
     */
    function redirectToFirstAvailableSubRoute(request, subRoutes) {
        for (const subRoute of subRoutes) {
            if (!subRoute.permission || checkPermissionRule(privileges, subRoute.permission)) {
                return redirect(subRoute.url + relayAddressQuery(request));
            }
        }
        return redirect(subRoutes[0].url + relayAddressQuery(request));
    }

    return createBrowserRouter([
        {
            path: "/",
            element: <App/>,
            errorElement: <AppError/>,
            children: [
                {
                    index: true,
                    loader: ({request}) => {
                        return redirect("/address" + relayAddressQuery(request));
                    }
                },
                {
                    path: "address",
                    element: <PageAddress/>
                },
                {
                    path: "location",
                    element: <PageLocation/>,
                    children: [
                        {
                            index: true,
                            loader: ({request}) => {
                                return redirectToFirstAvailableSubRoute(request, [
                                    {url: "/location/points-of-interest", permission: PERMISSION_RULE_LOCATION_POIS},
                                    {url: "/location/micro", permission: PERMISSION_RULE_LOCATION_MICRO},
                                    {url: "/location/macro", permission: PERMISSION_RULE_LOCATION_MACRO}
                                ]);
                            }
                        },
                        {
                            path: "points-of-interest",
                            element: <TabPoisLocation/>
                        },
                        {
                            path: "micro",
                            element: <TabMicroLocation/>
                        },
                        {
                            path: "macro",
                            element: <TabMacroLocation/>
                        }
                    ]
                },
                {
                    path: "market",
                    element: <PageMarket/>,
                    children: [
                        {
                            index: true,
                            loader: ({request}) => {
                                return redirectToFirstAvailableSubRoute(request, [
                                    {url: "/market/timelines", permission: PERMISSION_RULE_MARKET_TIMELINES}
                                ]);
                            }
                        },
                        {
                            path: "timelines",
                            element: <TabTimeLines/>
                        }
                    ]
                },
                {
                    path: "object",
                    element: <PageObject/>
                },
                {
                    path: "value",
                    element: <PageValue/>,
                    children: [
                        {
                            index: true,
                            loader: ({request}) => {
                                return redirectToFirstAvailableSubRoute(request, [
                                    {url: "/value/income-value"},
                                    {url: "/value/real-asset-value"},
                                    {url: "/value/comparative-value"}
                                ]);
                            }
                        },
                        {
                            path: "comparative-value",
                            element: <TabComparativeValue/>
                        },
                        {
                            path: "income-value",
                            element: <TabIncomeValue/>
                        },
                        {
                            path: "real-asset-value",
                            element: <TabRealAssetValue/>
                        }
                    ]
                }
            ]
        }
    ]);
}

/**
 * Add address query parameter to route, containing the current selected address.
 * @param {SelectAddressData|null} selectAddressData selected address (if available)
 * @return {string} query parameter for route
 */
export function addAddressQuery(selectAddressData) {
    if (!selectAddressData)
        return "";

    let query = "?address=" + encodeURIComponent(selectAddressData.addressQuery);
    if (selectAddressData.queryAmbiguous) {
        query += "&lat=" + selectAddressData.lat;
        query += "&lon=" + selectAddressData.lon;
    }
    return query;
}

/**
 * Add address query parameter to route, containing the current selected address from location.
 * @param {Location|null} location selected address (if available)
 * @return {string} query parameter for route
 */
export function addAddressQueryFromLocation(location) {
    if (!location)
        return "";

    const addressQuery = getAddressQueryFromLocation(location);
    if (!addressQuery || !addressQuery.addressQuery)
        return "";

    let query = "?address=" + encodeURIComponent(addressQuery.addressQuery);
    if (addressQuery.lat && addressQuery.lon) {
        query += "&lat=" + addressQuery.lat;
        query += "&lon=" + addressQuery.lon;
    }
    return query;
}

/** @typedef {import("@remix-run/router/history").Location} Location */

/**
 * @typedef {object} AddressUrlData
 * @property {string} addressQuery
 * @property {number|null} [lat] latitude (only for unambiguous address results)
 * @property {number|null} [lon] longitude (only for unambiguous address results)
 */

/**
 * Get address query from routing request.
 * @param {Request|null} request
 * @return {null|AddressUrlData}
 */
export function getAddressQueryFromRequest(request) {
    if (!request)
        return null;
    const url = new URL(request.url);
    return getAddressQueryFromUrl(url);
}

/**
 * Get address query from routing location.
 * @param {Location|null} location
 * @return {null|AddressUrlData}
 */
export function getAddressQueryFromLocation(location) {
    if (!location)
        return null;
    const url = new URL(location.pathname, window.location.origin);
    url.search = location.search;
    return getAddressQueryFromUrl(url);
}

/**
 * Get address query from URL.
 * @param {URL} url
 * @return {null|AddressUrlData}
 */
function getAddressQueryFromUrl(url) {
    const address = url.searchParams.get("address");
    if (!address)
        return null;
    const latStr = url.searchParams.get("lat");
    const lonStr = url.searchParams.get("lon");
    if (latStr && lonStr) {
        return {
            addressQuery: address,
            lat: parseFloat(latStr),
            lon: parseFloat(lonStr)
        }
    }
    return {
        addressQuery: address
    };
}

/**
 * Add address query parameter to route, relayed from route during redirect.
 * @param {Request|null} request request from routing
 * @return {string}
 */
function relayAddressQuery(request) {
    const addressData = getAddressQueryFromRequest(request);
    if (!addressData)
        return "";
    let query = "?address=" + encodeURIComponent(addressData.addressQuery);
    if (addressData.lat && addressData.lon) {
        query += "&lat=" + addressData.lat;
        query += "&lon=" + addressData.lon;
    }
    return query;
}