import {TabPanel} from "primereact/tabview";
import {checkPermissionRule} from "../model/permissions";
import MissingPermissionTooltip from "./MissingPermissionTooltip";

/**
 * @typedef {object} TabHeaderWithPermissionParams
 * @property {string} headerI18n the tab header i18nKey to display
 * @property {string} [key]
 * @property {string} [route] if key is not defined, use route as implicit key
 * @property {boolean} [disabled] flag, if tab should be disabled
 * @property {PermissionRule|null} [permissionRule] permission to check
 */

const headerTemplate = (header, key) => (
    <>
        <a role="tab" className={"p-tabview-nav-link tabHeader-" + key} tabIndex="-1"
           aria-selected="false" aria-disabled="true" data-pc-section="headeraction"
           style={{pointerEvents: "all"}}>
            <span className="pi pi-lock mr-2"></span>
            <span className="p-tabview-title" data-pc-section="headertitle">{header}</span>
        </a>
        <MissingPermissionTooltip target={".tabHeader-" + key} position="top"/>
    </>
);

/**
 * Get `<TabPanel/>` with permission check
 * @param {TabHeaderWithPermissionParams} tab tab properties
 * @param {(string) => string} t
 * @param {string[]} privileges loaded privileges of user
 * @return {React.ReactElement}
 */
export function getTabPanelWithPermission(tab, t, privileges) {
    let permitted = true;
    if (tab.permissionRule) {
        permitted = checkPermissionRule(privileges, tab.permissionRule);
    }
    const header = t(tab.headerI18n);
    const key = tab.key || tab.route;
    if (!permitted) {
        return (<TabPanel key={key} header={header} headerTemplate={headerTemplate(header, key)} disabled/>)
    }
    return (<TabPanel key={key} header={header} disabled={!!tab.disabled}/>)
}
