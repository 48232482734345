// Utilities for event handlers

/**
 * Prevent default action for wrapped JSX event handler, and return false on event.
 * @param {() => void} fn inner function to call on event
 * @return {(UIEvent) => boolean} wrapped event handler
 */
export function handlePreventDefault(fn) {
    return (e) => {
        if (e && typeof e.preventDefault === "function") {
            e.preventDefault();
        }
        if (typeof fn === "function") {
            fn();
        }
        return false;
    };
}