/**
 * @typedef ApiParam
 * @property {string} param
 * @property {*|null} value
 * @property {boolean} [encode] false, if no URL-encoding should be performed (Default: true)
 * @property {boolean} optional false, if empty value should still be encoded
 */

/**
 * Builder for API Query Parameters.
 */
export default class ApiParamBuilder {

    constructor() {
        /** @type {ApiParam[]} */
        this.params = [];
    }

    /**
     * Add required parameter (which will still be encoded, even if the value is empty).
     * @param {string} param
     * @param {*|null} value
     * @param {boolean} [encode] false, if no URL-encoding should be performed (Default: true)
     * @return {ApiParamBuilder} builder object (fluent API)
     */
    addParam(param, value, encode) {
        this._addParam(param, value, encode, false);
        return this;
    }

    /**
     * Add optional parameter.
     * @param {string} param
     * @param {*|null} value
     * @param {boolean} [encode] false, if no URL-encoding should be performed (Default: true)
     * @return {ApiParamBuilder} builder object (fluent API)
     */
    addOptionalParam(param, value, encode) {
        this._addParam(param, value, encode, true);
        return this;
    }

    /**
     * Build Query Params string to append to URL (including "?" if necessary).
     * @return {string}
     */
    build() {
        const encoded = this.params
            .filter(param => !param.optional || (param.value !== null && param.value !== ''))
            .map(param => {
                let value = "";
                if (param.value !== null && param.value !== '') {
                    value = param.value.toString();
                    if (param.encode) {
                        value = encodeURIComponent(value);
                    }
                }
                return param.param + "=" + value;
            });
        if (encoded.length === 0)
            return "";
        return "?" + encoded.join("&")
    }

    /**
     * Add required parameter (which will still be encoded, even if the value is empty).
     * @param {string} param
     * @param {*|null} value
     * @param {boolean} encode false, if no URL-encoding should be performed (Default: true)
     * @param {boolean} optional false, if empty value should still be encoded
     */
    _addParam(param, value, encode, optional) {
        this.params.push({
            param: param,
            value: (typeof value === 'undefined') ? null : value,
            encode: (typeof encode === 'boolean') ? encode : true,
            optional: optional
        });
    }

}