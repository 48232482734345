import {createSlice} from "@reduxjs/toolkit";
import {ErrorI18n} from "../i18n";
import {selectCredentials} from "./authSlice";
import {WmtsCapabilitiesModel} from "../model/WmtsCapabilitiesModel";


/**
 * @typedef {object} SelectCapabilitiesData
 */

export const wmtsSlice = createSlice({
    name: 'wmts',
    initialState: {
        /** @type {boolean} */
        loading: false,
        /** @type {SelectCapabilitiesData|null} */
        capabilities: null,
        /** @type {ApiQueryError|null} */
        queryError: null,
    },
    reducers: {
        setLoading: (state) => {
            state.loading = true;
            state.capabilities = null;
            state.queryError = null;
        },
        setQueryError: (state, action) => {
            const {error, errorArgs, hasContactLink} = action.payload;
            state.loading = false;
            state.capabilities = null;
            /** @type {ApiQueryError} */
            state.queryError = {error, errorArgs: errorArgs || null, hasContactLink: hasContactLink || false};
        },
        setCapabilities: (state, action) => {
            state.loading = false;
            state.capabilities = action.payload;
            state.queryError = null;
        }
    },
    selectors: {
        /** @return {SelectCapabilitiesData|null} */
        selectCapabilities: (state) => state.capabilities,
        /** @return {boolean} */
        selectLoading: (state) => state.loading,
        /** @return {ApiQueryError|null} query error data, or null if no error */
        selectError: (state) => state.queryError
    }
})

export const {
    setLoading,
    setQueryError,
    setCapabilities
} = wmtsSlice.actions;

export const {
    selectLoading,
    selectError,
    selectCapabilities
} = wmtsSlice.selectors;

export const LAYER_WOHNLAGEN_MIKRO_DECORATED = 'reva:wohnlagen_mikro_decorated';
export const LAYER_WOHNLAGEN_MAKRO_DECORATED = 'reva:wohnlagen_makro_decorated';
export const LAYER_POIS_DECORATED = 'reva:pois_decorated';
export const LAYER_ALKIS = 'reva:alkis';
export const LAYER_BASEMAP = 'reva:de_basemapde_web_raster_farbe';
export const DEFAULT_MATRIXSET = 'WebMercatorQuadx2';
/**
 * Async thunk to run PoisInfo request.
 * @return {RevaThunkAction<void>}
 */
export const queryCapabilitiesAsync = () => async (dispatch, getState, extraArgument) => {
    const {serviceApi} = extraArgument;
    dispatch(setLoading());
    try {
        const credentials = selectCredentials(getState());
        const wmtsLayers = [
            {layer: LAYER_WOHNLAGEN_MIKRO_DECORATED, matrixset: DEFAULT_MATRIXSET},
            {layer: LAYER_WOHNLAGEN_MAKRO_DECORATED, matrixset: 'WebMercatorQuad'},
            {layer: LAYER_POIS_DECORATED, matrixset: DEFAULT_MATRIXSET},
            {layer: LAYER_ALKIS, matrixset: DEFAULT_MATRIXSET},
            {layer: LAYER_BASEMAP, matrixset: DEFAULT_MATRIXSET}
        ]
        const capabilities = {}
        for (const wmtsLayer of wmtsLayers) {
            const capabilitiesResponse = serviceApi.loadGeoServerWmtsCapabilities(credentials, new WmtsCapabilitiesModel(wmtsLayer.layer, wmtsLayer.matrixset));
            const response = await capabilitiesResponse;
            if(!response)
                continue
            capabilities[wmtsLayer.layer] = {capabilities: response, matrixset: wmtsLayer.matrixset};
        }
        dispatch(setCapabilities(capabilities))
    } catch (err) {
        console.error("Error occurred when sending capabilities", err)
        if (err instanceof ErrorI18n) {
            dispatch(setQueryError({error: err.message, errorArgs: {...err.messageArgs}, errorHasContactLink: true}));
        } else {
            dispatch(setQueryError({
                error: 'general.error.custom',
                errorArgs: {errorMessage: err.message},
                errorHasContactLink: true
            }));
        }
    }
}

export default wmtsSlice.reducer