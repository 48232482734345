import {createSlice} from '@reduxjs/toolkit'

/**
 * @typedef {object} AuthData
 * @property {string} username
 * @property {string} license
 * @property {number} licenseId
 */

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        /** @type {boolean} */
        loggedIn: false,
        /** @type {AuthData|null} */
        authData: null,
        /** @type {string|null} */
        token: null,
        /** @type {string[]} */
        privileges: []
    },
    reducers: {
        logout: (state) => {
            state.loggedIn = false;
            state.authData = null;
            state.token = null;
            state.privileges = []
        },
        login: (state, action) => {
            const {authData, token, privileges} = action.payload;
            state.loggedIn = true;
            state.authData = authData;
            state.token = token;
            state.privileges = privileges;
        }
    },
    selectors: {
        /** @return {boolean} */
        selectLoggedIn:  (state) => state.loggedIn,
        /** @return {string} */
        selectUsername: (state) => state.authData ? state.authData.username : "",
        /** @return {string[]} */
        selectPrivileges: (state) => state.privileges,
        /** @return {boolean} */
        selectAdmin: (state) => state.privileges.includes('admin'),
        /** @return {boolean} */
        selectPrivilegeTimelines: (state) => state.privileges.includes('locationTimelines'),
        /** @return {boolean} */
        selectPrivilegePois: (state) => state.privileges.includes('locationPois'),
        /** @return {boolean} */
        selectPrivilegeValueIndication: (state) => state.privileges.includes('indicationValue'),
        /**
         * Select API credentials.
         * @param state redux state
         * @return {ApiTokenCredentials|null} API credentials
         */
        selectCredentials: (state) => {
            if (!state.token)
                return null;
            return {token: state.token}
        }
    }
});

export const {
    logout,
    login
} = authSlice.actions

export const {
    selectLoggedIn,
    selectUsername,
    selectPrivileges,
    selectAdmin,
    selectPrivilegeTimelines,
    selectPrivilegePois,
    selectPrivilegeValueIndication,
    selectCredentials
} = authSlice.selectors

export default authSlice.reducer
