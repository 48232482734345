import {createSelector, createSlice} from "@reduxjs/toolkit";

const valueIndicationSlice = createSlice({
    name: 'valueIndication',
    initialState: {
        /** @type {boolean} */
        loading: false,
        /** @type {boolean} */
        missingPermissions: false,
        /** @type {object} */
        requestData: null,
        /** @type {ValueIndicationData|null} */
        indicationData: null,
        /** @type {Record<RevaIndicationEndpoint, object>|null} */
        combinedIndicationData: null,
        /** @type {ApiQueryError|null} */
        queryError: null
    },
    reducers: {
        clearIndicationData: (state) => {
            state.loading = false;
            state.missingPermissions = false;
            state.indicationData = null;
            state.combinedIndicationData = null;
            state.requestData = null;
            state.queryError = null;
        },
        setLoading: (state) => {
            state.loading = true;
            state.missingPermissions = false;
            state.indicationData = null;
            state.combinedIndicationData = null;
            state.requestData = null;
            state.queryError = null;
        },
        setMissingPermissions: (state) => {
            state.loading = false;
            state.missingPermissions = true;
            state.indicationData = null;
            state.combinedIndicationData = null;
            state.requestData = null;
            state.queryError = null;
        },
        setQueryError: (state, action) => {
            const {error, errorArgs, hasContactLink} = action.payload;
            state.loading = false;
            state.missingPermissions = false;
            state.indicationData = null;
            state.combinedIndicationData = null;
            state.requestData = null;
            /** @type {ApiQueryError} */
            state.queryError = {error, errorArgs: errorArgs || null, hasContactLink: hasContactLink || false};
        },
        setIndicationData: (state, action) => {
            state.loading = false;
            state.missingPermissions = false;
            /** @type {{requestData: object, indicationData: ValueIndicationData}} */
            const payload = action.payload;
            state.indicationData = payload.indicationData;
            state.requestData = payload.requestData;
            /** @type {Record<RevaIndicationEndpoint, object>} */
            state.combinedIndicationData = {
                "INCOME_VALUE": combineIndicationData(state.indicationData, state.requestData, state.indicationData.income_value_details),
                "REAL_ASSET_VALUE": combineIndicationData(state.indicationData, state.requestData, state.indicationData.real_asset_value_details),
                "COMPARATIVE_VALUE": combineIndicationData(state.indicationData, state.requestData, state.indicationData.comparative_value_details)
            }
            state.queryError = null;
        }
    },
    selectors: {
        /** @return {boolean} */
        selectLoading: (state) => state.loading,
        /** @return {boolean} */
        selectMissingPermissions: (state) => state.missingPermissions,
        /** @return {ValueIndicationData|null} indication data, or null if no indication loaded */
        selectIndicationData: (state) => state.indicationData,
        /** @return {object|null} income value indication data, or null if no indication loaded or no income value data available */
        selectIncomeValueData: (state) => state.combinedIndicationData?.INCOME_VALUE,
        /** @return {object|null} real asset value indication data, or null if no indication loaded or no real asset value data available */
        selectRealAssetValueData: (state) => state.combinedIndicationData?.REAL_ASSET_VALUE,
        /** @return {object|null} comparative value indication data, or null if no indication loaded or no comparative value data available */
        selectComparativeValueData: (state) => state.combinedIndicationData?.COMPARATIVE_VALUE,
        /** @return {boolean} check if income value data is available */
        selectIncomeValueAvailable: (state) => !!state.combinedIndicationData?.INCOME_VALUE,
        /** @return {boolean} check if real asset value data is available */
        selectRealAssetValueAvailable: (state) => !!state.combinedIndicationData?.REAL_ASSET_VALUE,
        /** @return {boolean} check if comparative value data is available */
        selectComparativeValueAvailable: (state) => !!state.combinedIndicationData?.COMPARATIVE_VALUE,
        /** @return {ApiQueryError|null} query error data, or null if no error */
        selectError: (state) => state.queryError
    }
});

export const {
    clearIndicationData,
    setLoading,
    setMissingPermissions,
    setQueryError,
    setIndicationData
} = valueIndicationSlice.actions

export const {
    selectLoading,
    selectMissingPermissions,
    selectIndicationData,
    selectIncomeValueData,
    selectRealAssetValueData,
    selectComparativeValueData,
    selectIncomeValueAvailable,
    selectRealAssetValueAvailable,
    selectComparativeValueAvailable,
    selectError
} = valueIndicationSlice.selectors;

export const selectAnyValueAvailable = createSelector(
    [selectIncomeValueAvailable, selectRealAssetValueAvailable, selectComparativeValueAvailable],
    (iv, rav, cv) => iv || rav || cv
);

/**
 * Combine the indication details with the general indication response (of Value Indication), and the request data.
 * @param {ValueIndicationData|null} indicationData
 * @param {object|null} requestData
 * @param {object|null} indicationDetails
 */
function combineIndicationData(indicationData, requestData, indicationDetails) {
    if (typeof indicationData === 'object' && typeof requestData === 'object' && typeof indicationDetails === "object") {
        let indicationDataPure = {...indicationData};
        delete indicationDataPure.comparative_price_details;
        delete indicationDataPure.comparative_value_details;
        delete indicationDataPure.income_value_details;
        delete indicationDataPure.real_asset_value_details;
        return {...indicationDataPure, ...requestData, ...indicationDetails};
    }
    return null;
}

export default valueIndicationSlice.reducer;