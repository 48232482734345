
/** @typedef {string | string[]} PermissionConjunction Term of DNF, consisting of a conjunction of permissions */
/** @typedef {string | PermissionConjunction[]} PermissionRule Rule in DNF (disjunctive normal form), consisting of conjunctive terms */

/** @type {PermissionRule} */
export const PERMISSION_RULE_LOCATION = ["locationMicroMacro", "locationPois"];

/** @type {PermissionRule} */
export const PERMISSION_RULE_LOCATION_MICRO = "locationMicroMacro";
/** @type {PermissionRule} */
export const PERMISSION_RULE_LOCATION_MACRO = "locationMicroMacro";
/** @type {PermissionRule} */
export const PERMISSION_RULE_LOCATION_POIS = "locationPois";

/** @type {PermissionRule} */
export const PERMISSION_RULE_MARKET = "locationTimelines";

/** @type {PermissionRule} */
export const PERMISSION_RULE_MARKET_TIMELINES = "locationTimelines";

/** @type {PermissionRule} */
export const PERMISSION_RULE_VALUE = "indicationValue";

/**
 * Check if a permission rule is granted by the permissions, the user has.
 * @param {string[]} grantedPermissions set of permissions from the user
 * @param {PermissionRule} rule permission rule to check
 * @return {boolean} whether the permission check was successful
 */
export function checkPermissionRule(grantedPermissions, rule) {
    if (!rule || (Array.isArray(rule) && rule.length === 0))
        return true; // no rule means automatically granted
    if (!Array.isArray(grantedPermissions)) {
        console.warn("Function checkPermissionRule() requires a string array as first argument, but got: ", grantedPermissions);
        return false;
    }
    if (typeof rule === 'string') {
        // Simple rule: only 1 singular permission to check
        return grantedPermissions.includes(rule);
    }
    if (!Array.isArray(rule)) {
        console.warn("Function checkPermissionRule() requires either (1) a string, or (2) a string array, or (3) an array of string arrays as second argument, but got: ", rule);
        return false;
    }
    // Complex rule: array of conditions, of which at least one must be true
    for (let conjunction of rule) {
        // Check each conjunction of the rule for itself - once one is fulfilled, the whole disjunction is fulfilled as well
        if (typeof conjunction === 'string') {
            if (grantedPermissions.includes(conjunction)) {
                return true;
            } else {
                continue;
            }
        }
        if (!Array.isArray(conjunction)) {
            console.warn("Function checkPermissionRule() requires either (1) a string, or (2) a string array, or (3) an array of string arrays as second argument, but got: ", rule);
            return false;
        }
        if (conjunction.length === 0)
            return true; // no rule means automatically granted

        if (conjunction.every((permission) => grantedPermissions.includes(permission)))
            return true;
    }
    return false;
}