import {GeneralModel} from "./GeneralModel";
import {ValidationErrorI18n} from "./Validation";
import {WebError} from "../api";
import {ErrorI18n} from "../i18n";
import ApiParamBuilder from "../util/queryParams";

/** @typedef {import("ol/format").GeoJSON} GeoJSON */

/**
 * @typedef {object} LocationInfoResult
 * @property {LocationInfoTimelineData} timelines
 * @property {GeoJSON} pois
 * @property {LocationInfoAddress} address
 */

/**
 * @typedef {object} LocationInfoAddress
 * @property {number} lat
 * @property {number} lon
 * @property {string} precision
 * @property {string} postcode
 * @property {string} municipality
 * @property {string} street
 * @property {number} district_code
 * @property {string} district_name
 * @property {number} region_code
 * @property {string} region_name
 * @property {number} state_code (Computed by region_code)
 * @property {string} state_name
 */

/**
 * @typedef {object} LocationInfoTimelineData
 * @property {string} source
 * @property {LocationInfoTimelineSegmentData[]} segments
 */

/**
 * @typedef {object} LocationInfoTimelineSegmentData
 * @property {string} segment
 * @property {LocationInfoTimeline[]} timeline
 */

/**
 * @typedef {object} LocationInfoTimeline
 * @property {number} regional_level
 * @property {number} period
 * @property {LocationInfoTimelineValue[]} values
 */

/**
 * @typedef {object} LocationInfoTimelineValue
 * @property {string} start_of_period
 * @property {number} index
 * @property {number} value_low
 * @property {number} value
 * @property {number} value_high
 */

export const FIELD_TIMELINES = "Timelines"
export const FIELD_POIS = "Pois"

const STATE_NAMES = {
    1: "Schleswig-Holstein",
    2: "Hamburg",
    3: "Niedersachsen",
    4: "Bremen",
    5: "Nordrhein-Westfalen",
    6: "Hessen",
    7: "Rheinland-Pfalz",
    8: "Baden-Württemberg",
    9: "Bayern",
    10: "Saarland",
    11: "Berlin",
    12: "Brandenburg",
    13: "Mecklenburg-Vorpommern",
    14: "Sachsen",
    15: "Sachsen-Anhalt",
    16: "Thüringen"
}

export class LocationInformationModel extends GeneralModel {
    /**
     * Create location information model.
     * @param {string|null} address requested address
     * @param {string|null} [objectId] optional Object ID
     * @param {string|null} [accounting] optional Accounting flag
     * @param {string[]} [requestedFields] optional array of requested fields
     */
    constructor(address, latlon, objectId, accounting, requestedFields) {
        super()
        this.address = address;
        this.latlon = latlon;
        this.objectId = objectId;
        this.accounting = accounting;
        this.requestedFields = Array.isArray(requestedFields) ? [...requestedFields] : null;
    }

    validate() {
        if (!this.address || this.address.length < 4) {
            throw new ValidationErrorI18n("address", "address.error.too_short")
        }
    }

    /**
     * Handle and validate response data.
     * @param apiResponsePromise {Promise<object>} response promise from API call
     * @return {Promise<LocationInfoResult>} Promise containing result from the LocationInfo query
     */
    async handleResponse(apiResponsePromise) {
        try {
            const responseJson = await apiResponsePromise;
            LocationInformationModel._addStateInformationToAddress(responseJson);
            const {timelines, pois, address} = responseJson;
            return {timelines, pois, address};
        } catch (e) {
            if (e instanceof WebError) {
                switch (e.status) {
                    case 401:
                        throw new ErrorI18n('general.error.401');
                    case 403:
                        throw new ErrorI18n('general.error.403');
                    case 409:
                        throw new ErrorI18n('address.error.409');
                    case 422:
                        throw new ErrorI18n('address.error.422');
                    case 503:
                        throw new ErrorI18n('general.error.503');
                    case 500:
                        throw new ErrorI18n('general.error.unspecific');
                    default:
                }
                if (e.getCustomErrorMessage()) {
                    throw new ErrorI18n('general.error.custom', {errorMessage: e.getCustomErrorMessage()});
                }
            }
            throw new ErrorI18n('general.error.invalid');
        }
    }

    static _addStateInformationToAddress(responseJson) {
        if (!responseJson || !responseJson.address || !responseJson.address.district_code)
            return;
        responseJson.address.state_code = Math.floor(responseJson.address.district_code / 1000);
        responseJson.address.state_name = STATE_NAMES[responseJson.address.state_code];
    }

    /**
     * Get additional Query Parameters.
     * @return {string} request query parameters
     */
    getQueryParameters() {
        return new ApiParamBuilder()
            .addParam("address", this.latlon ? this.latlon[0] + '/' + this.latlon[1] : this.address)
            .addOptionalParam("accounting", this.accounting)
            .addOptionalParam("objectId", this.objectId)
            .addOptionalParam("requestedFields", (this.requestedFields ? this.requestedFields.join(",") : null))
            .build()
    }

}