import {GeneralModel} from "./GeneralModel";
import {ValidationErrorI18n} from "./Validation";
import {ErrorI18n} from "../i18n";
import {WebError} from "../api";
import ApiParamBuilder from "../util/queryParams";

/**
 * @typedef GeoRefResult
 * @property {boolean} ambiguous did the query result more than one (ambiguous) addresses?
 * @property {GeoRefAddress} [addressMeta] the single found address for the query (only if ambiguous=false)
 * @property {GeoRefAddress[]} [addresses] the multiple found addresses (only if ambiguous=true)
 */

export class GeoRefModel extends GeneralModel {

    /**
     * Create GeoRef model.
     * @param {string} address requested address
     * @param {string} [accounting] optional Accounting flag
     */
    constructor(address, accounting) {
        super()
        this.address = address;
        this.accounting = accounting;
    }

    validate() {
        if (!this.address || this.address.length < 4) {
            throw new ValidationErrorI18n("address", "address.error.too_short")
        }
    }

    /**
     * Handle and validate response data.
     * @param apiResponsePromise {Promise<object>} response promise from API call
     * @return {Promise<GeoRefResult>} Promise containing result from the GeoRef query
     */
    async handleResponse(apiResponsePromise) {
        const responseUnfiltered = await this.handleResponseBeforeAccuracyFiltering(apiResponsePromise);
        if (responseUnfiltered.ambiguous) {
            // filter found addresses with this.isPreciseEnough()
            const preciseAddresses = responseUnfiltered.addresses.filter((addressCandidate) => this.isPreciseEnough(addressCandidate));
            if (preciseAddresses.length === 0) {
                // No precise addresses remaining
                throw new ErrorI18n('address.error.not_precise_enough');
            } else if (preciseAddresses.length === 1) {
                // Only 1 precise address returned - not ambiguous anymore!
                return {ambiguous: false, addressMeta: preciseAddresses[0]};
            }
            return {ambiguous: true, addresses: preciseAddresses};
        } else {
            // check if found address is precise enough
            if (!this.isPreciseEnough(responseUnfiltered.addressMeta)) {
                throw new ErrorI18n('address.error.not_precise_enough');
            }
            return responseUnfiltered;
        }
    }

    /**
     * Handle and validate response data.
     * @param apiResponsePromise {Promise<object>} response promise from API call
     * @return {Promise<GeoRefResult>} Promise containing result from the GeoRef query
     */
    async handleResponseBeforeAccuracyFiltering(apiResponsePromise) {
        try {
            const responseJson = await apiResponsePromise;
            return {ambiguous: false, addressMeta: responseJson};
        } catch (e) {
            if (e instanceof WebError) {
                switch (e.status) {
                    case 409: return {ambiguous: true, addresses: e.json.geoRefs};
                    case 401: throw new ErrorI18n('general.error.401');
                    case 403: throw new ErrorI18n('general.error.403');
                    case 422: throw new ErrorI18n('address.error.422');
                    case 503: throw new ErrorI18n('general.error.503');
                    case 500: throw new ErrorI18n('general.error.unspecific');
                    default:
                }
                if (e.getCustomErrorMessage()) {
                    throw new ErrorI18n('general.error.custom', {errorMessage: e.getCustomErrorMessage()});
                }
            }
            throw new ErrorI18n('general.error.invalid');
        }
    }

    /**
     * Check if a found GeoRef is precise enough for processing (one of: COORDINATE, HOUSE, STREET_SECTION).
     * @param {GeoRefAddress} addressMeta
     * @return {boolean}
     */
    isPreciseEnough(addressMeta) {
        return addressMeta?.precision && ["COORDINATE", "HOUSE", "STREET_SECTION"].includes(addressMeta.precision);
    }

    /**
     * Get additional Query Parameters.
     * @return {string} request query parameters
     */
    getQueryParameters() {
        return new ApiParamBuilder()
            .addParam("address", this.address)
            .addOptionalParam("accounting", this.accounting)
            .build()
    }

}