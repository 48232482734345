import {useRef} from "react";
import {useTranslation} from 'react-i18next';

import {Menu} from "primereact/menu";
import {Button} from "primereact/button";

function LanguageSwitcher() {

    const {i18n} = useTranslation();

    const languageSwitcherMenu = useRef(null);

    const currentLanguage = i18n.language;

    const languageItems = [
        {
            value: "de",
            label: "Deutsch / German",
            short: "DE"
        },
        {
            value: "en",
            label: "Englisch / English",
            short: "EN"
        }
    ];
    const currentLanguageItem = languageItems.filter((item) => item.value === currentLanguage)[0];

    const model = languageItems.map((item) => {
        return {
            label: item.label,
            command: () => i18n.changeLanguage(item.value)
        };
    });

    return (
        <>
            <Menu id="languageSwitcherMenu" model={model} popup ref={languageSwitcherMenu} popupAlignment="right"/>
            <Button id="languageSwitcherButton" label={currentLanguageItem.short}
                    onClick={(e) => languageSwitcherMenu.current.toggle(e)}
                    aria-controls="languageSwitcherMenu" aria-haspopup text size="small"/>
        </>
    );
}

export default LanguageSwitcher;