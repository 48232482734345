import {useSelector} from 'react-redux'
import {selectAdmin} from '../redux/authSlice'
import {useTranslation} from 'react-i18next';
import {selectAppEnvironment} from "../redux/envSlice";

import ThemeSwitcher from "./ThemeSwitcher";
import LanguageSwitcher from "./LanguageSwitcher";

function FooterLink({href, text, id}) {
    return (
        <a className="no-underline mx-3 text-primary cursor-pointer" id={'footerLink' + id}
           href={href} target="_blank" rel="noreferrer">{text}</a>
    );
}

function AdminInfo() {

    const { t } = useTranslation();

    /** @type {AppEnvironment} */
    const environment = useSelector(selectAppEnvironment);
    const isAdmin = useSelector(selectAdmin);

    if (!isAdmin) {
        return null;
    }

    let gitInfo = null;
    if (environment.hasGitInfo) {
        gitInfo = (
            <span className="footerGitInfo" title={environment.gitCommitMessage}>
                {t('footer.git', {
                    branch: environment.gitBranch,
                    commit: environment.gitCommitHashShort,
                    date: new Date(environment.gitCommitDate)
                })}
            </span>
        );
    }

    let buildDateInfo = null;
    if (environment.buildDate) {
        buildDateInfo = (
            <span className="footerBuildInfo">
                {t('footer.build', {date: new Date(environment.buildDate)})}
            </span>
        );
    }

    let separator = null;
    if (environment.hasGitInfo && environment.buildDate) {
        separator = (<span className="mx-1">&#x2022;</span>)
    }

    return (
        <span className="adminInfo text-muted">
            {gitInfo}
            {separator}
            {buildDateInfo}
        </span>
    );

}

function Footer() {

    const { t } = useTranslation();

    return (
        <footer className="AppFooter surface-ground flex-grow-0">
            <div className="grid mt-0 mx-1">
                <div className="reva-footer-admin col-12 xl:col-4 text-xs">
                    <div className="p-3">
                        <AdminInfo/>
                    </div>
                </div>
                <div className="reva-footer-links col-12 md:col-8 md:offset-2 xl:col-4 xl:offset-0 text-center inner">
                    <div className="p-3">
                        <FooterLink text={t('footer.it_status')} id="footerLinkItStatus" href="https://status.value-marktdaten.de"/>
                        <FooterLink text={t('footer.imprint')} id="footerLinkImpressum" href="https://www.value-marktdaten.de/impressum"/>
                        <FooterLink text={t('footer.privacy')} id="footerLinkDatenschutz"
                                    href="https://www.value-marktdaten.de/datenschutz"/>
                        <FooterLink text={t('footer.contact')} id="footerLinkKontakt" href="https://www.value-marktdaten.de/kontakt"/>
                    </div>
                </div>
                <div className="reva-footer-switchers col-12 md:col-2 xl:col-4 text-right">
                    <div className="p-2">
                        <LanguageSwitcher/>
                        <ThemeSwitcher/>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
