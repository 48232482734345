import {GeneralModel} from "./GeneralModel";
import {ValidationErrorI18n} from "./Validation";
import {WebError} from "../api";
import {ErrorI18n} from "../i18n";
import ApiParamBuilder from "../util/queryParams";

/**
 * @typedef {object} ImmoDropResult
 */

export class ImmoDropModel extends GeneralModel {

    /**
     * Create ImmoDrop model.
     * @param {string} targetUrl requested URL of document to parse
     * @param {number|null} [onDemandTimeoutMs] optional timeout for OnDemand-requests
     */
    constructor(targetUrl, onDemandTimeoutMs) {
        super()
        this.targetUrl = targetUrl;
        this.onDemandTimeoutMs = (typeof onDemandTimeoutMs === 'number') ? onDemandTimeoutMs : 15000;
    }

    validate() {
        if (!this.targetUrl || this.targetUrl.length < 7) {
            throw new ValidationErrorI18n("url", "immodrop.error.too_short")
        }
        if (!this.targetUrl.startsWith("https://") && !this.targetUrl.startsWith("http://")) {
            throw new ValidationErrorI18n("url", "immodrop.error.invalid_scheme")
        }
    }

    /**
     * Handle and validate response data.
     * @param apiResponsePromise {Promise<object>} response promise from API call
     * @return {Promise<ImmoDropResult>} Promise containing result from the GeoRef query
     */
    async handleResponse(apiResponsePromise) {
        try {
            return await apiResponsePromise;
        } catch (e) {
            if (e instanceof WebError) {
                switch (e.status) {
                    case 600: throw new ErrorI18n('immodrop.error.nodata');
                    case 601: throw new ErrorI18n('immodrop.error.nodata');
                    case 602: throw new ErrorI18n('immodrop.error.nodata');
                    case 603: throw new ErrorI18n('immodrop.error.nodata');
                    case 401: throw new ErrorI18n('general.error.401');
                    case 403: throw new ErrorI18n('general.error.403');
                    case 422: throw new ErrorI18n('address.error.422');
                    case 503: throw new ErrorI18n('general.error.503');
                    case 500: throw new ErrorI18n('general.error.unspecific');
                    default:
                }
                if (e.getCustomErrorMessage()) {
                    throw new ErrorI18n('general.error.custom', {errorMessage: e.getCustomErrorMessage()});
                }
            }
            throw new ErrorI18n('general.error.invalid');
        }
    }

    /**
     * Get additional Query Parameters.
     * @return {string} request query parameters
     */
    getQueryParameters() {
        return new ApiParamBuilder()
            .addParam("url", this.targetUrl)
            .addParam("onDemandTimeoutMs", this.onDemandTimeoutMs)
            .build()
    }

}