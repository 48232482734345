import {createSlice} from '@reduxjs/toolkit'
import {defaultAppEnvironment} from "../environment";

export const envSlice = createSlice({
    name: 'env',
    initialState: {
        appEnvironment: defaultAppEnvironment
    },
    reducers: {
        initializeEnvironment: (state, action) => {
            /** @type {AppEnvironment} */
            state.appEnvironment = action.payload.appEnvironment;
        }
    },
    selectors: {
        /** @return {AppEnvironment} */
        selectAppEnvironment: (state) => state.appEnvironment,
        /** @return {boolean} */
        selectDevelopMode: (state) => state.appEnvironment.isDevelopmentMode,
        /** @return {string} */
        selectAppRoleLabel: (state) => state.appEnvironment.roleLabel
    }
})

export const {initializeEnvironment} = envSlice.actions

export const {
    selectAppEnvironment,
    selectDevelopMode,
    selectAppRoleLabel
} = envSlice.selectors

export default envSlice.reducer
