/**
 * Parse date.
 * @param dateStr {string|null} optional date string
 * @param key {string} json key (for error message)
 * @return {Date|null} parsed date or null
 */
function parseOptionalDate(dateStr, key) {
    if (typeof dateStr === "string") {
        const dateTimestamp = Date.parse(dateStr);
        if (isNaN(dateTimestamp)) {
            console.warn(`Invalid date '${dateStr}' in environment.json (key: ${key})`)
            return null;
        }
        return new Date(dateTimestamp);
    }
    return null;
}

/**
 * Parse string.
 * @param str {string|null} optional string
 * @return {string|null} parsed date or null
 */
function parseOptionalStr(str) {
    if (typeof str === "string") {
        return str;
    }
    return null;
}

/**
 * Parse (optional) boolean.
 * @param boolValue {boolean|string|null} optional boolean value
 * @param key {string} json key (for error message)
 * @param defaultValue {boolean} default value to use, if parameter not provided or invalid
 * @return {boolean} parsed boolean or default
 */
function parseOptionalBoolean(boolValue, key, defaultValue) {
    if (typeof boolValue === "boolean") {
        return boolValue;
    }
    if (typeof boolValue === "string") {
        switch (boolValue.toLowerCase()) {
            case "true":
                return true;
            case "false":
                return false;
            default:
                console.warn(`Invalid boolean '${boolValue}' in environment.json (key: ${key})`)
                return defaultValue;
        }
    }
    return defaultValue;
}

/**
 * Encode a Date, so it becomes serializable for Redux
 * @param dateValue {Date|null} input
 * @return {string|null} encoded
 */
function encodeDateSerializable(dateValue) {
    if (!dateValue)
        return null;
    return dateValue.toJSON();
}

/**
 * Default/Fallback environment
 */
const defaultEnvironmentJson = {
    "role": "TESTING",
    "build_date": null,
    "git": {
        "branch": null,
        "commit": null,
        "commitDate": null,
        "commitMessage": null
    },
    "development": false
}

/**
 * @typedef
 * @name AppEnvironment
 * @property {string} role
 * @property {string|null} roleLabel
 * @property {string|null} apiUrl
 * @property {string|null} buildDate
 * @property {string|null} gitBranch
 * @property {string|null} gitCommitHash
 * @property {string|null} gitCommitHashShort
 * @property {string|null} gitCommitDate
 * @property {string|null} gitCommitMessage
 * @property {string|null} omniscaleKey
 * @property {boolean} hasGitInfo
 * @property {boolean} isDevelopmentMode
 */

/**
 * Build AppEnvironment from JSON.
 * @param {object} [json] input json
 * @return {AppEnvironment} generated environment
 */
function buildAppEnvironment(json) {
    if (typeof json !== "object") {
        json = {};
    }
    /** @type {string} */
    const role = parseOptionalStr(json.role) || defaultEnvironmentJson.role;
    /** @type {string|null} */
    let apiUrl = parseOptionalStr(json.api_url);
    if (apiUrl && apiUrl.endsWith("/")) {
        apiUrl = apiUrl.replace(/\/$/, '');
    }
    /** @type {string|null} */
    let roleLabel= null;
    switch (role) {
        case "STAGE":
            roleLabel = "Stage";
            break;
        case "TESTING":
            roleLabel = "Testing";
            break;
        case "LOCAL":
            roleLabel = "Local";
            break;
        default:
    }
    const buildDate = encodeDateSerializable(parseOptionalDate(json.build_date, "build_date"));
    /** @type {string|null} */
    let gitBranch = null;
    /** @type {string|null} */
    let gitCommitHash = null;
    /** @type {string|null} */
    let gitCommitHashShort = null;
    /** @type {Date|null} */
    let gitCommitDate = null;
    /** @type {string|null} */
    let gitCommitMessage = null;
    if (typeof json.git === 'object') {
        gitBranch = parseOptionalStr(json.git.branch);
        gitCommitHash = parseOptionalStr(json.git.commit);
        gitCommitHashShort = (gitCommitHash && gitCommitHash.length > 8) ? gitCommitHash.substring(0, 8) : gitCommitHash;
        gitCommitDate = encodeDateSerializable(parseOptionalDate(json.git.commitDate, "git/commitDate"));
        gitCommitMessage = parseOptionalStr(json.git.commitMessage);
    }
    const hasGitInfo = !!(gitBranch && gitCommitHashShort && gitCommitMessage && gitCommitDate);
    const isDevelopmentMode = parseOptionalBoolean(json.development, "development", false);
    const omniscaleKey = parseOptionalStr(json.omniscaleKey);
    return {role, roleLabel, apiUrl, buildDate, gitBranch, gitCommitHash, gitCommitHashShort, gitCommitDate,
        gitCommitMessage, hasGitInfo, isDevelopmentMode, omniscaleKey};
}

export const defaultAppEnvironment = buildAppEnvironment();
//console.debug(defaultAppEnvironment)

/**
 * Load environment json.
 * @return {Promise<object>} promise of loaded app environment
 */
async function fetchEnvJson() {
    try {
        const response = await fetch("/environment.json", {cache: 'no-store'})
        if (response.status === 404) {
            console.warn("Could not find environment.json. If this is a development environment, you can ignore this warning.")
            return {};
        }
        if (!response.ok) {
            console.error("Error loading environment.json", response.status, response.statusText);
            // Fallback to LIVE, so it doesn't impact availability of the live cluster
            return {
                "role": "LIVE"
            };
        }
        return await response.json()
    } catch (e) {
        console.error("Error loading environment.json", e);
        // Fallback to LIVE, so it doesn't impact availability of the live cluster
        return {
            "role": "LIVE"
        };
    }
}

/**
 * Get current app environment from a static context. MUST be called, after loadEnvironment() completed.
 * @param {boolean} required if required is true, an error is thrown when current environment is not yet set
 * @return {AppEnvironment} the current environment
 */
export function getCurrentEnvironment(required){
    const currentEnvironment = window.currentAppEnvironment;
    if (required && !currentEnvironment) {
        throw new Error("getCurrentEnvironment() called, before environment was loaded via loadEnvironment()")
    }
    return currentEnvironment;
}

/**
 * Check if current environment is in develop mode in a static context. If environment is not yet initialized, false is returned.
 * @return {boolean} true, if develop mode is enabled
 */
export function isCurrentEnvironmentInDevelopMode() {
    const currentEnvironment = getCurrentEnvironment(false);
    return currentEnvironment && currentEnvironment.isDevelopmentMode;
}

/**
 * Load environment. MUst be called and complete, before the app initializes.
 * @return {Promise<AppEnvironment>} promise which will complete, when the environment is loaded
 */
export async function loadEnvironment() {
    const environmentJson = await fetchEnvJson();
    const effectiveEnvironmentJson = {...defaultEnvironmentJson, ...environmentJson}
    const currentAppEnvironment = buildAppEnvironment(effectiveEnvironmentJson);
    //console.debug(currentAppEnvironment)
    if (currentAppEnvironment.roleLabel) {
        document.title += ` (${currentAppEnvironment.roleLabel})`;
    }
    window.currentAppEnvironment = currentAppEnvironment;
    return currentAppEnvironment;
}