import {useSelector} from "react-redux";
import {
    selectError,
} from "../../redux/valueIndicationSlice";

import {Divider} from "primereact/divider";
import I18nErrorMessage from "../../component/I18nErrorMessage";

import {ParameterInput, ParameterInputSegment} from "./parameterInputs";

import {
    selectSegment, selectSingleParamCurried
} from "../../redux/objectParametersSlice";
import {useTranslation} from "react-i18next";
import UiFragment from "../../component/UiFragment";
import {selectInputParamForCurrentSegmentCurried} from "../../redux/specSlice";

/*
 * -- Meta definitions for expandable panels ---
 */

/** @type {MetaInputParamDef} */
const META_MODERNIZED = {
    parameter: "modernized",
    type: "boolean",
    nameDe: "Wurde das Objekt in der Vergangenheit modernisiert?",
    nameEn: "Has the object been modernized in the past?"
};

/** @type {MetaInputParamDef} */
const META_PARKING = {
    parameter: "parking",
    type: "boolean",
    nameDe: "Sind Stellplätze vorhanden?",
    nameEn: "Are parking places available?"
};

/** @type {MetaInputParamDef} */
const META_DISTRICT_COURT = {
    parameter: "district_curt",
    type: "string",
    nameDe: "Amtsgericht",
    nameEn: "District Court"
};

/** @type {MetaInputParamDef} */
const META_REGISTER_DATE = {
    parameter: "register_date",
    type: "string",
    nameDe: "Grundbuch von",
    nameEn: "Register from"
};

/** @type {MetaInputParamDef} */
const META_REGISTER_PAGE = {
    parameter: "register_page",
    type: "string",
    nameDe: "Blatt (Band optional)",
    nameEn: "Page (volume optional)"
};


function ColumnTitle({title}) {
    return <h3 className="mt-0 mb-3 pt-0 pb-2 border-bottom-1 border-gray-300">{title}</h3>;
}

function PanelTitle({title}) {
    if (!title)
        return null;

    return (
        <>
            <h4 className="my-0">{title}</h4>
            <Divider/>
        </>
    );
}

function PanelForInputs({children, title}) {
    return (
        <div className="border-round surface-100 p-3 panelForInputs">
            <PanelTitle title={title}/>
            {children}
        </div>
    );
}

/**
 * Parameter input for the displayed segment.
 * @return {JSX.Element}
 * @constructor
 */
function BaseParameterInputs() {

    const segment = useSelector(selectSegment);

    if (segment === 'WHG_K') {
        return (
            <>
                <ParameterInput parameter="year_of_construction"/>
                <ParameterInput parameter="space_living"/>
                <ParameterInput parameter="building_floor"/>
                <ParameterInput parameter="ownership_share"/>
                <ParameterInput parameter="building_units"/>
                <ParameterInput parameter="space_plot"/>
                <ParameterInput parameter="quality_furnishings"/>
                <ParameterInput parameter="property_condition"/>
            </>
        );
    }
    if (segment === 'EZFH_K') {
        return (
            <>
                <ParameterInput parameter="building_type"/>
                <ParameterInput parameter="year_of_construction"/>
                <ParameterInput parameter="space_living"/>
                <ParameterInput parameter="building_construction_type"/>
                <ParameterInput parameter="prop_detached"/>
                <ParameterInput parameter="space_plot"/>
                <ParameterInput parameter="building_floors_upper"/>
                <ParameterInput parameter="attic_extension"/>
                <ParameterInput parameter="prop_cellar"/>
                <ParameterInput parameter="quality_furnishings"/>
                <ParameterInput parameter="property_condition"/>
            </>
        );
    }
    if (segment === 'MFHWGH_K') {
        return (
            <>
                <ParameterInput parameter="building_type"/>
                <ParameterInput parameter="year_of_construction"/>
                <ParameterInput parameter="building_construction_type"/>
                <ParameterInput parameter="space_plot"/>
                <ParameterInput parameter="prop_cellar"/>
                <ParameterInput parameter="space_living"/>
                <ParameterInput parameter="building_units"/>
                <ParameterInput parameter="space_commercial"/>
                <ParameterInput parameter="quality_furnishings"/>
                <ParameterInput parameter="property_condition"/>
            </>
        );
    }
    if (segment === 'GRST_K') {
        return (
            <>
                <ParameterInput parameter="space_plot"/>
            </>
        );
    }

    return null;
}



function LeftColumnBaseParameters() {

    const {t} = useTranslation();

    return (
        <>
            <ColumnTitle title={t("value.input.objectProperties")}/>
            <PanelForInputs>
                <ParameterInputSegment/>
                <BaseParameterInputs/>
            </PanelForInputs>
        </>
    );

}

function MiddleColumnModernization() {
    const modernized = useSelector(selectSingleParamCurried(META_MODERNIZED.parameter, true));
    return (
        <PanelForInputs>
            <ParameterInput metaDef={META_MODERNIZED}/>
            <UiFragment rendered={modernized}>
                <Divider/>
                <ParameterInput parameter="year_of_modernization"/>
                <ParameterInput parameter="degree_modernization_category"/>
                <ParameterInput parameter="prop_energy"/>
            </UiFragment>
        </PanelForInputs>
    );
}

function MiddleColumnParking() {
    const parking = useSelector(selectSingleParamCurried(META_PARKING.parameter, true));
    const garageUndergroundData = useSelector(selectInputParamForCurrentSegmentCurried("prop_garage_underground_count"));
    return (
        <PanelForInputs>
            <ParameterInput metaDef={META_PARKING}/>
            <UiFragment rendered={parking}>
                <Divider/>
                <ParameterInput parameter="prop_garage_count"/>
                <ParameterInput parameter="prop_garage_cost_base"/>
                <ParameterInput parameter="prop_garage_profit_monthly"/>
                <Divider/>
                <ParameterInput parameter="prop_carport_count"/>
                <ParameterInput parameter="prop_carport_cost_base"/>
                <ParameterInput parameter="prop_carport_profit_monthly"/>
                <Divider/>
                <ParameterInput parameter="prop_parking_lot_count"/>
                <ParameterInput parameter="prop_parking_lot_cost_base"/>
                <ParameterInput parameter="prop_parking_lot_profit_monthly"/>
                <UiFragment rendered={!!garageUndergroundData}>
                    <Divider/>
                    <ParameterInput parameter="prop_garage_underground_count"/>
                    <ParameterInput parameter="prop_garage_underground_cost_base"/>
                    <ParameterInput parameter="prop_garage_underground_profit_monthly"/>
                </UiFragment>
            </UiFragment>
        </PanelForInputs>
    );
}

function MiddleColumnCommonParameters() {
    return (
        <>
            <ColumnTitle title="Erweiterte Objektangaben"/>
            <MiddleColumnModernization/>
            <MiddleColumnParking/>
        </>
    );
}

function RightColumnPurchasePrice() {
    return (
        <PanelForInputs>
            <ParameterInput parameter="purchase_price"/>
        </PanelForInputs>
    );
}

function RightColumnRegisterInputs() {
    return (
        <PanelForInputs title="Grundbuchangaben">
            <ParameterInput metaDef={META_DISTRICT_COURT}/>
            <ParameterInput metaDef={META_REGISTER_DATE}/>
            <ParameterInput metaDef={META_REGISTER_PAGE}/>
        </PanelForInputs>
    );
}

function RightColumnLeasehold() {

    const leaseholdData = useSelector(selectInputParamForCurrentSegmentCurried("prop_leasehold"));
    const leasehold =  useSelector(selectSingleParamCurried("prop_leasehold", false));

    if (!leaseholdData)
        return null;

    return (
        <PanelForInputs>
            <ParameterInput parameter="prop_leasehold"/>
            <UiFragment rendered={leasehold}>
                <Divider />
                <ParameterInput parameter="prop_leasehold_amount_pa"/>
                <ParameterInput parameter="prop_leasehold_duration"/>
            </UiFragment>
        </PanelForInputs>
    );
}

function RightColumnRegister() {
    return (
        <>
            <ColumnTitle title="Grundbuch"/>
            <RightColumnPurchasePrice/>
            <RightColumnRegisterInputs/>
            <RightColumnLeasehold/>
        </>
    );
}

export default function ObjectInputs() {

    const indicationError = useSelector(selectError);
    const segment = useSelector(selectSegment);

    const hasMiddleColumn = segment !== 'GRST_K';

    const columns = hasMiddleColumn ? (
        <>
            <div className="col-4">
                <LeftColumnBaseParameters/>
            </div>
            <div className="col-4">
                <MiddleColumnCommonParameters/>
            </div>
            <div className="col-4">
                <RightColumnRegister/>
            </div>
        </>
    ) : (
        <>
            <div className="col-6">
                <LeftColumnBaseParameters/>
            </div>
            <div className="col-6">
                <RightColumnRegister/>
            </div>
        </>
    );

    return (
        <div className="flex-1">
            <I18nErrorMessage errorObject={indicationError} className="flex-grow-0 flex-shrink-0 mb-3 w-full"/>
            <div className="grid">
                {columns}
            </div>
        </div>
    );
}