import {useTranslation} from "react-i18next";
import {TabView} from "primereact/tabview";

import {Outlet, useLocation, useNavigate, matchPath} from "react-router-dom";
import {addAddressQuery, addAddressQueryFromLocation} from "../router";
import {useSelector} from "react-redux";
import {selectAddressData, selectInitialized} from "../redux/addressSlice";
import {useEffect} from "react";
import {selectDevelopMode} from "../redux/envSlice";
import {
    checkPermissionRule,
    PERMISSION_RULE_LOCATION_MICRO,
    PERMISSION_RULE_LOCATION_MACRO,
    PERMISSION_RULE_LOCATION_POIS,
    PERMISSION_RULE_LOCATION,
} from "../model/permissions";
import {selectPrivileges} from "../redux/authSlice";
import {getTabPanelWithPermission} from "../component/TabPanelWithPermission";
import MissingPermissionIndicator from "../component/MissingPermissionIndicator";

/** @type {TabHeaderWithPermissionParams[]} */
const TABS = [
    {
        "route": "points-of-interest",
        "headerI18n": "location.category.pois",
        "permissionRule": PERMISSION_RULE_LOCATION_POIS
    },
    {
        "route": "micro",
        "headerI18n": "location.category.micro",
        "permissionRule": PERMISSION_RULE_LOCATION_MICRO
    },
    {
        "route": "macro",
        "headerI18n": "location.category.macro",
        "permissionRule": PERMISSION_RULE_LOCATION_MACRO
    }
]
const TAB_ROUTES = TABS.map(tab => tab.route);

function PageLocation() {

    const {t} = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();
    const {pathname} = location;

    const addressData = useSelector(selectAddressData);
    const addressInitialized = useSelector(selectInitialized)
    const isDevelop = useSelector(selectDevelopMode);
    const privileges = useSelector(selectPrivileges);

    // Redirect to address page, if this page is shown without a valid address selected
    useEffect(() => {
        if (addressInitialized && !addressData) {
            if (isDevelop) {
                console.log("No valid address selected: redirect to address page");
            }
            navigate("/address" + addAddressQueryFromLocation(location));
        }
    }, [addressInitialized, addressData, navigate, location, isDevelop]);

    let activeIndex = null;
    for (let index = 0; index < TAB_ROUTES.length; index++) {
        if (matchPath("/location/" + TAB_ROUTES[index], pathname)) {
            activeIndex = index;
            break;
        }
    }

    const onTabChange = (index) => {
        if (index >= 0 && index < TAB_ROUTES.length) {
            navigate("/location/" + TAB_ROUTES[index] + addAddressQuery(addressData));
        }
    };

    const hasAnyPermissions = checkPermissionRule(privileges, PERMISSION_RULE_LOCATION);
    if (!hasAnyPermissions) {
        return (<MissingPermissionIndicator missingPermissions={true} />)
    }

    /** @type React.ReactElement[] */
    const tabs = TABS.map(tab => getTabPanelWithPermission(tab, t, privileges));

    return (
        <main role="main" className="flex-grow-1 pt-3 px-4 py-4 w-12">
            <div className="flex flex-column w-full h-full">
                <h2 className="flex-0 flex mt-0">
                    <div className="flex-0 HeaderTextShared">{t('location.location')}:</div>
                    <TabView className="flex-1 HeaderTabView" panelContainerClassName="hidden" activeIndex={activeIndex}
                             onTabChange={(e) => onTabChange(e.index)}>
                        {tabs}
                    </TabView>
                </h2>
                <Outlet/>
            </div>
        </main>
    );
}

export default PageLocation;