import {useDispatch, useSelector} from "react-redux";
import {
    selectSegment,
    selectSingleParamCurried,
    setSegmentAndReset,
    setSingleParam
} from "../../redux/objectParametersSlice";
import {InputNumber} from "primereact/inputnumber";
import {Dropdown} from "primereact/dropdown";
import {selectInputParamForCurrentSegmentCurried} from "../../redux/specSlice";
import {snakeToCamel} from "../../util/stringFormat";
import {SelectButton} from "primereact/selectbutton";
import {useSelectByCurrentLanguage} from "../../util/language";
import {TriStateCheckbox} from "primereact/tristatecheckbox";
import {Checkbox} from "primereact/checkbox";
import {InputText} from "primereact/inputtext";

/**
 * @typedef SpecCategoryDef Category from Spec
 * @property {number} categoryNumber categoryNumber
 * @property {string} shortNameDe Category name (DE)
 * @property {string} shortNameEn Category name (EN)
 * @property {string|null} [titleDe] Category title (DE)
 * @property {string|null} [titleEn] Category title (EN)
 */

/**
 * @typedef MetaInputParamDef Meta Parameter properties
 * @property {string} [parameter] parameter name (optional)
 * @property {string} type parameter type
 * @property {string|string[]|null} [onlyInSegment] optional flag, if the meta param should only be valid for specific flags
 * @property {boolean|null} [required] is the parameter required?
 * @property {string|null} [nameDe] Name (DE)
 * @property {string|null} [nameEn] Name (En)
 * @property {string|null} [unitDe] Unit (DE)
 * @property {string|null} [unitEn] Unit (EN)
 * @property {number|null} [minValue] optional min value of number input
 * @property {number|null} [maxValue] optional max value of number input
 * @property {SpecCategoryDef[]|null} [categories]
 */

/**
 * @typedef SpecInputParamDef Input Parameter from Spec
 * @property {string} parameter parameter name
 * @property {string} type parameter type
 * @property {boolean} required is the parameter required?
 * @property {string} nameDe Name (DE)
 * @property {string} nameEn Name (En)
 * @property {string|null} [unitDe] Unit (DE)
 * @property {string|null} [unitEn] Unit (EN)
 * @property {number|null} [minValue] optional min value of number input
 * @property {number|null} [maxValue] optional max value of number input
 * @property {SpecCategoryDef[]|null} [categories]
 */

/**
 * @typedef InputParamDef
 * @property {string} parameter parameter name
 * @property {boolean} meta is it a meta parameter?
 * @property {string} type parameter type
 * @property {boolean} required is the parameter required?
 * @property {string} nameDe Name (DE)
 * @property {string} nameEn Name (En)
 * @property {string|null} [unitDe] Unit (DE)
 * @property {string|null} [unitEn] Unit (EN)
 * @property {number|null} [minValue] optional min value of number input
 * @property {number|null} [maxValue] optional max value of number input
 * @property {SpecCategoryDef[]|null} [categories]
 */

/**
 * React Hook to use an input parameter spec, either from
 * @param {string} parameter parameter or meta parameter name to query
 * @param {MetaInputParamDef|null} [metaDef] optional override for the parameter spec, to use a meta parameter instead
 * @return {InputParamDef|null} resolved input parameter spec (or null, if parameter is not valid)
 */
const useInputParameterDef = (parameter, metaDef) => {
    const currentSegment = useSelector(selectSegment);
    /** @type {SpecInputParamDef|null} */
    const specInputParamDef = useSelector(selectInputParamForCurrentSegmentCurried(parameter));
    if (metaDef) {
        const {type, onlyInSegment, required, nameDe, nameEn, unitDe, unitEn, minValue, maxValue, categories} = metaDef;
        if (typeof onlyInSegment === "string" && onlyInSegment !== currentSegment) {
            return null;
        }
        if (Array.isArray(onlyInSegment) && onlyInSegment.includes(currentSegment)) {
            return null;
        }
        return {
            parameter,
            meta: true,
            type,
            required: !!required,
            nameDe: nameDe || parameter,
            nameEn: nameEn || parameter,
            unitDe,
            unitEn,
            minValue,
            maxValue,
            categories: categories || null
        };
    } else if (specInputParamDef) {
        const {type, required, nameDe, nameEn, unitDe, unitEn, minValue, maxValue, categories} = specInputParamDef;
        return {
            parameter,
            meta: false,
            type,
            required,
            nameDe,
            nameEn,
            unitDe,
            unitEn,
            minValue,
            maxValue,
            categories
        }
    }
    return null;
};

/**
 * React Hook to gain access to a specific parameter value.
 * @param {string} parameter parameter name
 * @param {boolean} meta flag, if meta parameter should be used instead of (spec) parameter
 * @param {(object) => any} [valuePropertyMapper] optional wrapper to get the value from the event object
 * @return {[any, (FormEvent) => void]} array: [0] = current value, [1] = change function
 */
const useParameterValue = (parameter, meta, valuePropertyMapper) => {
    const currentValue = useSelector(selectSingleParamCurried(parameter, meta));
    const dispatch = useDispatch();
    if (typeof valuePropertyMapper !== "function") {
        valuePropertyMapper = (e) => e.value;
    }
    const onValueChange = (e) => {
        dispatch(setSingleParam({parameter, meta, value: valuePropertyMapper(e)}));
    };
    return [currentValue, onValueChange];
};

/**
 * Container and label for a field.
 * @param {string} id htmlId to connect to the label
 * @param {string|null} [label] rendered label (override)
 * @param {boolean|null} [required] flag, if the field should be rendered as required (override)
 * @param {string|null} [parameter] optional parameter name, to get label and required from
 * @param {MetaInputParamDef|null} [metaDef] optional meta parameter definition, to use instead of parameter
 * @param {boolean|null} [showUnit] optional flag to wrap the input in an input group, if the parameter metadata has a unit
 * @param {JSX.Element} children children, containing the real input
 * @return {React.ReactNode}
 * @constructor
 */
function ParameterFieldContainer({id, label, required, parameter, metaDef, showUnit, children}) {

    const selectByCurrentLanguage = useSelectByCurrentLanguage();
    const parameterData = useInputParameterDef(parameter, metaDef);

    if (parameter && !parameterData)
        return null;

    /** @type {boolean} */
    const effectiveRequired = (typeof required === "boolean" || !parameterData) ? !!required : parameterData.required;
    /** @type {string} */
    const effectiveLabel =  (typeof label === "string" || !parameterData) ? label : selectByCurrentLanguage(parameterData.nameDe, parameterData.nameEn);

    const renderedLabel = effectiveLabel + (effectiveRequired ? ' *' : '');
    const className = effectiveRequired ? 'font-bold' : '';

    const unit = (showUnit && parameterData) ? selectByCurrentLanguage(parameterData.unitDe, parameterData.unitEn) : null;

    let innerControl = children;
    if (unit) {
        innerControl = (
            <div className="p-inputgroup w-full">
                {innerControl}
                <span className="p-inputgroup-addon" style={{whiteSpace: "nowrap"}}>{unit}</span>
            </div>
        );
    }

    return (
        <>
            <div key={id} className="field p-inputtext-sm text-sm">
                <label htmlFor={id} className={className}>{renderedLabel}</label>
                {innerControl}
            </div>
        </>
    )
}

const availableSegments = [
    {value: 'WHG_K', labelDe: 'Eigentumswohnung', labelEn: 'Flat'},
    {value: 'EZFH_K', labelDe: 'Ein-/Zweifamilienhaus', labelEn: 'One/two family house'},
    {value: 'MFHWGH_K', labelDe: 'Mehrfamilienhaus', labelEn: 'Apartment house'},
    {value: 'GRST_K', labelDe: 'Grundstück', labelEn: 'Plot'},
];

export function ParameterInputSegment() {

    const dispatch = useDispatch();

    const selectByCurrentLanguage = useSelectByCurrentLanguage();

    const segment = useSelector(selectSegment);
    const changeSegment = (e) => {
        dispatch(setSegmentAndReset({segment: e.value}));
    };
    const label = selectByCurrentLanguage("Objektart", "Object type");

    return (
        <ParameterFieldContainer id="objectType" label={label} required="true">
            <Dropdown id="objectType" className="w-full" value={segment} onChange={changeSegment}
                  options={availableSegments} optionLabel={selectByCurrentLanguage('labelDe', 'labelEn')}/>
        </ParameterFieldContainer>
    );
}

function ParameterInputNumber({parameter, label, id, metaDef}) {

    const selectByCurrentLanguage = useSelectByCurrentLanguage();

    const parameterData = useInputParameterDef(parameter, metaDef);
    const [currentValue, onValueChange] = useParameterValue(parameter, parameterData.meta);

    if (!parameterData)
        return null;

    let minFractionDigits = 0;
    let maxFractionDigits = 0;
    if (parameterData.type === 'double') {
        minFractionDigits = 2;
        maxFractionDigits = 2;
    }

    const locale = selectByCurrentLanguage("de-DE", "en-US");

    return (
        <ParameterFieldContainer id={id} label={label} parameter={parameter} metaDef={metaDef} showUnit={true}>
            <InputNumber inputId={id} className="w-full" inputClassName="text-right"
                         value={currentValue} locale={locale}
                         useGrouping={true} minFractionDigits={minFractionDigits} maxFractionDigits={maxFractionDigits}
                         min={parameterData.minValue} max={parameterData.maxValue}
                         onValueChange={onValueChange}/>
        </ParameterFieldContainer>
    );
}

function ParameterInputYear({parameter, label, id, metaDef}) {

    const parameterData = useInputParameterDef(parameter, metaDef);
    const [currentValue, onValueChange] = useParameterValue(parameter, parameterData.meta);

    if (!parameterData)
        return null;

    return (
        <ParameterFieldContainer id={id} label={label} parameter={parameter} metaDef={metaDef}>
            <InputNumber inputId={id} className="w-full" inputClassName="text-right"
                         value={currentValue} useGrouping={false}
                         min={parameterData.minValue} max={parameterData.maxValue}
                         onValueChange={onValueChange}/>
        </ParameterFieldContainer>
    );
}

function ParameterInputText({parameter, label, id, metaDef}) {

    const parameterData = useInputParameterDef(parameter, metaDef);
    const [currentValue, onValueChange] = useParameterValue(parameter, parameterData.meta, e => e.target.value);

    if (!parameterData)
        return null;

    return (
        <ParameterFieldContainer id={id} label={label} parameter={parameter} metaDef={metaDef}>
            <InputText id={id} className="w-full" value={currentValue || ""} onChange={onValueChange}/>
        </ParameterFieldContainer>
    );
}

function ParameterInputCategoryButtons({parameter, label, id, metaDef}) {

    const selectByCurrentLanguage = useSelectByCurrentLanguage();

    const parameterData = useInputParameterDef(parameter, metaDef);
    const [currentValue, onValueChange] = useParameterValue(parameter, parameterData.meta);

    if (!parameterData || !Array.isArray(parameterData.categories) || !parameterData.categories.length)
        return null;

    const options = Array.isArray(parameterData.categories) ? parameterData.categories.map(category => {
        return {
            value: category.categoryNumber,
            label: selectByCurrentLanguage(category.shortNameDe, category.shortNameEn),
            title: selectByCurrentLanguage(category.titleDe, category.titleEn)
        };
    }) : [];

    return (
        <ParameterFieldContainer id={id} label={label} parameter={parameter} metaDef={metaDef}>
            <SelectButton id={id} value={currentValue} className="w-full" pt={{button: {className: "p-button-sm"}}}
                          options={options} onChange={onValueChange}/>
        </ParameterFieldContainer>
    );
}

function ParameterInputCheckbox({parameter, label, id, metaDef}) {

    const selectByCurrentLanguage = useSelectByCurrentLanguage();

    const parameterData = useInputParameterDef(parameter, metaDef);
    const [currentValue, onValueChange] = useParameterValue(parameter, parameterData.meta, e => e.checked);

    if (!parameterData)
        return null;

    /** @type {string} */
    const effectiveLabel =  (typeof label === "string" || !parameterData) ? label : selectByCurrentLanguage(parameterData.nameDe, parameterData.nameEn);

    return (
        <div key={id} className="field p-inputtext-sm text-sm flex align-items-center">
            <Checkbox inputId={id} checked={currentValue} onChange={onValueChange} />
            <label htmlFor={id} className="ml-3">{effectiveLabel}</label>
        </div>
    );
}

function ParameterInputTriState({parameter, label, id, metaDef}) {

    const selectByCurrentLanguage = useSelectByCurrentLanguage();

    const parameterData = useInputParameterDef(parameter, metaDef);
    const [currentValue, onValueChange] = useParameterValue(parameter, parameterData.meta);

    // TODO: Replace? TrieSTate is Deprecated in PrimeReact?

    if (!parameterData)
        return null;

    /** @type {string} */
    const effectiveLabel =  (typeof label === "string" || !parameterData) ? label : selectByCurrentLanguage(parameterData.nameDe, parameterData.nameEn);

    return (
        <div key={id} className="col-12 flex align-items-center">
            <TriStateCheckbox value={currentValue} onChange={onValueChange} pt={{input: {id: id}}}/>
            <label htmlFor={id} className="ml-3">{effectiveLabel}</label>
        </div>
    );
}

/**
 * Display parameter input (all types)
 * @param {string} [parameter] parameter key (can be omitted, if metaDef with parameter property is passed)
 * @param {MetaInputParamDef|null} [metaDef] optional meta parameter definition, to use instead of parameter
 * @param {string|null} [label] optional (override) label to display (instead of label defined by input parameter meta)
 * @param {boolean|null} [triState] optional flag for boolean parameters: use TriState instead of checkbox
 * @constructor
 */
export function ParameterInput({parameter, metaDef, label, triState}) {

    if (!parameter && metaDef) {
        parameter = metaDef.parameter;
    }

    const parameterData = useInputParameterDef(parameter, metaDef);

    // if Parameter is not a valid input for current segment, don't show input
    if (!parameterData)
        return null;

    const id = snakeToCamel("input_" + parameter);

    switch (parameterData.type) {
        case "boolean":
            if (triState) {
                return (<ParameterInputTriState parameter={parameter} label={label} id={id} metaDef={metaDef} />);
            }
            return (<ParameterInputCheckbox parameter={parameter} label={label} id={id} metaDef={metaDef} />);
        case "category":
            return (<ParameterInputCategoryButtons parameter={parameter} label={label} id={id} metaDef={metaDef} />);
        case "double":
            return (<ParameterInputNumber parameter={parameter} label={label} id={id} metaDef={metaDef} />);
        case "integer":
            if (parameter.includes("year")) {
                return (<ParameterInputYear parameter={parameter} label={label} id={id} metaDef={metaDef} />);
            }
            return (<ParameterInputNumber parameter={parameter} label={label} id={id} metaDef={metaDef} />);
        case "string":
            return (<ParameterInputText parameter={parameter} label={label} id={id} metaDef={metaDef} />);
        default:
            console.warn(`Unsupported Input Type: ${parameterData.type}`)
            return null;
    }
}
