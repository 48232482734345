import {useSelector} from "react-redux";
import {selectLoggedIn, selectPrivileges} from "../redux/authSlice";
import {selectDarkTheme} from "../redux/themeSlice";
import React, {useEffect} from "react";
import {Chart} from "chart.js";
import LoginForm from "./LoginForm";
import Footer from "./Footer";
import {RouterProvider} from "react-router-dom";

import {routerFactory} from "../router";

export default function AppContainer() {

    const loggedIn = useSelector(selectLoggedIn);
    const isDarkTheme = useSelector(selectDarkTheme);
    const privileges = useSelector(selectPrivileges);

    const router = routerFactory(privileges);

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        Chart.defaults.font.family = documentStyle.getPropertyValue('--font-family');
    }, [isDarkTheme]);

    return (
        <div className={"App flex flex-column " + (isDarkTheme ? "app-theme-dark" : "app-theme-light")}>
            {loggedIn ? (
                <RouterProvider router={router}/>
            ) : (
                <LoginForm/>
            )}
            <Footer />
        </div>
    );

}