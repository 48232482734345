import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {useDispatch, useSelector} from "react-redux";
import {TabPanel, TabView} from "primereact/tabview";
import {useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import I18nErrorMessage from "../component/I18nErrorMessage";
import UiFragment from "../component/UiFragment";
import {Message} from "primereact/message";
import {Divider} from "primereact/divider";
import {
    clear,
    selectImmodropLoading,
    selectImmodropQueryError,
    selectAddressLoading,
    selectAddressError,
    selectAddressQuery,
    selectImportJson,
    selectImportSegment,
    selectImportAddress,
    selectValid,
    queryImmodropAsync,
    queryImportAddressAsync, finalizeImport, setSegment
} from "../redux/importSlice";
import AddressCard from "./address/AddressCard";
import {Dropdown} from "primereact/dropdown";
import {useSelectByCurrentLanguage} from "../util/language";
import {Toast} from "primereact/toast";
import {IconField} from "primereact/iconfield";
import {InputIcon} from "primereact/inputicon";
import {handlePreventDefault} from "../util/events";
import {useTranslation} from "react-i18next";


const supportedSegmentsSelections = [
    {value: 'WHG_K', labelI18n: 'importDialog.segment.WHG_K'},
    {value: 'EZFH_K', labelI18n: 'importDialog.segment.EZFH_K'},
    {value: 'MFHWGH_K', labelI18n: 'importDialog.segment.MFHWGH_K'},
    {value: 'GRST_K', labelI18n: 'importDialog.segment.GRST_K'},
];
const supportedSegmentKeys = supportedSegmentsSelections.map(selection => selection.value);


export default function DialogImport({visible, setVisible}) {

    const {t} = useTranslation();
    const selectByCurrentLanguage = useSelectByCurrentLanguage();

    const toastRef = useRef(null);

    const [tab, setTab] = useState(0);
    const [immodropInput, setImmodropInput] = useState("");
    const [addressInput, setAddressInput] = useState(null);
    const [segmentInput, setSegmentInput] = useState(null);

    const dispatch = useDispatch();

    const importDataValid = useSelector(selectValid);

    const immodropLoading = useSelector(selectImmodropLoading);
    const immodropQueryError = useSelector(selectImmodropQueryError);

    const addressLoading = useSelector(selectAddressLoading);
    const addressQueryError = useSelector(selectAddressError);
    const addressQuery = useSelector(selectAddressQuery);

    const importJson = useSelector(selectImportJson);
    const importSegment = useSelector(selectImportSegment);
    const importAddress = useSelector(selectImportAddress);

    const loading = immodropLoading || addressLoading;
    const currentAddress = (addressInput === null) ? addressQuery : addressInput;
    const segmentIsValid = supportedSegmentKeys.includes(importSegment);

    let segmentSelection = supportedSegmentsSelections.map((segmentSelection) => {
        return {
            value: segmentSelection.value,
            label: t(segmentSelection.labelI18n),
        }
    });
    if (!segmentIsValid) {
        segmentSelection = [...segmentSelection, {
            value: importSegment,
            label: t('importDialog.segment.notSupported', {segment: importSegment})
        }];
    }

    const currentSegment = (segmentInput === null) ? importSegment : segmentInput;

    const immodropSuccessMessage = importJson ?
        t('immodrop.success', {size: Object.values(importJson).length}) :
        null;

    const resetUiState = () => {
        setTab(0);
        setImmodropInput("");
        setAddressInput(null);
        setSegmentInput(null);
    }

    const onTabChange = (e) => {
        dispatch(clear());
        resetUiState();
        setTab(e.index);
    }

    const onCancel = () => {
        dispatch(clear());
        resetUiState();
        setVisible(false);
    }

    const onRunImmodrop = handlePreventDefault(() => {
        setAddressInput(null);
        setSegmentInput(null);
        dispatch(queryImmodropAsync(immodropInput));
    });

    const onUpdateAddress = () => {
        dispatch(queryImportAddressAsync(currentAddress))
    }

    const onUpdateSegment = (e) => {
        const newSegment = e.value;
        setSegment(newSegment);
        if (supportedSegmentKeys.includes(newSegment)) {
            dispatch(setSegment({segment: newSegment}));
        }
    }

    const onJsonCopy = async () => {
        try {
            await navigator.clipboard.writeText(JSON.stringify(importJson, null, 4));
            toastRef.current.show({ severity: 'info', summary: 'Info', detail: t('immodrop.copiedJsonToClipboard') });
        } catch (e) {
            console.warn("Could not copy JSON to clipboard", e);
        }
    }

    const onFinalizeImport = () => {
        dispatch(finalizeImport());
        resetUiState();
        setVisible(false);
    }

    const footerContent = (
        <div>
            <Button label={t('importDialog.cancel')} icon="pi pi-times" severity="danger" text onClick={onCancel}/>
            <Button label={t('importDialog.submit')} icon="pi pi-check" onClick={onFinalizeImport} disabled={!importDataValid}/>
        </div>
    );

    return (
        <Dialog id="importObject" header={t('importDialog.title')} footer={footerContent}
                style={{minWidth: "70rem", minHeight: "44rem"}}
                visible={visible} onHide={() => setVisible(false)} closable={false}>

            <Toast ref={toastRef} />

            <TabView id="importSource" activeIndex={tab} onTabChange={onTabChange}>
                <TabPanel key="immoDrop" header={t('importDialog.tab.immodrop')} leftIcon="pi pi-globe mr-3">

                    <form className="flex w-full field align-items-center" onSubmit={onRunImmodrop}>
                        <label htmlFor="urlInput" className="mr-5 mb-0 flex-0 font-bold w-8rem">{t('immodrop.url.label')}</label>
                        <IconField iconPosition="left" className="flex-1">
                            <InputIcon className="pi pi-globe"/>
                            <InputText id="urlInput" value={immodropInput} onChange={(e) => setImmodropInput(e.target.value)}
                                       placeholder={t('immodrop.url.example')} className="w-full"
                                       disabled={loading} invalid={!!immodropQueryError} />
                        </IconField>
                        <Button id="runImmodropButton" label={t('immodrop.url.submit')} icon="pi pi-lightbulb"
                                onClick={onRunImmodrop} disabled={loading} className="flex-0 ml-3"
                                loading={loading} loadingIcon="pi pi-spin pi-spinner"/>
                    </form>
                    <I18nErrorMessage errorObject={immodropQueryError}/>
                    <UiFragment rendered={!!immodropSuccessMessage}>
                        <Message severity="success" className="w-full" content={
                            <>
                                <span className="pi pi-check mr-2"></span>
                                <span>{immodropSuccessMessage}</span>
                                <Button size="small" icon="pi pi-question-circle" className="ml-3" text
                                        tooltip={JSON.stringify(importJson, null, 4)}
                                        tooltipOptions={{pt: {text: {style: {whiteSpace: "pre"}}}, autoHide: false}}
                                        onClick={onJsonCopy}/>
                            </>
                        }/>
                    </UiFragment>
                    <UiFragment rendered={!!importJson}>
                        <Divider/>

                        <div className="flex w-full field align-items-center">
                            <label htmlFor="segmentInput" className="mr-5 mb-0 flex-0 font-bold w-8rem">{t('importDialog.segment.label')}</label>

                            <Dropdown id="segmentInput" className="flex-1" value={currentSegment} onChange={onUpdateSegment}
                                      invalid={!segmentIsValid} options={segmentSelection}/>
                        </div>

                        <div className="flex w-full field align-items-center">
                            <label htmlFor="addressInput" className="mr-5 mb-0 flex-0 font-bold w-8rem">{t('importDialog.address.label')}</label>
                            <InputText id="addressInput" value={currentAddress}
                                       onChange={(e) => setAddressInput(e.target.value)}
                                       className="flex-1" disabled={loading}
                                       invalid={!!addressQueryError}/>
                            <Button id="setAddressButton" label={t('importDialog.address.submit')} icon="pi pi-pencil"
                                    onClick={onUpdateAddress} className="flex-0 ml-3"
                                    disabled={loading}
                                    loading={loading} loadingIcon="pi pi-spin pi-spinner"/>
                        </div>

                        <I18nErrorMessage errorObject={addressQueryError}/>

                        <UiFragment rendered={!!importAddress}>
                            <div className="flex w-full align-items-center">
                                <div className="mr-5 mb-0 flex-0 w-8rem"></div>
                                <AddressCard addressMeta={importAddress} className="flex-1"/>
                            </div>
                        </UiFragment>
                    </UiFragment>

                </TabPanel>
                <TabPanel key="json" header={t('importDialog.tab.json')} leftIcon="pi pi-code mr-3">
                    TBD
                </TabPanel>
            </TabView>

        </Dialog>
    )
}
