import {useTranslation} from "react-i18next";
import {Button} from "primereact/button";
import {PrimeIcons} from "primereact/api";
import {useSelector} from "react-redux";
import {selectDarkTheme} from "../redux/themeSlice";
import {selectAddressData, selectAddressSelected} from "../redux/addressSlice";
import {matchPath, useLocation, useNavigate} from "react-router-dom";
import {addAddressQuery} from "../router";
import {
    checkPermissionRule,
    PERMISSION_RULE_LOCATION,
    PERMISSION_RULE_MARKET,
    PERMISSION_RULE_VALUE
} from "../model/permissions";
import {selectPrivileges} from "../redux/authSlice";
import {useRef} from "react";
import MissingPermissionTooltip from "../component/MissingPermissionTooltip";
import {selectAnyValueAvailable} from "../redux/valueIndicationSlice";


/**
 * @typedef {object} NavBarItem
 * @property {string} navKey
 * @property {string} labelI18n
 * @property {string} icon
 * @property {boolean} [addressRequired]
 * @property {boolean} [disabled]
 * @property {PermissionRule} [permissionsRequired]
 * @property {(state) => boolean} [availableSelector]
 * @property {string} [route]
 */

/**
 * @type {NavBarItem[]}
 */
const NAV_ITEMS = [
    {
        navKey: 'address',
        labelI18n: 'navigation.address',
        route: "/address",
        icon: PrimeIcons.SEARCH,
        addressRequired: false
    },
    {
        navKey: 'location',
        labelI18n: 'navigation.location',
        route: "/location",
        icon: PrimeIcons.MAP_MARKER,
        addressRequired: true,
        permissionsRequired: PERMISSION_RULE_LOCATION
    },
    {
        navKey: 'market',
        labelI18n: 'navigation.market',
        route: "/market",
        icon: PrimeIcons.CHART_LINE,
        addressRequired: true,
        permissionsRequired: PERMISSION_RULE_MARKET
    },
    {
        navKey: 'object',
        labelI18n: 'navigation.object',
        route: "/object",
        icon: PrimeIcons.BUILDING,
        addressRequired: true,
    },
    {
        navKey: 'value',
        labelI18n: 'navigation.value',
        route: "/value",
        icon: PrimeIcons.EURO,
        addressRequired: true,
        permissionsRequired: PERMISSION_RULE_VALUE,
        availableSelector: selectAnyValueAvailable
    }
];

/**
 * NavBar item.
 * @param {NavBarItem} item
 * @constructor
 */
function SidebarItem({item}) {

    const {t} = useTranslation();

    const navigate = useNavigate();
    const {pathname} = useLocation();

    const lockedButtonRef = useRef(null);

    const addressSelected = useSelector(selectAddressSelected);
    const addressData = useSelector(selectAddressData);

    const privileges = useSelector(selectPrivileges);

    const available = useSelector(item.availableSelector || (state => true));

    const {navKey, labelI18n, icon, route, addressRequired, permissionsRequired} = item;
    const matchesRoute = !!matchPath(route + "/*", pathname);
    const disabled = !route || (addressRequired && !addressSelected) || !available;
    const active = !disabled && matchesRoute;
    const routeWithParams = route + addAddressQuery(addressData);

    const permitted = checkPermissionRule(privileges, permissionsRequired);

    if (!permitted && !disabled) {
        return (
            <>
                <MissingPermissionTooltip target={lockedButtonRef} position="right"/>
                <Button
                    ref={lockedButtonRef}
                    key={navKey}
                    label={t(labelI18n)}
                    icon={PrimeIcons.LOCK}
                    severity="secondary"
                    className="w-full px-1 py-3 text-xs border-noround border-none shadow-none text-400"
                    iconPos="top"
                />
            </>
        );
    }

    return (
        <Button
            key={navKey}
            label={t(labelI18n)}
            icon={icon}
            severity={active ? 'primary' : 'secondary'}
            disabled={disabled}
            onClick={() => navigate(routeWithParams)}
            className="w-full px-1 py-3 text-xs border-noround border-none shadow-none"
            iconPos="top"
        />
    );
}

/**
 * Sidebar/Nav-Bar.
 * @return {JSX.Element} Sidebar element
 * @constructor
 */
export function Sidebar() {
    const isDarkTheme = useSelector(selectDarkTheme);

    return (
        <nav className={"AppSidebar flex-grow-0 flex-shrink-0 w-5rem pt-5 mr-5 " + (isDarkTheme ? "surface-100" : "surface-700")}>
            {NAV_ITEMS.map(item => (
                <SidebarItem key={item.navKey} item={item} />
            ))}
        </nav>
    );
}