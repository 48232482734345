/*
 * Utilities for Maps.
 */


import {Circle as GeomCircle} from 'ol/geom';
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import TileWMSSource from "ol/source/TileWMS";
import WMTS, {optionsFromCapabilities} from "ol/source/WMTS";
import {fromLonLat, get as getProjection} from "ol/proj";
import VectorSource from "ol/source/Vector";
import {Feature, View} from "ol";
import {GeoJSON, WMTSCapabilities} from "ol/format";
import Point from "ol/geom/Point";
import {Fill, Icon, Stroke, Style, Text} from "ol/style";
import markerHouse from "../images/marker_haus.png";

import {Vector as VectorLayer} from "ol/layer";
import proj4 from "proj4";
import {register as registerProj4} from "ol/proj/proj4";
import {loadGeoserverTileAsync} from "../redux/geoserverThunk";
import {isCurrentEnvironmentInDevelopMode} from "../environment";
import {FALLBACK_ICON, POIS_CATEGORY_TO_ICON} from "./pois";

/** @typedef {import("ol").Map} OLMap */
/** @typedef {import("ol").View} OLView */
/** @typedef {import("ol/coordinate").Coordinate} OLCoordinate */
/** @typedef {import("ol/layer/Layer").Layer} OLLayer */
/** @typedef {import("ol/Collection").Collection} OLCollection */
/** @typedef {import("ol/format").GeoJSON} GeoJSON */
/** @typedef {import("ol-layerswitcher/dist/ol-layerswitcher").Options} LayerSwitcherOptions */

/**
 * @typedef OpenLayersCoords
 * @property {number} lat
 * @property {number} lon
 */

/**
 * @typedef {OpenLayersCoords} MapView
 * @property {number} zoom
 * @property {boolean} [resetCenterOnChange]
 * @property {boolean} [resetCenterOnZoomChange]
 * @property {Extent} [extentToFit]
 * @property {boolean} [fixedZoom]
 */

/**
 * @typedef {"OSM" | "Vector" | "Omniscale" | "Geoserver" | "GeoJSON" | "Circle"} MapLayerType
 */

/**
 * @typedef {object} MapLayer
 * @property {MapLayerType} layerType type of layer
 * @property {string} [key] key to identify the layer
 * @property {CommonLayerOptions | OmniscaleLayerOptions | GeoserverLayerOptions | VectorLayerOptions} [options] additional configuration options for the layer
 */

/**
 * @typedef {object} CommonLayerOptions
 * @property {string|null} [title] title (shown in layer switcher)
 * @property {boolean|null} [visible] if set to false, hide the layer by default
 * @property {"base"|null} [type] type of layer (used in layer switcher) - "base" layers are shown switched with radio button
 */

/**
 * @typedef {CommonLayerOptions} OmniscaleLayerOptions
 * @property {string} omniscaleKey API Key for Omniscale
 * @property {string} [layers] layers in Omniscale
 */

/**
 * @typedef {CommonLayerOptions} GeoserverLayerOptions
 * @property {string} layer REVA layer to show
 * @property {"WMS"|"WMTS"} sourceType Geoserver Source type (WMS or WMTS)
 * @property {SelectAddressData|null} [address] queried REVA address, which is transmitted to AVM-API for bookkeeping
 * @property {string} [title] optional title of layer
 * @property {number} [opacity] optional opacity (between 0 and 1 - default is 1)
 * @property {string} [style] style (if not provided, default style is used
 * @property {object} [params] additional params for geoserver
 */

/**
 * @typedef {OpenLayersCoords} VectorLayerMarker
 * @property {string} name
 */

/**
 * @typedef {CommonLayerOptions} VectorLayerOptions
 * @property {OpenLayersCoords[]} [markers]
 * @property {boolean} [highlighted]
 */

/**
 * @typedef {CommonLayerOptions} GeoJSONOptions
 * @property {object} [markers]
 * @property {string} [vehicle]
 * @property {number[]} [highlightedMarkers]
 */

/**
 * @typedef {MapModelEventhandlers} MapModelEventhandlers
 * @property {(object)=>void} hover_feature_event type of layer
 * @property {(object)=>void} fade_out_event type of layer
 * @property {(object)=>void} click_event type of layer
 */

/**
 * @typedef MapModel
 * @property {MapView} view
 * @property {MapLayer[]} layers
 * @property {MapModelLayerSwitcher|null} [switcher]
 * @property {MapModelEventhandlers|null} eventHandlers
 */

/**
 * @typedef MapModelLayerSwitcher
 * @property {boolean} enabled is the layer switcher enabled/visible?
 * @property {LayerSwitcherOptions|null} [options] the options for the layer switcher
 */

/**
 * WGS 84 / Pseudo-Mercator -- Spherical Mercator, Google Maps, OpenStreetMap, Bing, ArcGIS, ESRI
 */
export const EPSG_3857 = 'EPSG:3857';
/** Proj4 Definition for EPSG:3857
 *  https://epsg.io/3857
 * */
const EPSG_3857_PROJ4 = '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +axis=neu +no_defs +type=crs';
/** Gauss-Krueger Coordinate System */
export const EPSG_31467 = 'EPSG:31467';
/** Proj4 Definition for EPSG:31467 */
const EPSG_31467_PROJ4 = '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=bessel +datum=potsdam +units=m +no_defs';

export function registerCRSs() {
    proj4.defs(EPSG_31467, EPSG_31467_PROJ4);
    registerProj4(proj4);
    proj4.defs(EPSG_3857, EPSG_3857_PROJ4);
    registerProj4(proj4);

    //https://osgeo-org.atlassian.net/browse/GEOS-10902
    logMapDebug('Invert axis orientation for EPSG 3857 (see: https://osgeo-org.atlassian.net/browse/GEOS-10902)')
    const projEPSG3857 = getProjection(EPSG_3857);
    projEPSG3857.axisOrientation_ = 'neu';
}

const ATTR_KEY = "reva_key";
const ATTR_OPTIONS = "reva_options";

// Debugger flag (if set to false, suppresses all map debug outputs)
export const DEBUG_MAPS = true;


/**
 * Wrapper for Map debug logs.
 */
export function logMapDebug() {
    if (DEBUG_MAPS && isCurrentEnvironmentInDevelopMode()) {
        console.debug.apply(this, arguments);
    }
}

/**
 * Configurator for a layer type.
 */
class LayerConfigurator {
    /**
     * @param {MapLayerType} layerType handled layer type
     */
    constructor(layerType) {
        this.layerType = layerType;
    }

    /**
     * Create new layer of implemented type
     * @param {object} [options] layer options
     * @param {Dispatch} dispatch Redux dispatcher for API loading
     * @return {OLLayer} created layer
     */
    createLayer(options, dispatch) {
        throw new Error("createLayer() must be implemented by " + this.layerType);
    }

    /**
     * Update an existing layer, which was already assigned to the map.
     * @param {OLLayer} existingLayer already existing layer
     * @param {object} options layer options
     * @param {Dispatch} dispatch Redux dispatcher for API loading
     * @return {OLLayer|null} a new created layer, or null if the change could be applied in-place
     */
    updateLayer(existingLayer, options, dispatch) {
        // Default behaviour: discard old layer, create a new one
        return this.createLayer(options, dispatch);
    }
}

class OSMLayerConfigurator extends LayerConfigurator {
    constructor() {
        super("OSM");
    }

    /**
     * @param {CommonLayerOptions} options
     * @param {Dispatch} dispatch
     */
    createLayer(options, dispatch) {
        if (!options) {
            options = {};
        }
        return new TileLayer({
            source: new OSM(
                {attributions: '© <a target="_blank" href="https://www.openstreetmap.org/copyright">OSM</a>'}
            ), // native OSM source from OpenLayers,
            title: options.title,
            type: options.type,
            visible: (options.visible !== false)
        });
    }
}

class OmniscaleLayerConfigurator extends LayerConfigurator {
    constructor() {
        super("Omniscale");
    }

    // TODO: OmniScale Attribution
    static ATTRIBUTION = '© <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>';

    /**
     * @param {OmniscaleLayerOptions} options
     * @param {Dispatch} dispatch
     */
    createLayer(options, dispatch) {
        if (!options) {
            options = {};
        }
        const layers = options.layers || "osm";
        const omniscaleKey = options.omniscaleKey;
        if (!omniscaleKey) {
            throw new Error("Missing omniscaleKey!");
        }
        return new TileLayer({
            title: options.title,
            type: options.type,
            visible: (options.visible !== false),
            transitionEffect: 'resize',
            source: new TileWMSSource({
                attributions: OmniscaleLayerConfigurator.ATTRIBUTION,
                url: 'https://maps.omniscale.net/v2/' + omniscaleKey + '/style.default/map',
                projection: EPSG_31467,
                params: {
                    LAYERS: layers,
                    SRS: EPSG_31467,
                }
            })
        });
    }
}

class GeoserverLayerConfigurator extends LayerConfigurator {
    constructor() {
        super("Geoserver");
    }

    static ATTRIBUTION = '© <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>';

    /**
     * @param {GeoserverLayerOptions} options
     * @param {Dispatch} dispatch
     */
    createLayer(options, dispatch) {
        if (!options) {
            options = {};
        }
        const layer = options.layer;
        if (!layer) {
            throw new Error("Missing layer name!");
        }
        let params = {
            VERSION: '1.1.1',
            SRS: EPSG_3857,
            FORMAT: 'image/png',
            FORMAT_OPTIONS: 'antialiasing:on',
            TRANSPARENT: true,
            STYLES: options.style
        };
        if (options.sourceType === "WMS") {
            params.LAYERS = layer;
        }
        if (typeof options.params === 'object' && options.params) {
            params = {...params, ...options.params};
        }
        if (typeof options.address === 'object') {
            const addressParams = {
                REVA_LAT: options.address.lat,
                REVA_LON: options.address.lon,
                REVA_ADDRESS: options.address.addressQuery,
            }
            params = {...params, ...addressParams};
        }
        const source = this._createWmsSource(params, dispatch);
        return new TileLayer({
            title: options.title,
            type: options.type,
            visible: (options.visible !== false),
            opacity: (typeof options.opacity === 'number') ? options.opacity : undefined,
            transitionEffect: 'resize',
            source: source
        });
    }

    /**
     * Create WMS layers source.
     * @param {object} params
     * @param {Dispatch} dispatch
     */
    _createWmsSource(params, dispatch) {
        return new TileWMSSource({
            // attributions: GeoserverLayerConfigurator.ATTRIBUTION,
            attributions: params.attributions,
            url: /* relative to API url */ "/reva/gs/wms",
            serverType: 'geoserver',
            /* We need to override the default load method of OpenLayers WMS, because it does not support authentication out fo the box */
            tileLoadFunction: (tile, src) => dispatch(loadGeoserverTileAsync(tile, src)),
            params: params
        })
    }
}

class GeoserverWmtsLayerConfigurator extends LayerConfigurator {
    constructor() {
        super("GeoserverWMTS");
    }

    /**
     * Create WMTS layers source.
     * @param {string} capabilities
     * @param {string} layer
     * @param {string} matrixset
     * @param {object} params
     * @param {Dispatch} dispatch
     */
    _createWmtsSource(capabilities, layer, matrixset, params, dispatch) {
        const parsedCapabilities = new WMTSCapabilities().read(capabilities);
        const fromCapabilities = optionsFromCapabilities(parsedCapabilities, {
            layer: layer,
            matrixSet: matrixset,
        });
        // Expected WMTS Request:
        // https://geoserver.value-marktdaten.de:444/geoserver/gwc/service/wmts?layer=reva%3Awohnlagen_mikro_decorated&style=&tilematrixset=WebMercatorQuad
        // &Service=WMTS&Request=GetTile&Version=1.0.0&Format=image%2Fpng&TileMatrix=16&TileCol=40000&TileRow=23000
        fromCapabilities.tileLoadFunction = (tile, src) => dispatch(loadGeoserverTileAsync(tile, src));
        fromCapabilities.urls = ['/reva/gs/wmts']
        fromCapabilities.attributions = params.attributions || GeoserverLayerConfigurator.ATTRIBUTION
        // Hinweise: Geoserver vertauscht X und Y Koordinaten in den Capabilities von EPSG:3857:
        // Wurde bereits einmalig (global) in Funktion registerCRSs() eingestellt!
        return new WMTS(fromCapabilities);
    }

    /**
     * Create WMTS layers source.
     * @param options
     * @param {Dispatch} dispatch
     */
    createLayer(options, dispatch) {
        if (!options) {
            options = {};
        }
        const layer = options.layer;
        if (!layer) {
            throw new Error("Missing layer name!");
        }

        const matrixset = options.matrixset;
        if (!matrixset) {
            throw new Error(`Missing matrixset for layer ${layer}!`);
        }

        const capabilities = options.capabilities;
        if (!capabilities) {
            throw new Error(`Missing capabilities for layer:${layer}`);
        }

        logMapDebug(`Loaded Capabilities for ${layer} with matrix set ${matrixset}`, capabilities)
        let params = {
            FORMAT: 'image/png',
            STYLES: options.style
        };
        if (typeof options.params === 'object' && options.params) {
            params = {...params, ...options.params};
        }
        if (typeof options.address === 'object') {
            const addressParams = {
                REVA_LAT: options.address.lat,
                REVA_LON: options.address.lon,
                REVA_ADDRESS: options.address.addressQuery,
            }
            params = {...params, ...addressParams};
        }
        const source = this._createWmtsSource(capabilities, layer, matrixset, params, dispatch);
        /* We need to override the default load method of OpenLayers WMS, because it does not support authentication out fo the box */
        return new TileLayer({
            title: options.title,
            type: options.type,
            visible: (options.visible !== false),
            opacity: (typeof options.opacity === 'number') ? options.opacity : undefined,
            source: source
        });
    }
}

class VectorLayerConfigurator extends LayerConfigurator {
    constructor() {
        super("Vector");
    }

    /**
     * @param {number} scale scale of the marker
     * @return {Style}
     */
    static markerStyle = (scale) => new Style({
        image: new Icon({
            anchorXUnits: 'pixels',
            anchorYUnits: 'pixels',
            anchor: [10, 32],
            src: markerHouse,
            scale: scale,
        })
    });

    /**
     * @param {VectorLayerMarker} md
     * @param {number} scale
     */
    static convertMarkerDataToPoint(md, scale) {
        const feature = new Feature({
            geometry: new Point(fromLonLat([md.lon, md.lat], EPSG_3857)),
            name: md.name
        });
        feature.setStyle(VectorLayerConfigurator.markerStyle(scale));
        feature.setId('marker_point');
        return feature;
    }

    static textStyle = (md) => new Style({
        text: new Text({
            text: md.radius + 'm',
            font: 'bold 12px Calibri,sans-serif',
            fill: new Fill({
                color: 'black',
            }),
            stroke: new Stroke({
                color: 'white',
                width: 2,
            }),
        }),
    });

    static convertMarkerDataToText(md) {
        if (!md.radius)
            return [];
        const center = fromLonLat([md.lon, md.lat], EPSG_3857);
        const iconFeatures =
            [
                new Feature({geometry: new Point([center[0], center[1] - md.radius])}),
                new Feature({geometry: new Point([center[0], center[1] + md.radius])}),
                new Feature({geometry: new Point([center[0] - md.radius, center[1]])}),
                new Feature({geometry: new Point([center[0] + md.radius, center[1]])}),
            ]
        ;
        iconFeatures.forEach(f => f.setStyle(VectorLayerConfigurator.textStyle(md)));
        return iconFeatures;
    }

    /**
     * Convert markers to vector features
     * @param {VectorLayerMarker[]} md
     * @param {boolean} [highlighted]
     * @return {Feature<Point>[]}
     */
    static convertMarkerDataToFeatures(md, highlighted) {
        if (!md)
            return [];
        const features = md.map(
            (md) => {
                if (!md.radius) {
                    return this.convertMarkerDataToPoint(md, highlighted === true ? 1.6 : 1.0);
                }
                return this.convertMarkerDataToCircle(md);
            }
        );
        const items = md.flatMap(md => this.convertMarkerDataToText(md)).filter(md => md !== undefined);
        return features.concat(items);
    }

    static circleStyle = new Style({
        fill: null,
        stroke: new Stroke({color: "purple", width: 2}),
    });

    /**
     * Convert markers to circle Features
     * @param {VectorLayerMarker[]} md
     * @return {Feature<Point>[]}
     */
    static convertMarkerDataToCircle(md) {
        const circleFeature = new Feature({
            geometry: new GeomCircle(
                fromLonLat([md.lon, md.lat], EPSG_3857), md.radius),
        });
        circleFeature.setId('marker_circle_' + md.radius);
        circleFeature.setStyle(VectorLayerConfigurator.circleStyle);
        return circleFeature;
    }


    /**
     * @param {VectorLayerOptions} options
     * @param {Dispatch} dispatch
     */
    createLayer(options, dispatch) {
        if (!options) {
            options = {};
        }
        const markers = options.markers;
        const highlighted = options.highlighted;
        const features = VectorLayerConfigurator.convertMarkerDataToFeatures(markers, highlighted);
        return new VectorLayer({
            source: new VectorSource({
                projection: EPSG_3857,
                features: features
            }),
        })
    }
}

class GeoJSONLayerConfigurator extends LayerConfigurator {
    constructor() {
        super("GeoJSON");
    }

    /**
     * @param {GeoJSONOptions} options
     * @param {Dispatch} dispatch
     */
    createLayer(options, dispatch) {
        if (!options) {
            options = {};
        }
        //deep copy to omit mutating options
        let markers = JSON.parse(JSON.stringify(options.markers));
        const highlighted = options.highlightedMarkers ? options.highlightedMarkers.filter(m => -1 < m && m < markers.features.length) : [];
        const highlightedKategories = markers.features.map(f => f.properties.type_en).filter((k, i) => highlighted.includes(i))
        markers.features = markers.features.sort((a, b) => {
            if (highlighted.size === 0) {
                return 0;
            }
            //Sortiere zur Darstellung PoIs nach hinten, wenn diese gehighlighted werden sollen
            const ahighlighted = highlightedKategories.includes(a.properties.type_en);
            const bhighlighted = highlightedKategories.includes(b.properties.type_en);
            if (ahighlighted && !bhighlighted) {
                return 1;
            } else if (!ahighlighted && bhighlighted) {
                return -1;
            } else {
                return 0;
            }
        })
        if (options.highlightedMarkers.length > 0)
            logMapDebug("Creating GeoJSON Layer with highlights:", options.highlightedMarkers, options.markers.features.map(f => f.properties.type_en.toLowerCase()), highlightedKategories)

        const baseImage = (feature) => {
            const kategorie_en = feature.get('type_en');
            const opacity = highlighted.length === 0 || highlightedKategories.includes(kategorie_en) ? 1 : 0.5;
            const scale = highlighted.length === 0 || !highlightedKategories.includes(kategorie_en) ? 1 : 1.5;
            const icon = POIS_CATEGORY_TO_ICON[kategorie_en.toLowerCase()];
            const src = !kategorie_en || !icon ? FALLBACK_ICON : icon
            return new Icon({
                anchorXUnits: 'pixels',
                anchorYUnits: 'pixels',
                anchor: [10, 32],
                src: src,
                opacity: opacity,
                scale: scale
            })
        }

        const vectorSource = new VectorSource({
            features: new GeoJSON().readFeatures(markers,
                {
                    dataProjection: "EPSG:4326",
                    featureProjection: EPSG_3857
                }
            ),
        });

        return new VectorLayer({
            source: vectorSource,
            style: feature => {
                const image = baseImage(feature);
                const style = new Style({image: image});
                return style;
            }
        })
    }

    updateLayer(existingLayer, options, dispatch) {
        if (options.vehicle !== existingLayer[ATTR_OPTIONS].vehicle)
            return this.createLayer(options, dispatch);
        // if (options.highlightedMarkers !== existingLayer[ATTR_OPTIONS].highlightedMarkers)
        //     return this.createLayer(options, dispatch);
        const keyMapper = (os) => new Set(os.markers.map(m => m.key + m.geometry))
        if (keyMapper(options) !== keyMapper(existingLayer[ATTR_OPTIONS])) {
            return this.createLayer(options, dispatch);
        }
        return null;
    }
}

/** @type {LayerConfigurator[]} */
const LAYER_CONFIGURATORS = [
    new OSMLayerConfigurator(),
    new OmniscaleLayerConfigurator(),
    new GeoserverLayerConfigurator(),
    new GeoserverWmtsLayerConfigurator(),
    new VectorLayerConfigurator(),
    new GeoJSONLayerConfigurator()
];

/** @type {Map<MapLayerType, LayerConfigurator>} */
const LAYER_CONFIGURATORS_BY_TYPE = new Map(
    LAYER_CONFIGURATORS.map(lc => [lc.layerType, lc]));

/**
 * Validate a Map LayerDev, and check if required attributes are provided.
 * @param {MapLayer} layerDef
 * @return {LayerConfigurator} layer configurator if layer is valid, null if layer is invalid or has no configurator
 * @private
 */
function _findLayerConfigurator(layerDef) {
    if (!layerDef) {
        console.warn("Empty layer");
        return null;
    }
    if (!layerDef.key) {
        console.warn("Empty key in ", layerDef);
        return null;
    }
    if (!layerDef.layerType) {
        console.warn("Empty layerType in ", layerDef);
        return null;
    }
    const configurator = LAYER_CONFIGURATORS_BY_TYPE.get(layerDef.layerType);
    if (!configurator) {
        console.warn(`No Layer configurator for ${layerDef.layerType}`);
        return null;
    }
    return configurator;
}

/**
 * Create a single layer for map.
 * @param {MapLayer} layerDef
 * @param {Dispatch} dispatch Redux dispatcher for API loading
 * @return {OLLayer|null}
 * @private
 */
function _createLayer(layerDef, dispatch) {
    const configurator = _findLayerConfigurator(layerDef);
    if (!configurator) {
        return null;
    }
    /** @type {OLLayer} */
    let layer = null;
    try {
        layer = configurator.createLayer(layerDef.options, dispatch);
    } catch (e) {
        console.error(`Error creating layer ${JSON.stringify(layerDef)}`, e);
        return null;
    }
    if (!layer) {
        console.error(`Error creating layer ${JSON.stringify(layerDef)} (result is empty)`);
        return null;
    }
    layer[ATTR_KEY] = layerDef.key;
    layer[ATTR_OPTIONS] = JSON.stringify(layerDef.options);
    return layer;
}

/**
 * Update a single layer in the map.
 * @param {OLLayer} existingLayer
 * @param {MapLayer} layerDef
 * @param {Dispatch} dispatch Redux dispatcher for API loading
 * @return {OLLayer|null} updated layer, or null if the update was done in-place
 * @private
 */
function _updateLayer(existingLayer, layerDef, dispatch) {
    const configurator = _findLayerConfigurator(layerDef);
    if (!configurator) {
        return null;
    }
    try {
        const options = layerDef.options;
        const updatedLayer = configurator.updateLayer(existingLayer, options, dispatch);
        if (updatedLayer) {
            // new layer was created
            updatedLayer[ATTR_KEY] = layerDef.key;
            updatedLayer[ATTR_OPTIONS] = JSON.stringify(options);
        } else {
            // existing layer was updated
            existingLayer[ATTR_OPTIONS] = JSON.stringify(options);
        }
        return updatedLayer;
    } catch (e) {
        console.error(`Error updating layer ${layerDef.key}`, e);
        return null;
    }
}

/**
 * Add layers to (empty) Map.
 * @param {OLMap} map
 * @param {Dispatch} dispatch Redux dispatcher for API loading
 * @param {MapLayer[]} layers
 */
export function createLayers(map, dispatch, layers) {
    if (!Array.isArray(layers))
        return;

    logMapDebug('Adding all layers to map', map);
    for (const layerDef of layers) {
        const layer = _createLayer(layerDef, dispatch);
        if (!layer)
            continue;
        logMapDebug(`Adding ${layerDef.layerType} layer (key=${layerDef.key}): ${JSON.stringify(layerDef.options)}`, layerDef)
        map.addLayer(layer);
    }
    map[ATTR_OPTIONS] = JSON.stringify(layers);
    logMapDebug('All layers finished', map);
}

/**
 * Compare configured layer of Map.
 * If the layer configuration diverges, clear all existing layers, and create all anew.
 * @param {OLMap} map
 * @param {Dispatch} dispatch Redux dispatcher for API loading
 * @param {MapLayer[]} layers
 * @return {boolean} true, if layers changed
 */
export function updateLayers(map, dispatch, layers) {
    if (!map)
        return false;
    logMapDebug('Updating all layers of map', map);
    if (map[ATTR_OPTIONS] === JSON.stringify(layers)) {
        // No options differ - nothing to update
        logMapDebug("All layers are in sync.")
        return false;
    }

    const existingKeys = map.getAllLayers().map(layer => layer[ATTR_KEY]);
    const updateKeys = layers.map(layerDef => layerDef.key);
    if (JSON.stringify(existingKeys) !== JSON.stringify(updateKeys)) {
        // Layer keys differ - the map was updated fundamentally
        logMapDebug(`Layers are not in sync. Clearing existing ${map.getLayers().getLength()} layers ...`)
        map.getLayers().clear();
        createLayers(map, dispatch, layers);
        return true;
    }

    // Layer keys are the same, but some layers options differ.
    // Only update those layers
    logMapDebug(`Layers are not in sync. Updating layers with new options ...`)
    for (let index = 0; index < layers.length; index++) {
        const layerDef = layers[index];
        const optionsJson = JSON.stringify(layerDef.options);
        const existingLayer = map.getLayers().item(index);
        if (optionsJson === existingLayer[ATTR_OPTIONS]) {
            logMapDebug(`Layer ${layerDef.key} is unchanged`);
            continue;
        }
        logMapDebug(`Layer ${layerDef.key} needs updating:`);
        const updatedLayer = _updateLayer(existingLayer, layerDef, dispatch);
        if (!updatedLayer) {
            logMapDebug(`Layer ${layerDef.key} was updated in-place`)
        } else {
            map.removeLayer(existingLayer);
            existingLayer.dispose();
            map.getLayers().insertAt(index, updatedLayer);
            logMapDebug(`Layer ${layerDef.key} was updated and replaced in map`)
        }
    }
    map[ATTR_OPTIONS] = JSON.stringify(layers);
    return true;
}

/**
 * Format center of view options for comparison.
 * @param {MapView} viewOptions
 * @return {[number, number]}
 */
function viewCenterCmp(viewOptions) {
    return [viewOptions.lat, viewOptions.lon];
}

/**
 * Transform center of view options in EPSG:3857.
 * @param {MapView} viewOptions
 * @return {OLCoordinate}
 */
function viewCenterEpsg3857(viewOptions) {
    return fromLonLat([viewOptions.lon, viewOptions.lat], EPSG_3857);
}

/**
 * Create new OpenLayers view.
 * @param {MapView} viewOptions
 * @return {OLView}
 */
export function createView(viewOptions) {
    logMapDebug(`Creating view (Lat/Lon = ${viewOptions.lat}/${viewOptions.lon}, Zoom = ${viewOptions.zoom})`)
    const view = new View({
        projection: EPSG_3857,
        center: viewCenterEpsg3857(viewOptions),
        zoom: viewOptions.zoom,
        minZoom: Math.min(8, viewOptions.zoom),
        maxZoom: 20
    });
    view[ATTR_OPTIONS] = JSON.stringify(viewCenterCmp(viewOptions));
    logMapDebug("Created OpenLayers view", view);
    return view;
}

/**
 * Update view of OpenLayers map.
 * @param {OLMap} map
 * @param {MapView} viewOptions
 * @param {boolean} layersChanged
 */
export function updateView(map, viewOptions, layersChanged) {
    if (!map)
        return;
    logMapDebug(`Updating view (Lat/Lon = ${viewOptions.lat}/${viewOptions.lon}, Zoom = ${viewOptions.zoom})`)
    let zoomChanged = false;
    // TODO: Compare Zoom with attribute
    if (viewOptions.extentToFit) {
        logMapDebug(`Found fixed extent!`);
        logMapDebug(`Updating Extent: ${map.getView().getViewStateAndExtent().extent} --> ${viewOptions.extentToFit}`);
        const extent = viewOptions.extentToFit;
        if (JSON.stringify(extent) === JSON.stringify([0, 0, 0, 0])) {
            zoomChanged = false;
        } else if (extent) {
            const view = map.getView();
            view.fit(extent, {size: map.getSize()});
            delete viewOptions.extentToFit;
        }
    } else if (viewOptions.zoom !== map.getView().getZoom()) {
        logMapDebug(`Updating Zoom: ${map.getView().getZoom()} --> ${viewOptions.zoom}`);
        map.getView().setZoom(viewOptions.zoom);
        zoomChanged = true
    } else {
        logMapDebug("Zoom already in sync");
    }
    const centerChangeRequired = (map.getView()[ATTR_OPTIONS] !== JSON.stringify(viewCenterCmp(viewOptions)))
        || (viewOptions.resetCenterOnZoomChange && zoomChanged)
        || (viewOptions.resetCenterOnChange && (layersChanged || zoomChanged));
    if (centerChangeRequired) {
        logMapDebug(`Updating Center: ${map.getView()[ATTR_OPTIONS]} --> ${JSON.stringify(viewCenterCmp(viewOptions))}`);
        map.getView().setCenter(viewCenterEpsg3857(viewOptions));
    } else {
        logMapDebug("Center already in sync");
    }
}

export class LayerFactory {

    /**
     * Create default OSM layer.
     * @param {string} [key] optional key
     * @param {CommonLayerOptions} [commonOptions] common options
     * @return {MapLayer}
     */
    static createOSMLayer(key, commonOptions) {
        return {
            layerType: "OSM",
            key: key || "osm",
            options: commonOptions
        };
    }

    /**
     * Create Vector layer with marker(s).
     * @param {VectorLayerMarker|VectorLayerMarker[]|GeoRefAddress|GeoRefAddress[]} markers one or multiple markers and/or addresses
     * @param {i18n} i18n Internationalization object (for language deduction)
     * @param {string} [key] optional key
     * @param {boolean} [highlighted] optional flag if markers should be highlighted
     * @return {MapLayer}
     */
    static createMarkerLayer(markers, i18n, key, highlighted) {
        if (!Array.isArray(markers)) {
            if (typeof markers === 'object')
                markers = [markers];
            else
                markers = [];
        }
        /** @type {VectorLayerMarker[]} */
        const convertedMarkers = markers.map(marker => {
            if (marker.lat && marker.lon) {
                if (marker.displayNameEN && marker.displayNameDE) {
                    // Is GeoRef Address
                    return {
                        lat: marker.lat,
                        lon: marker.lon,
                        name: (i18n.language === 'en' ? marker.displayNameEN : marker.displayNameDE)
                    }
                }
                // Valid marker object
                return marker;
            }
            console.warn(`Given marker is not valid: ${JSON.stringify(marker)}`);
            return null;
        }).filter(marker => !!marker);
        return {
            layerType: "Vector",
            key: key || "markers",
            options: {
                markers: convertedMarkers,
                highlighted: highlighted
            }
        }
    }

    /**
     * Create Circle layer with marker(s).
     * @param {VectorLayerMarker|VectorLayerMarker[]|GeoRefAddress|GeoRefAddress[]} markers one or multiple markers and/or addresses
     * @param {number[]} radius array of radius values
     * @param {i18n} i18n Internationalization object (for language deduction)
     * @param {string} [key] optional key
     * @return {MapLayer}
     */
    static createCircleLayer(markers, radius, i18n, key) {
        if (!Array.isArray(markers)) {
            if (typeof markers === 'object')
                markers = [markers];
            else
                markers = [];
        }
        if (!Array.isArray(radius)) {
            if (typeof radius === 'object')
                radius = [radius];
            else
                radius = [];
        }
        const convertedMarkers = markers.flatMap(marker => {
            return radius.map(r => {
                    if (marker.lat && marker.lon) {
                        // Is GeoRef Address
                        return {
                            lat: marker.lat,
                            lon: marker.lon,
                            name: r + 'm',
                            radius: r
                        }
                    }
                    console.warn(`Given marker is not valid: ${JSON.stringify(marker)}`);
                    return null;
                }
            )
        }).filter(marker => !!marker);
        return {
            layerType: "Vector",
            key: key || "circle",
            options: {
                markers: convertedMarkers,
                highlighted: 0
            }
        }
    }

    /**
     * Create GeoJSON layer with PoIs as marker(s).
     * @param {string|null} vehicle optional key
     * @param {Object} pois one or multiple markers and/or addresses
     * @param {string} [key] optional key
     * @param {number[]} [highlightedMarkerIndexes] optional array for highlighted PoIs
     * @return {MapLayer}
     */
    static createPoisLayer(vehicle, pois, highlightedMarkerIndexes, key) {
        return {
            layerType: "GeoJSON",
            key: key || "pois",
            options: {
                vehicle: vehicle,
                markers: pois,
                highlightedMarkers: highlightedMarkerIndexes
            }
        }
    }

    /**
     * Create geoserver WMS layer.
     * @param {string} layer Geoserver layer to use
     * @param {SelectAddressData|null} address queried REVA address, which is transmitted to AVM-API for bookkeeping
     * @param {string|undefined} [style] optional style in geoserver
     * @param {string} [key] optional key
     * @param {number|undefined} [opacity] optional opacity
     * @param {CommonLayerOptions} [commonOptions] common options
     * @return {MapLayer}
     */
    static createGeoserverWmsLayer(layer, address, style, key, opacity, commonOptions) {
        return this.createGeoserverLayer(layer, "WMS", address, style, key, opacity, commonOptions);
    }

    /**
     * Create geoserver WMTS layer.
     * @param {string} layer Geoserver layer to use
     * @param {SelectAddressData|null} address queried REVA address, which is transmitted to AVM-API for bookkeeping
     * @param {string|undefined} [style] optional style in geoserver
     * @param {string} [key] optional key
     * @param {number|undefined} [opacity] optional opacity
     * @param {CommonLayerOptions} [commonOptions] common options
     * @return {MapLayer}
     */
    static createGeoserverWmtsLayer(layer, address, style, key, opacity, commonOptions) {
        return {
            layerType: "GeoserverWMTS",
            key: key || ("geoserver_wmts_" + layer),
            options: {
                address: address,
                style: style || undefined,
                layer: layer,
                sourceType: "WMTS",
                opacity: opacity || undefined,
                ...(commonOptions || {})
            }
        };
    }

    /**
     * Create geoserver layer.
     * @param {string} layer Geoserver layer to use
     * @param {"WMS"|"WMTS"} sourceType Geoserver source type (WMS or WMTS)
     * @param {SelectAddressData|null} address queried REVA address, which is transmitted to AVM-API for bookkeeping
     * @param {string|undefined} [style] optional style in geoserver
     * @param {string} [key] optional key
     * @param {number|undefined} [opacity] optional opacity
     * @param {CommonLayerOptions} [commonOptions] common options
     * @return {MapLayer}
     */
    static createGeoserverLayer(layer, sourceType, address, style, key, opacity, commonOptions) {
        return {
            layerType: "Geoserver",
            key: key || ("geoserver_" + layer),
            options: {
                address: address,
                style: style || undefined,
                layer: layer,
                sourceType: sourceType,
                opacity: opacity || undefined,
                ...(commonOptions || {})
            }
        }
    }

}
