import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectSpec} from "../../redux/specSlice";
import {aggregateIndicationTableData} from "../../model/SpecModel";
import {selectByLanguage} from "../../util/language";

/**
 * Table with rendered data.
 * @param {RevaSpecOutputColumn[]} specColumns columns to render
 * @param {ValueIndicationDataRow[]} rows rendered rows
 * @param {boolean} fullsize flag, if the table should be rendered over the full width (no inforation panel visible)
 * @return {React.ReactNode}
 * @constructor
 */
function TableWithData({specColumns, rows, fullsize}) {

    const { i18n} = useTranslation();

    const headerTemplate = (data) => {
        return (
            <div className="flex align-items-center gap-2 mt-3">
                <span className="font-bold text-xl">{data.group}</span>
            </div>
        );
    };

    const rowClassName = (data) => {
        return {
            "font-bold": data.important,
            "row-underline": data.underline
        }
    };

    const className = fullsize ? "col-12" : "col-12 xl:col-9 pr-5";

    return (
        <DataTable value={rows} rowGroupMode="subheader" groupRowsBy="group" rowClassName={rowClassName}
                   rowGroupHeaderTemplate={headerTemplate} size="small" stripedRows
                   className={className}>
            <Column field="title" header=""/>
            {specColumns.map(col => (
                <Column key={col.field} field={col.field} header={selectByLanguage(i18n.language, col.headerDE, col.headerEN)}
                        className="p-align-right" headerClassName="p-align-right"/>
            ))}
        </DataTable>
    );
}

/**
 * Info sidebar.
 * @param {string[]} infoOutput info items
 * @return {React.ReactNode|null}
 * @constructor
 */
function InfoData({infoOutput}) {

    if (!infoOutput || !infoOutput.length)
        return null;

    return (
        <>
            <div className="col-12 xl:col-3 border-round surface-100 p-3">
                <span className="font-bold text-xl">Information</span>
                <ul>
                    {infoOutput.map((item) => (<li key={item}>{item}</li>))}
                </ul>
            </div>
        </>
    )
}

/**
 * Table showing an indication details result.
 * @param {RevaIndicationEndpoint} endpoint
 * @param {object} indicationDetails result details for endpoint
 * @return {React.ReactNode|null}
 * @constructor
 */
function IndicationTable({endpoint, indicationDetails}) {

    const { i18n} = useTranslation();
    const spec = useSelector(selectSpec);

    if (!endpoint || !indicationDetails || !spec || !spec.pages || !spec.pages[endpoint]) {
        return null;
    }

    const renderedRows = aggregateIndicationTableData(i18n.language, spec, endpoint, indicationDetails);

    const info = selectByLanguage(i18n.language, indicationDetails["info_info_de"], indicationDetails["info_info"]);
    let infoOutput = [];
    if (typeof info === "string") {
        infoOutput = info.split("/").map(item => item.trim()).filter(item => !!item);
    }

    return (
        <div className="flex-1 grid">
            <TableWithData rows={renderedRows} specColumns={spec.pages[endpoint].columns} fullsize={!infoOutput.length}/>
            <InfoData infoOutput={infoOutput}/>
        </div>
    );
}

export default IndicationTable;