import {createSlice} from "@reduxjs/toolkit";
import {selectCredentials, selectPrivilegePois} from "./authSlice";
import {ErrorI18n} from "../i18n";
import {selectAddressData} from "./addressSlice";
import {FIELD_POIS, LocationInformationModel} from "../model/LocationInformationModel";

import {groupAndFindMinimum} from "../util/pois";

/** @typedef {import("ol/format").GeoJSON} GeoJSON */

/**
 * @typedef PoisSlice
 * @property {PoisInfoData} data
 */

/**
 * @typedef PoisInfoData
 * @property {LocationInfoAddress} address
 * @property {GeoJSON} pois
 * @property {Object} groupedPois
 * @property {string[]} types
 */

// TODO: cancel old PoisInfo response, after concurrent new PoisInfo request is sent
export const poisSlice = createSlice({
    name: 'pois',
    initialState: {
        /** @type {boolean} */
        loading: false,
        /** @type {boolean} */
        missingPermissions: false,
        /** @type {PoisInfoData|null} */
        data: null,
        /** @type {ApiQueryError|null} */
        queryError: null,
    },
    reducers: {
        clearPoisInfo: (state) => {
            state.loading = false;
            state.missingPermissions = false;
            state.pois = null;
            state.queryError = null;
        },
        setLoading: (state) => {
            state.loading = true;
            state.missingPermissions = false;
            state.pois = null;
            state.queryError = null;
        },
        setMissingPermissions: (state) => {
            state.loading = false;
            state.missingPermissions = true;
            state.pois = null;
            state.queryError = null;
        },
        setQueryError: (state, action) => {
            const {error, errorArgs, hasContactLink} = action.payload;
            state.loading = false;
            state.missingPermissions = false;
            state.pois = null;
            /** @type {ApiQueryError} */
            state.queryError = {error, errorArgs: errorArgs || null, hasContactLink: hasContactLink || false};
        },
        setData: (state, action) => {
            const {pois, address} = action.payload;
            state.loading = false;
            state.missingPermissions = false;
            const vehicleMinFeatures = groupAndFindMinimum(pois.features);
            state.pois = {
                data: {
                    pois: pois,
                    groupedPois: vehicleMinFeatures.features,
                    address: address,
                    types: vehicleMinFeatures.types
                }
            }
            state.queryError = null;
        }
    },
    selectors: {
        /** @return {boolean} */
        selectLoading: (state) => state.loading,
        /** @return {boolean} */
        selectMissingPermissions: (state) => state.missingPermissions,
        /** @return {PoisInfoData|null} pois data, or null if no pois loaded */
        selectPoiData: (state) => state.pois?.data,
        /** @return {ApiQueryError|null} query error data, or null if no error */
        selectError: (state) => state.queryError
    }
})

export const {
    clearPoisInfo,
    setLoading,
    setMissingPermissions,
    setQueryError,
    setData
} = poisSlice.actions

export const {
    selectLoading,
    selectMissingPermissions,
    selectPoiData,
    selectError
} = poisSlice.selectors;

/**
 * Async thunk to run PoisInfo request.
 * @return {RevaThunkAction<void>}
 */
export const queryPoisInfoAsync = () => async (dispatch, getState, extraArgument) => {
    const {env, serviceApi} = extraArgument;
    // TODO: ObjectId?, Accounting?
    const addressMeta = selectAddressData(getState());
    if (!addressMeta) {
        return;
    }
    if (!selectPrivilegePois(getState())) {
        if (env.isDevelopmentMode) {
            console.log("Skipping loading of LocationInfo-Pois due to missing permissions");
        }
        dispatch(setMissingPermissions());
        return;
    }
    const locationInfoModel = new LocationInformationModel(addressMeta.addressQuery, [addressMeta.lat,addressMeta.lon],null, null, [FIELD_POIS]);
    if (!locationInfoModel.validateAndDispatchError((error, errorArgs) => dispatch(setQueryError({error, errorArgs})))) {
        return;
    }
    dispatch(setLoading());
    const credentials = selectCredentials(getState());
    try {
        if (env.isDevelopmentMode) {
            console.log(`LocationInfo (POIs) query for address: ${addressMeta.addressQuery}`)
        }
        const apiResponsePromise = serviceApi.performLocationInformation(credentials, locationInfoModel);
        const {pois, address} = await locationInfoModel.handleResponse(apiResponsePromise);
        if (!pois) {
            dispatch(setQueryError({error: 'general.error.invalid', errorHasContactLink: true}));
            return;
        }
        dispatch(setData({pois, address}))
    } catch (err) {
        if (err instanceof ErrorI18n) {
            dispatch(setQueryError({error: err.message, errorArgs: {...err.messageArgs}, errorHasContactLink: true}));
        } else {
            dispatch(setQueryError({
                error: 'general.error.custom',
                errorArgs: {errorMessage: err.message},
                errorHasContactLink: true
            }));
        }
    }
}

export default poisSlice.reducer