import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectPrivileges, selectUsername} from "../redux/authSlice";
import {checkPermissionRule} from "../model/permissions";

/**
 * Indicator, that required permission was rejected.
 * @param {PermissionRule} permissionRule rule to check (option 1)
 * @param {boolean} missingPermissions flag if permission was rejected (option 2)
 * @param {JSX.Element} children
 * @return {JSX.Element} rendered indicator
 */
function MissingPermissionIndicator({permissionRule, missingPermissions, children}) {

    const {t} = useTranslation();

    const username = useSelector(selectUsername);
    const privileges = useSelector(selectPrivileges);


    if (permissionRule) {
        const permitted = checkPermissionRule(privileges, permissionRule);
        if (permitted) {
            return children; // Don't show permission error
        }
    }
    if (missingPermissions === false) {
        return children; // Don't show permission error
    }

    return (
        <div className="flex-grow-1 flex flex-column justify-content-center align-items-center">
            <div className="p-inline-message p-component p-inline-message-warn block">
                <div className="m-3 mb-5 text-2xl">
                    <span className="pi pi-lock text-2xl mr-3"></span>
                    {t("general.missingPermissionTitle")}
                </div>
                <p className="m-3">
                    {t('general.missingPermission1')}<strong>{username}</strong>{t('general.missingPermission2')}
                </p>
                <p className="m-3 mt-0">
                    {t('general.missingPermissionContact')}
                    <a href="https://www.value-marktdaten.de/contact" target="_blank" rel="noreferrer"
                       style={{color: "inherit"}}
                       className="p-button-label ml-2 text-war">{t('general.contact')}</a>
                </p>
            </div>
        </div>
    );
}

export default MissingPermissionIndicator;