import {createSlice} from "@reduxjs/toolkit";
import {FIELD_TIMELINES, LocationInformationModel} from "../model/LocationInformationModel";
import {selectCredentials, selectPrivilegeTimelines} from "./authSlice";
import {ErrorI18n} from "../i18n";
import {selectAddressData} from "./addressSlice";
import {aggregateTimelineData} from "../model/TimeLinesModel";

/**
 * @typedef LocationInfoData
 * @property {LocationInfoTimelineData} timelines
 * @property {LocationInfoAddress} address
 * @property {AggregatedTimelineData} timelinesAggregated
 */

// TODO: cancel old LocationInfo response, after concurrent new LocationInfo request is sent

export const locationInfoSlice = createSlice({
    name: 'locationInfo',
    initialState: {
        /** @type {boolean} */
        loading: false,
        /** @type {boolean} */
        missingPermissions: false,
        /** @type {LocationInfoData|null} */
        locationInfoData: null,
        /** @type {ApiQueryError|null} */
        queryError: null
    },
    reducers: {
        clearLocationInfo: (state) => {
            state.loading = false;
            state.missingPermissions = false;
            state.locationInfoData = null;
            state.queryError = null;
        },
        setLoading: (state) => {
            state.loading = true;
            state.missingPermissions = false;
            state.locationInfoData = null;
            state.queryError = null;
        },
        setMissingPermissions: (state) => {
            state.loading = false;
            state.missingPermissions = true;
            state.locationInfoData = null;
            state.queryError = null;
        },
        setQueryError: (state, action) => {
            const {error, errorArgs, hasContactLink} = action.payload;
            state.loading = false;
            state.missingPermissions = false;
            state.locationInfoData = null;
            /** @type {ApiQueryError} */
            state.queryError = {error, errorArgs: errorArgs || null, hasContactLink: hasContactLink || false};
        },
        setLocationInfoData: (state, action) => {
            const {timelines, address} = action.payload;
            const timelinesAggregated = aggregateTimelineData(timelines);
            state.loading = false;
            state.missingPermissions = false;
            /** @type {LocationInfoData} */
            state.locationInfoData = {timelines, address, timelinesAggregated};
            state.queryError = null;
        }
    },
    selectors: {
        /** @return {boolean} */
        selectLoading: (state) => state.loading,
        /** @return {boolean} */
        selectMissingPermissions: (state) => state.missingPermissions,
        /** @return {LocationInfoTimelineData|null} timeline data, or null if no timeline loaded */
        selectTimelineData: (state) => state.locationInfoData?.timelines,
        /** @return {AggregatedTimelineData|null} aggregated timeline data, or null if no timeline loaded */
        selectTimelineDataAggregated: (state) => state.locationInfoData?.timelinesAggregated,
        /** @return {LocationInfoAddress|null} selected address data, or null if no selected address */
        selectAddress: (state) => state.locationInfoData?.address,
        /** @return {ApiQueryError|null} query error data, or null if no error */
        selectError: (state) => state.queryError
    }
})

export const {
    clearLocationInfo,
    setLoading,
    setMissingPermissions,
    setQueryError,
    setLocationInfoData
} = locationInfoSlice.actions

export const {
    selectLoading,
    selectMissingPermissions,
    selectTimelineData,
    selectTimelineDataAggregated,
    selectAddress,
    selectError
} = locationInfoSlice.selectors;

/**
 * Async thunk to run LocationInfo request.
 * @return {RevaThunkAction<void>}
 */
export const queryLocationInfoAsync = () => async (dispatch, getState, extraArgument) => {
    const {env, serviceApi} = extraArgument;
    // TODO: ObjectId?, Accounting?
    const addressData = selectAddressData(getState());
    if (!addressData) {
        return;
    }
    if (!selectPrivilegeTimelines(getState())) {
        if (env.isDevelopmentMode) {
            console.log("Skipping loading of LocationInfo-Timelines due to missing permissions");
        }
        dispatch(setMissingPermissions());
        return;
    }
    const locationInfoModel = new LocationInformationModel(addressData.addressQuery,[addressData.lat,addressData.lon], null, null, [FIELD_TIMELINES]);
    if (!locationInfoModel.validateAndDispatchError((error, errorArgs) => dispatch(setQueryError({error, errorArgs})))) {
        return;
    }
    dispatch(setLoading());
    const credentials = selectCredentials(getState());
    try {
        if (env.isDevelopmentMode) {
            console.log(`LocationInfo (Timelines) query for address: ${addressData}`)
        }
        const apiResponsePromise = serviceApi.performLocationInformation(credentials, locationInfoModel);
        const {timelines, address} = await locationInfoModel.handleResponse(apiResponsePromise);
        if (!timelines || !address) {
            dispatch(setQueryError({error: 'general.error.invalid', errorHasContactLink: true}));
            return;
        }
        dispatch(setLocationInfoData({timelines, address}))
    } catch (err) {
        if (err instanceof ErrorI18n) {
            dispatch(setQueryError({error: err.message, errorArgs: {...err.messageArgs}, errorHasContactLink: true}));
        } else {
            dispatch(setQueryError({error: 'general.error.custom', errorArgs: {errorMessage: err.message}, errorHasContactLink: true}));
        }
    }
}

export default locationInfoSlice.reducer