import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {selectAddressData, selectInitialized} from "../redux/addressSlice";
import {clearIndicationData, selectLoading} from "../redux/valueIndicationSlice";
import {selectDevelopMode} from "../redux/envSlice";
import {useEffect, useRef, useState} from "react";
import {queryValueIndicationWithCurrentParamsAsync, selectCurrentIndicationPayload} from "../redux/indicationThunk";
import {addAddressQueryFromLocation} from "../router";
import {resetObjectParameters} from "../redux/objectParametersSlice";
import {Toast} from "primereact/toast";
import {Tooltip} from "primereact/tooltip";
import {Button} from "primereact/button";
import {ConfirmPopup} from "primereact/confirmpopup";
import ObjectInputs from "./object/ObjectInputs";

function PageObject() {

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();

    const addressData = useSelector(selectAddressData);
    const addressInitialized = useSelector(selectInitialized)

    const isDevelop = useSelector(selectDevelopMode);

    const indicationLoading = useSelector(selectLoading);

    const resetButton = useRef(null);
    const [confirmResetVisible, setConfirmResetVisible] = useState(false);

    const toastRef = useRef(null);

    const debugObj = useSelector(selectCurrentIndicationPayload);
    const debugJson = JSON.stringify(debugObj, null, 4);

    // Redirect to address page, if this page is shown without a valid address selected
    useEffect(() => {
        if (addressInitialized && !addressData) {
            if (isDevelop) {
                console.log("No valid address selected: redirect to address page");
            }
            navigate("/address" + addAddressQueryFromLocation(location));
        }
    }, [addressInitialized, addressData, navigate, location, isDevelop]);

    const onReset = () => {
        dispatch(resetObjectParameters());
        dispatch(clearIndicationData())
    }

    const onCalculate = () => {
        dispatch(queryValueIndicationWithCurrentParamsAsync());
    }

    const onJsonCopy = async () => {
        try {
            await navigator.clipboard.writeText(debugJson);
            toastRef.current.show({ severity: 'info', summary: 'Info', detail: t('object.copiedJsonToClipboard') });
        } catch (e) {
            console.warn("Could not copy JSON to clipboard", e);
        }
    }

    return (
        <main role="main" className="flex-grow-1 pt-3 px-4 py-4 w-12">
            <div className="flex flex-column w-full h-full">
                <h2 className="flex-0 flex mt-0">
                    <div className="flex-1 HeaderText">{t('object.objectParameters')}:</div>
                    <div className="HeaderToolbarRight">

                        <Toast ref={toastRef} />

                        <Tooltip target="#valueJsonInfo" position="left" autoHide="false">
                            <div>AVM JSON request:</div>
                            <pre>{debugJson}</pre>
                            <div><i>(Press button to copy to clipboard)</i></div>
                        </Tooltip>

                        <Button id="valueJsonInfo" icon="pi pi-code" onClick={onJsonCopy} text/>

                        <ConfirmPopup target={resetButton.current} visible={confirmResetVisible}
                                      icon="pi pi-exclamation-triangle"
                                      onHide={() => setConfirmResetVisible(false)}
                                      accept={onReset}
                                      message={t('object.actions.confirmReset')}
                                      acceptClassName="p-button-danger"
                                      acceptLabel={t('general.confirm.yes')}
                                      rejectLabel={t('general.confirm.no')}/>
                        <Button icon="pi pi-trash" tooltip={t('object.actions.reset')} text
                                tooltipOptions={{position: 'left'}} className="ml-1" severity="danger"
                                onClick={() => setConfirmResetVisible(true)} ref={resetButton}/>

                        <Button icon="pi pi-play" label={t('object.actions.calculate')} className="ml-3"
                                loading={indicationLoading} loadingIcon="pi pi-spin pi-spinner"
                                onClick={onCalculate}/>
                    </div>
                </h2>
                <ObjectInputs/>
            </div>
        </main>
    );
}

export default PageObject;