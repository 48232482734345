import {GeneralModel} from "./GeneralModel";
import {ValidationErrorI18n} from "./Validation";
import {WebError} from "../api";
import {ErrorI18n} from "../i18n";
import ApiParamBuilder from "../util/queryParams";

export class WmtsCapabilitiesModel extends GeneralModel {
    /**
     * Create WmtsCapabilities model.
     * @param {string} layer requested layer
     * @param {string} matrixset tilematrixset of the layer
     */
    constructor(layer, matrixset) {
        super()
        this.layer = layer;
        this.matrixset = matrixset;
    }

    validate() {
    }

    /**
     * Handle and validate response data.
     * @param apiResponsePromise {Promise<object>} response promise from API call
     * @return {Promise<string>} Promise containing result from the Capabilities query
     */
    async handleResponse(apiResponsePromise) {
        try {
            const capabilitiesResponse = await apiResponsePromise;
            return capabilitiesResponse.text();
        } catch (e) {
            if (e instanceof WebError) {
                switch (e.status) {
                    case 404: throw new ErrorI18n('general.error.404');
                    case 500: throw new ErrorI18n('general.error.unspecific');
                    default:
                }
                if (e.getCustomErrorMessage()) {
                    throw new ErrorI18n('general.error.custom', {errorMessage: e.getCustomErrorMessage()});
                }
            }
            throw new ErrorI18n('general.error.invalid');
        }
    }

    /**
     * Get additional Query Parameters.
     * @return {string} request query parameters
     */
    getQueryParameters() {
        //https://geoserver.value-marktdaten.de:444/geoserver/gwc/service/wmts?layer=reva%3Awohnlagen_mikro_decorated&style=&tilematrixset=WebMercatorQuad&Service=WMTS&Request=GetCapabilities
        return new ApiParamBuilder()
            .addParam("layer", this.layer)
            .addParam("tilematrixset", this.matrixset)
            .addParam("service", "wmts")
            .addParam("request", "GetCapabilities")
            .build()
    }

}