
// Helper function to group and return minimum duration and path_length
import railway_stop_marker from "../images/railway_stop.svg";
import tram_stop_marker from "../images/tram_stop.svg";
import airport_marker from "../images/airport.svg";
import motorway_link_marker from "../images/motorway_link.svg";
import hospital_marker from "../images/hospital.svg";
import doctors_marker from "../images/doctors.svg";
import pharmacy_marker from "../images/pharmacy.svg";
import school_marker from "../images/kindergarten.svg";
import kindergarten_marker from "../images/kindergarten.svg";
import bus_stop_marker from "../images/bus_stop.svg";
import supermarket_marker from "../images/supermarket.svg";
import mall_marker from "../images/mall.svg";
import bakery_marker from "../images/bakery.svg";
import bank_marker from "../images/bank.svg";
import cafe_marker from "../images/cafe.svg";
import coordinates_marker from "../images/coordinates.svg";
import scale_marker from "../images/scale.svg";
import embassy_marker from "../images/embassy.svg";
import firefighter_marker from "../images/firefighter.svg";
import map_marker_marker from "../images/map_marker.svg";
import package_delivery_marker from "../images/package_delivery.svg";
import park_marker from "../images/park.svg";
import playground_marker from "../images/playground.svg";
import police_marker from "../images/police.svg";
import post_office_marker from "../images/post_office.svg";
import prison_marker from "../images/prison.svg";
import restaurant_marker from "../images/restaurant.svg";
import theater_marker from "../images/theater.svg";
import social_institution_marker from "../images/social_institution.svg";
import sport_marker from "../images/sport.svg";
import waterway_marker from "../images/waterway.svg";

export function groupAndFindMinimum(features) {
    const vehicleTypes = ['bike', 'car', 'foot'];
    let groupedFeatures = {};
    let featureTypes = new Set();

    features.forEach(feature => {
        vehicleTypes.forEach(vehicle => {
            const type = feature.properties.type_en.replaceAll(' ', '_').toLowerCase();
            featureTypes.add(type);
            const vehicleTypeCombinations = `${vehicle}_${type}`;
            const durationKey = `${vehicle}_duration_min`;
            const pathKey = `${vehicle}_path_length_m`;
            const isFirstEntry = !groupedFeatures.hasOwnProperty(vehicleTypeCombinations);
            if (isFirstEntry) {
                groupedFeatures[vehicleTypeCombinations] = {
                    type: 'Feature',
                    geometry: feature.geometry,
                    properties: feature.properties
                };
                return;
            }
            const isDurationLess = feature["properties"][durationKey] < groupedFeatures[vehicleTypeCombinations]["properties"][durationKey];
            if (isDurationLess) {
                groupedFeatures[vehicleTypeCombinations] = {
                    type: 'Feature',
                    geometry: feature.geometry,
                    properties: feature.properties
                };
                return;
            }


            const isPathShorter = (feature["properties"][durationKey] === groupedFeatures[vehicleTypeCombinations]["properties"][durationKey]) && feature["properties"][pathKey] < groupedFeatures[vehicleTypeCombinations]["properties"][pathKey];
            if (isPathShorter) {
                groupedFeatures[vehicleTypeCombinations] = {
                    type: 'Feature',
                    geometry: feature.geometry,
                    properties: feature.properties
                };
            }
        })
    });
    return {types: [...featureTypes], features: groupedFeatures};
}

export const FALLBACK_ICON = coordinates_marker

export const POIS_CATEGORY_TO_ICON = {
    'railway station':railway_stop_marker,
    'subway station':tram_stop_marker,
    'tram stop':tram_stop_marker,
    'aeroport':airport_marker,
    'light rail station':tram_stop_marker,
    'mainline station':railway_stop_marker,
    'motorway link':motorway_link_marker,

    'hospital':hospital_marker,
    'doctor':doctors_marker,
    'pharmacie':pharmacy_marker,
    'kindergarten':kindergarten_marker,
    'bus stop':bus_stop_marker,
    'school with unknown isced-level':school_marker,
    'university':school_marker,
    'primary school':school_marker,
    'secondary school':school_marker,
    'mixed school':school_marker,

    'supermarket':supermarket_marker,
    'mall':mall_marker,
    'shop':mall_marker,
    'bakery/butchery':bakery_marker,
    'bank':bank_marker,
    'community centre':theater_marker,
    'leisure':cafe_marker,

    'administrative':scale_marker,
    'courthouse':scale_marker,
    'embassy':embassy_marker,
    'fire station':firefighter_marker,
    'map_marker':map_marker_marker,
    'package_delivery':package_delivery_marker,
    'park':park_marker,
    'playground':playground_marker,
    'police station':police_marker,
    'post office':post_office_marker,
    'prison':prison_marker,
    'gastronomy':restaurant_marker,
    'other cultural institutions':theater_marker,
    'place of worship':theater_marker,
    'waterway':waterway_marker,
    'fließende gewässer':waterway_marker,
    'stehende gewässer':waterway_marker,
    'agriculture':park_marker,
    'forest':park_marker,
    'social facility': social_institution_marker,
    'sport': sport_marker

}
