import {useEffect, useLayoutEffect, useRef} from "react";
import {useDispatch} from "react-redux";

import {Map} from "ol";
import LayerSwitcher from "ol-layerswitcher";
import {OverlayPanel} from 'primereact/overlaypanel';
import OpenLayersLegendControl from "./OpenLayersLegendControl";

import {createLayers, createView, logMapDebug, registerCRSs, updateLayers, updateView} from "../util/maps";
import {useTranslation} from "react-i18next";

// Register EPSG:31467 and EPSG:3857 once on loading of this module
registerCRSs();

/**
 * React Hook to use a OL Map.
 * @param {MutableRefObject<HTMLDivElement|null>} mapDivRef ref to the DIV containing the map
 * @param {MapModel} mapModel map model describing the properties of the map
 * @param {MutableRefObject<OverlayPanel|null>|null} legendOverlayRef ref to the legend content, or null for no reference
 * @return {MutableRefObject<Map|null>} reference for map
 */
function useOpenLayersMap(mapDivRef, mapModel, legendOverlayRef) {
    /** @type {MutableRefObject<Map|null>} */
    const mapRef = useRef(null);
    /** @type {MutableRefObject<OpenLayersLegendControl|null>} */
    const legendControlRef = useRef(null);

    const {t, i18n} = useTranslation();

    const dispatch = useDispatch();


    /**
     * @param {Map} map
     * @param {MapBrowserEventHandler} dispatch
     */
    function registerMapEvents(map, eventHandlers) {
        if (!eventHandlers) {
            return
        }
        if (eventHandlers.hover_feature_event) {
            console.info("Registering Movein event ", eventHandlers.hover_feature_event);
            map.on('pointermove', evt =>  eventHandlers.hover_feature_event(map, evt));
        }
        if (eventHandlers.click_event) {
            console.info("Registering Click event ", eventHandlers.click_event);
            map.on('click', evt => eventHandlers.click_event(map, evt))
        }
        if (eventHandlers.fade_out_event) {
            console.info("Registering Fade out event ", eventHandlers.fade_out_event);
            map.getTargetElement().addEventListener('pointerleave', () => eventHandlers.fade_out_event(map))
        }
    }

    useEffect(() => {
        if (mapModel) {
            if (!mapRef.current) {
                // Create new map
                const map = new Map({
                    target: mapDivRef.current || undefined,
                    layers: [],
                    view: createView(mapModel.view)
                });


                createLayers(map, dispatch, mapModel.layers);
                if (mapModel.switcher?.enabled) {
                    const layerSwitcher = new LayerSwitcher(mapModel.switcher.options || {});
                    map.addControl(layerSwitcher);
                }
                if (legendOverlayRef) {
                    const buttonLabel = t("general.map.legend");
                    const legendControl = new OpenLayersLegendControl({legendOverlayRef, buttonLabel});
                    legendControlRef.current = legendControl;
                    map.addControl(legendControl);
                }
                registerMapEvents(map, mapModel.eventHandlers);
                mapRef.current = map;
                logMapDebug(`Created new Map (target=${mapDivRef.current}):`, map)
            } else {
                // Update map
                const map = mapRef.current;
                const layersChanged = updateLayers(map, dispatch, mapModel.layers);
                if(layersChanged || !mapModel.view.fixedZoom)
                    updateView(map, mapModel.view, layersChanged);
                logMapDebug(`Updated Map (target=${mapDivRef.current}):`, map)
            }
        }

        return () => {
            if (mapRef.current && mapRef.current.getTarget() && !mapDivRef.current) {
                logMapDebug("Clearing target of", mapRef.current);
                mapRef.current.setTarget(null);
            }
        };
    }, [mapDivRef, mapModel]);

    useEffect(() => {
        if (legendControlRef.current) {
            const buttonLabel = t("general.map.legend");
            legendControlRef.current.updateButtonLabel(buttonLabel);
        }
    }, [i18n.language, legendControlRef]);

    useLayoutEffect(() => {
        if (mapRef.current && mapDivRef.current && mapRef.current.getTarget() !== mapDivRef.current) {
            mapRef.current.setTarget(mapDivRef.current);
            logMapDebug(`Setting map target to ${mapDivRef.current}`)
        }
    }, [mapDivRef]);

    return mapRef;
}

/**
 * OpenLayer Map component.
 * @param {MapModel|null} model the map model describing the layers
 * @param {JSX.Element|null} legend the (optional) map legend element
 * @return {JSX.Element|null}
 * @constructor
 */
function OpenLayersMap({model, legend}) {

    /** @type {MutableRefObject<HTMLDivElement|null>} */
    const mapDivRef = useRef(null);
    /** @type {MutableRefObject<OverlayPanel|null>} */
    const legendOverlayRef = useRef(null);

    useOpenLayersMap(mapDivRef, model, legend ? legendOverlayRef : null);

    if (!model)
        return null;

    return (
        <div ref={mapDivRef} className="OpenLayersMap">
            <OverlayPanel ref={legendOverlayRef} className="OpenLayersLegendOverlay">{legend}</OverlayPanel>
        </div>
    );
}

export default OpenLayersMap;