import AddressCard from "./AddressCard";

/**
 * Display of address details.
 * @param {SelectAddressData|GeoRefAddress|null} address selected address
 * @param {string} [className] optional addition CSS classes
 * @constructor
 */
export default function AddressDetails({address, className}) {
    if (!address)
        return null;

    let addressMeta = address;
    if (typeof addressMeta.addressMeta === "object") {
        addressMeta = addressMeta.addressMeta;
    }

    return (
        <AddressCard addressMeta={addressMeta} className={className}/>
    )
}