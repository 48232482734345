import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux'
import initStore from './redux/store'
import {PrimeReactProvider} from 'primereact/api';
import AppContainer from "./app/AppContainer";
import reportWebVitals from './reportWebVitals';
import {loadEnvironment} from "./environment";

import ServiceApi from "./api";

// PrimeReact / PrimeFlex, PrimeIcons
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.scss';
// PrimeReact theme imported directly in index.xhtml, with theme switcher abilities:
// https://primereact.org/theming/#switchthemes

// OpenLayers
import "ol/ol.css";
import 'ol-layerswitcher/dist/ol-layerswitcher.css';

// App Stylesheet
import './style/App.scss';

// import i18n (needs to be bundled)
import {initializeI18n} from "./i18n";

import {initializeEnvironment} from "./redux/envSlice";
import {initializeThemeAsync} from "./redux/themeSlice";
import {tryPerformInitialLogin} from "./redux/loginSlice";

// TODO: PrimeReact locale
//  https://primereact.org/configuration/#Locale

const root = ReactDOM.createRoot(document.getElementById('root'));

loadEnvironment().then(async (env) => {

    // Initialize Service API
    const serviceApi = new ServiceApi(env.apiUrl);

    // Initialize Redux Store
    const store = initStore(env, serviceApi);

    // Initialize I18n
    await initializeI18n(env);

    // Initialize Store
    await store.dispatch(initializeEnvironment({appEnvironment: env}));
    await store.dispatch(initializeThemeAsync());

    // Try to auto-login (in the background), if a valid session token was stored
    store.dispatch(tryPerformInitialLogin());

    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <PrimeReactProvider>
                    <AppContainer/>
                </PrimeReactProvider>
            </Provider>
        </React.StrictMode>
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
