import {TabView} from 'primereact/tabview';
import {useTranslation} from "react-i18next";
import {useEffect, useRef, useState} from "react";
import {matchPath, Outlet, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectAddressData, selectInitialized} from "../redux/addressSlice";
import {selectDevelopMode} from "../redux/envSlice";
import {addAddressQuery, addAddressQueryFromLocation} from "../router";
import {getTabPanelWithPermission} from "../component/TabPanelWithPermission";
import {selectPrivileges} from "../redux/authSlice";
import {
    checkPermissionRule,
    PERMISSION_RULE_VALUE
} from "../model/permissions";
import MissingPermissionIndicator from "../component/MissingPermissionIndicator";
import {
    clearIndicationData, selectAnyValueAvailable,
    selectIndicationData,
    selectLoading
} from "../redux/valueIndicationSlice";
import {Button} from "primereact/button";
import {resetObjectParameters} from "../redux/objectParametersSlice";
import {ConfirmPopup} from "primereact/confirmpopup";
import {queryValueIndicationWithCurrentParamsAsync, selectCurrentIndicationPayload} from "../redux/indicationThunk";
import {Tooltip} from "primereact/tooltip";
import {Toast} from "primereact/toast";
import UiFragment from "../component/UiFragment";

/**
 * @typedef {TabHeaderWithPermissionParams} TabHeaderWithPermissionParamsForIndication
 * @property {(ValueIndicationDataOpt) => boolean} [disabledFn] dynamic disabled flag computation
 */

/** @type {TabHeaderWithPermissionParamsForIndication[]} */
const TABS = [
    {
        route: "income-value",
        headerI18n: "value.category.incomeValue",
        disabledFn: (indicationData) => !(indicationData?.income_value_details)
    },
    {
        route: "real-asset-value",
        headerI18n: "value.category.realAssetValue",
        disabledFn: (indicationData) => !(indicationData?.real_asset_value_details)
    },
    {
        route: "comparative-value",
        headerI18n: "value.category.comparativeValue",
        disabledFn: (indicationData) => !(indicationData?.comparative_value_details)
    }
]
const TAB_ROUTES = TABS.map(tab => tab.route);

function PageValue() {

    const {t} = useTranslation();

    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();
    const {pathname} = location;

    const addressData = useSelector(selectAddressData);
    const addressInitialized = useSelector(selectInitialized)

    const valueIndicationData = useSelector(selectIndicationData);

    const isDevelop = useSelector(selectDevelopMode);
    const privileges = useSelector(selectPrivileges);

    const indicationLoading = useSelector(selectLoading);

    const anyIndicationAvailable = useSelector(selectAnyValueAvailable);

    /** @type React.ReactElement[] */
    const tabs = TABS
        .map(tab => {
            const {route, headerI18n, disabledFn} = tab;
            let {disabled} = tab;
            if (typeof disabledFn === "function") {
                disabled = disabledFn(valueIndicationData)
            }
            return {route, headerI18n, disabled};
        })
        .map(tab => getTabPanelWithPermission(tab, t, privileges));

    // Redirect to address page, if this page is shown without a valid address selected
    useEffect(() => {
        if (addressInitialized && !addressData) {
            if (isDevelop) {
                console.log("No valid address selected: redirect to address page");
            }
            navigate("/address" + addAddressQueryFromLocation(location));
        }
    }, [addressInitialized, addressData, navigate, location, isDevelop]);

    let activeIndex = null;
    for (let index = 0; index < TAB_ROUTES.length; index++) {
        if (matchPath("/value/" + TAB_ROUTES[index], pathname)) {
            activeIndex = index;
            break;
        }
    }

    const onTabChange = (index) => {
        if (index >= 0 && index < TAB_ROUTES.length) {
            navigate("/value/" + TAB_ROUTES[index] + addAddressQuery(addressData));
        }
    };

    const onCalculate = () => {
        dispatch(queryValueIndicationWithCurrentParamsAsync());
    }

    const hasAnyPermissions = checkPermissionRule(privileges, PERMISSION_RULE_VALUE);
    if (!hasAnyPermissions) {
        return (<MissingPermissionIndicator missingPermissions={true} />)
    }

    return (
        <main role="main" className="flex-grow-1 pt-3 px-4 py-4 w-12">
            <div className="flex flex-column w-full h-full">
                <h2 className="flex-0 flex mt-0">
                    <div className="flex-0 HeaderTextShared">{t('value.valueIndication')}:</div>
                    <TabView className="flex-1 HeaderTabView" panelContainerClassName="hidden" activeIndex={activeIndex}
                             onTabChange={(e) => onTabChange(e.index)}>
                        {tabs}
                    </TabView>
                    <UiFragment rendered={anyIndicationAvailable}>
                        <div className="HeaderToolbarRight">

                            <Button icon="pi pi-play" label={t('value.actions.calculate')} className="ml-3"
                                    loading={indicationLoading} loadingIcon="pi pi-spin pi-spinner"
                                    onClick={onCalculate}/>
                        </div>
                    </UiFragment>
                </h2>
                <Outlet/>
            </div>
        </main>
    );
}

export default PageValue;