import {useTranslation} from "react-i18next";

/** @typedef {"de"|"en"} RevaLanguage */

/**
 * Select a value depending on the given language.
 * @template T type of values
 * @param {RevaLanguage|undefined} language the provided language (de is default)
 * @param {T} deOption option to select, if language is not "en"
 * @param {T} enOption option to select, if language is "en"
 * @return {T} return value
 */
export function selectByLanguage(language, deOption, enOption) {
    return (language === "en") ? enOption : deOption;
}

/**
 * @callback SelectByCurrentLanguage
 * @param {string} textDe Text in German/DE
 * @param {string} textEn Text in English/EN
 * @return {string} either textDe or textEn, depending on current language
 */

/**
 * React Hook to get a SelectByCurrentLanguage function for the currently set language.
 * @return {SelectByCurrentLanguage} translation function
 */
export function useSelectByCurrentLanguage() {
    const {i18n} = useTranslation();
    return (textDe, textEn) => selectByLanguage(i18n.language, textDe, textEn);
}