import {Control as OLControl} from 'ol/control';

/** @typedef {import("ol/control/Control").Options} ControlOptions */

/** @typedef {import("primereact/overlaypanel").OverlayPanel} OverlayPanel */

/**
 * @typedef {ControlOptions} LegendControlOptions
 * @property {MutableRefObject<OverlayPanel|null>} [legendOverlayRef]
 * @property {string} [buttonLabel]
 */

export default class OpenLayersLegendControl extends OLControl {

    /**
     * @param {LegendControlOptions} opt_options
     */
    constructor(opt_options) {
        const options = opt_options || {};

        const button = document.createElement('button');

        const element = document.createElement('div');
        element.className = 'ol-unselectable ol-control legend-button';
        element.appendChild(button);

        super({
            element: element,
            target: options.target
        });

        this.legendOverlayRef = opt_options.legendOverlayRef;
        this.button = button;

        const buttonLabel = opt_options.buttonLabel || "Legende";
        this.updateButtonLabel(buttonLabel)

        button.addEventListener('click', this.handleOverlayToggle.bind(this), false);
    }

    /**
     * @param {string} buttonLabel
     */
    updateButtonLabel(buttonLabel) {
        this.button.innerHTML = '<span class="pi pi-info-circle"></span> ' + buttonLabel;
    }

    handleOverlayToggle(e) {
        if (this.legendOverlayRef.current && typeof this.legendOverlayRef.current.toggle === 'function') {
            this.legendOverlayRef.current.toggle(e);
        }
    }

}