import {createSlice} from "@reduxjs/toolkit";
import {ImmoDropModel} from "../model/ImmoDropModel";
import {selectCredentials} from "./authSlice";
import {ErrorI18n} from "../i18n";
import {GeoRefModel} from "../model/GeoRefModel";
import {lockInDistinctAddress} from "./addressSlice";
import {setSegmentAndParameters} from "./objectParametersSlice";

export const importSlice = createSlice({
    name: 'import',
    initialState: {
        /** @type {string|null} Target URL for Immodrop */
        immodropUrl: null,
        /** @type {boolean} Is the ImmoDrop request currently loading? */
        immodropLoading: false,
        /** @type {ApiQueryError|null} The current query error from the last Immodrop request */
        immodropQueryError: null,

        /** @type {string|null} The address query for the import (extracted from either Immodrop or the importJson)  */
        addressQuery: null,
        /** @type {boolean} Is the Address geo-referencing currently loading? */
        addressLoading: false,
        /** @type {ApiQueryError|null} The current query error from geo-referencing the importAddress */
        addressError: null,

        /** @type {object|null} JSON object, containing the payload (in AVM-API format) to be imported. */
        importJson: null,
        /** @type {Segment|null} Segment to be imported */
        importSegment: null,
        /** @type {object|null} object parameters to be imported */
        importObjectParams: null,
        /** @type {GeoRefAddress|null} the found address meta to be imported */
        importAddress: null
    },
    reducers: {
        clear: (state) => {
            state.immodropUrl = null;
            state.immodropLoading = false;
            state.immodropQueryError = null;

            state.addressQuery = null;
            state.addressLoading = false;
            state.addressError = null;

            state.importJson = null;
            state.importSegment = null;
            state.importObjectParams = null;
            state.importAddress = null;
        },
        setImmodropLoading: (state, action) => {
            const {targetUrl} = action.payload;

            state.immodropUrl = targetUrl;
            state.immodropLoading = true;
            state.immodropQueryError = null;

            state.addressQuery = null;
            state.addressLoading = false;
            state.addressError = null;

            state.importJson = null;
            state.importSegment = null;
            state.importObjectParams = null;
            state.importAddress = null;
        },
        setImmodropError: (state, action) => {
            const {error, errorArgs, hasContactLink} = action.payload;

            state.immodropLoading = false;
            /** @type {ApiQueryError} */
            state.immodropQueryError = {error, errorArgs: errorArgs || null, hasContactLink: hasContactLink || false};
        },
        setImportJsonData: (state, action) => {
            const {importJson} = action.payload;

            const {address, segment, ...params} = (importJson || {});
            state.immodropLoading = false

            state.addressQuery = address || "";
            state.addressLoading = false;
            state.addressError = null;

            state.importJson = importJson;
            state.importSegment = segment;
            state.importObjectParams = params;
            state.importAddress = null;
        },
        setSegment: (state, action) => {
            const {segment} = action.payload;

            state.importSegment = segment;
        },
        setAddressLoading: (state, action) => {
            const {addressQuery} = action.payload;
            state.addressQuery = addressQuery || "";
            state.addressLoading = true;
            state.addressError = null;

            state.importAddress = null;
        },
        setAddressError: (state, action) => {
            const {error, errorArgs, hasContactLink} = action.payload;
            state.addressLoading = false;
            /** @type {ApiQueryError} */
            state.addressError = {error, errorArgs: errorArgs || null, hasContactLink: hasContactLink || false};

            state.importAddress = null;
        },
        setAddressFound: (state, action) => {
            const {addressMeta} = action.payload;
            state.addressLoading = false;
            state.addressError = null;

            state.importAddress = addressMeta;
        },
    },
    selectors: {
        /** @return {string|null} */
        selectImmodropUrl: (state) => state.immodropUrl,
        /** @return {boolean} */
        selectImmodropLoading: (state) => state.immodropLoading,
        /** @return {ApiQueryError|null} */
        selectImmodropQueryError: (state) => state.immodropQueryError,

        /** @return {string|null} */
        selectAddressQuery: (state) => state.addressQuery,
        /** @return {boolean} */
        selectAddressLoading: (state) => state.addressLoading,
        /** @return {ApiQueryError|null} */
        selectAddressError: (state) => state.addressError,

        /** @return {object|null} */
        selectImportJson: (state) => state.importJson,
        /** @return {Segment|null} */
        selectImportSegment: (state) => state.importSegment,
        /** @return {object|null} */
        selectImportObjectParams: (state) => state.importObjectParams,
        /** @return {GeoRefAddress|null} */
        selectImportAddress: (state) => state.importAddress,

        /** @return {boolean} */
        selectValid: (state) => {
            return state.importJson && state.importAddress && state.importObjectParams &&
                ["WHG_K", "EZFH_K", "MFHWGH_K", "GRST_K"].includes(state.importSegment);
        }
    }
});

export const {
    clear,
    setImmodropLoading,
    setImmodropError,
    setImportJsonData,
    setSegment,
    setAddressLoading,
    setAddressError,
    setAddressFound
} = importSlice.actions;

export const {
    selectImmodropUrl,
    selectImmodropLoading,
    selectImmodropQueryError,
    selectAddressQuery,
    selectAddressLoading,
    selectAddressError,
    selectImportJson,
    selectImportSegment,
    selectImportObjectParams,
    selectImportAddress,
    selectValid
} = importSlice.selectors;

export default importSlice.reducer;

/**
 * Async thunk to submit a URL to Immodrop.
 * @param {string} targetUrl target URL to send to ImmoDrop
 * @return {RevaThunkAction<void>}
 */
export const queryImmodropAsync = (targetUrl) => async (dispatch, getState, extraArgument) => {
    const {env, serviceApi} = extraArgument;
    dispatch(setImmodropLoading({targetUrl}));
    const immodropModel = new ImmoDropModel(targetUrl);
    if (!immodropModel.validateAndDispatchError((error, errorArgs) => dispatch(setImmodropError({error, errorArgs})))) {
        return;
    }
    const credentials = selectCredentials(getState());
    try {
        if (env.isDevelopmentMode) {
            console.log(`Immodrop query for url: ${targetUrl}`)
        }
        const apiResponsePromise = serviceApi.performImmodropExtraction(credentials, immodropModel);
        const importJson = await immodropModel.handleResponse(apiResponsePromise);
        dispatch(setImportJsonData({importJson}));
        if (env.isDevelopmentMode) {
            console.log(`Successful Immodrop response:`, importJson);
        }
        // Immediately start to geo-reference detected address ...
        const addressQuery = selectAddressQuery(getState());
        await dispatch(queryImportAddressAsync(addressQuery));
    } catch (err) {
        if (err instanceof ErrorI18n) {
            dispatch(setImmodropError({error: err.message, errorArgs: {...err.messageArgs}, errorHasContactLink: false}));
        } else {
            dispatch(setImmodropError({error: 'general.error.custom', errorArgs: {errorMessage: err.message}, errorHasContactLink: true}));
        }
    }
};

export const queryImportAddressAsync = (addressQuery) => async (dispatch, getState, extraArgument) => {
    const {env, serviceApi} = extraArgument;
    const geoRefModel = new GeoRefModel(addressQuery);
    if (!geoRefModel.validateAndDispatchError((error, errorArgs) => dispatch(setAddressError({error, errorArgs})))) {
        return;
    }
    dispatch(setAddressLoading({addressQuery}));
    const credentials = selectCredentials(getState());
    try {
        if (env.isDevelopmentMode) {
            console.log(`GeoRef query (during import) for address: ${addressQuery}`)
        }
        const apiResponsePromise = serviceApi.performGeoRef(credentials, geoRefModel);
        const {ambiguous, addressMeta} = await geoRefModel.handleResponse(apiResponsePromise);
        if (ambiguous) {
            dispatch(setAddressError({error: 'address.error.multipleFound', errorHasContactLink: false}));
        } else {
            dispatch(setAddressFound({addressMeta}));
        }
    } catch (err) {
        if (err instanceof ErrorI18n) {
            dispatch(setAddressError({error: err.message, errorArgs: {...err.messageArgs}, errorHasContactLink: true}));
        } else {
            dispatch(setAddressError({error: 'general.error.custom', errorArgs: {errorMessage: err.message}, errorHasContactLink: true}));
        }
    }
}

export const finalizeImport = () => (dispatch, getState, extraArgument) => {
    const {env} = extraArgument;
    const valid = selectValid(getState());
    if (!valid) {
        return;
    }
    const addressQuery = selectAddressQuery(getState());
    const importAddress = selectImportAddress(getState());
    const importSegment = selectImportSegment(getState());
    const importObjectParams = selectImportObjectParams(getState());
    if (env.isDevelopmentMode) {
        const immodropUrl = selectImmodropUrl(getState());
        console.log(`Finalizing import for ImmoDrop URL ${immodropUrl}`, importAddress, importSegment, importObjectParams);
    }
    dispatch(lockInDistinctAddress(addressQuery, importAddress, false));
    dispatch(setSegmentAndParameters({segment: importSegment, params: importObjectParams}));
    // Clear old import data
    dispatch(clear());
}
