import logoLight from "../images/logo_value_marktdaten.png";
import logoDark from "../images/logo_value_marktdaten_dark.png";

import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {
    selectError,
    selectErrorArgs,
    selectErrorHasContactLink,
    selectStatus,
    tryPerformLoginAsync
} from '../redux/loginSlice';
import {selectDarkTheme} from "../redux/themeSlice";
import {selectAppRoleLabel} from "../redux/envSlice";

import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Tag} from "primereact/tag";
import {IconField} from "primereact/iconfield";
import {InputIcon} from "primereact/inputicon";

import UiFragment from "../component/UiFragment";
import I18nErrorMessage from "../component/I18nErrorMessage";
import {handlePreventDefault} from '../util/events';


function LoginErrorMessage() {

    const status = useSelector(selectStatus);
    const error = useSelector(selectError);
    const errorArgs = useSelector(selectErrorArgs);
    const errorHasContactLink = useSelector(selectErrorHasContactLink);

    return (
        <I18nErrorMessage i18nKey={error} i18nArgs={errorArgs}
                          enabled={status === 'failed'} hasContactLink={errorHasContactLink}/>
    );
}

function LoginForm() {

    const {t} = useTranslation();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const isDarkTheme = useSelector(selectDarkTheme);

    /** @type {string} */
    const status = useSelector(selectStatus);

    const roleLabel = useSelector(selectAppRoleLabel);

    const dispatch = useDispatch();

    function handleLoginClick() {
        if (status === 'loading') {
            return;
        }
        dispatch(tryPerformLoginAsync(username, password));
    }
    return (
        <div className="flex-grow-1">
            <div className="flex align-items-center justify-content-center h-full">
                <form className="LoginForm surface-card p-4 mt-5 shadow-2 border-round w-full lg:w-6"
                      onSubmit={handlePreventDefault(handleLoginClick)}>
                    <div className="text-center mb-5">
                        <img src={isDarkTheme ? logoDark : logoLight} className="App-logo m-auto mb-5" width="50%"
                             alt="LOGO"/>
                        <div className="text-900 text-xl font-medium mb-3">
                            Real Estate Valuation Assistant
                            <UiFragment rendered={!!roleLabel}>
                                <Tag value={roleLabel} className="ml-3"/>
                            </UiFragment>
                        </div>
                    </div>

                    <UiFragment rendered={status === 'init'}>
                        <div className="text-center mb-5">
                            <i className="pi pi-spin pi-spinner" style={{fontSize: '10rem'}}></i>
                        </div>
                    </UiFragment>

                    <UiFragment rendered={status !== 'init'}>
                        <div className="grid formgrid">
                            <div className="field col-12 mt-3">
                                <IconField iconPosition="left" className="p-float-label">
                                    <InputIcon className="pi pi-user"/>
                                    <InputText id="inputLoginName" value={username} className="w-full"
                                               onChange={(e) => setUsername(e.target.value)}
                                               type="text" disabled={status === 'loading'}/>
                                    <label htmlFor="inputLoginName" className="block font-medium">
                                        {t('login.username')}
                                    </label>
                                </IconField>
                            </div>
                            <div className="field col-12 mt-3">
                                <IconField iconPosition="left" className="p-float-label">
                                    <InputIcon className="pi pi-lock"/>
                                    <InputText id="inputPassword" value={password} className="w-full"
                                               onChange={(e) => setPassword(e.target.value)}
                                               type="password" disabled={status === 'loading'}/>
                                    <label htmlFor="inputPassword" className="block font-medium">
                                        {t('login.password')}
                                    </label>
                                </IconField>
                            </div>
                            <div className="field col-12 mt-5">
                                <Button id="loginButton" className="w-full" disabled={status === 'loading'}
                                        label={status === 'loading' ? t('login.loggingIn') : t('login.login')}
                                        icon={status === 'loading' ? "pi pi-spin pi-spinner" : "pi pi-user"}/>
                            </div>
                            <div className="field col-12 mt-3 mb-0">
                                <LoginErrorMessage/>
                            </div>
                        </div>
                    </UiFragment>
                </form>
            </div>
        </div>
    );
}

export default LoginForm;