import {createSlice} from '@reduxjs/toolkit'
import {selectCredentials} from "./authSlice";
import {ErrorI18n} from "../i18n";
import {GeoRefModel} from "../model/GeoRefModel";
import {clearLocationInfo, queryLocationInfoAsync} from "./locationInfoSlice";
import {clearPoisInfo, queryPoisInfoAsync} from "./poisSlice";
import {clearIndicationData} from "./valueIndicationSlice";
import {resetObjectParameters} from "./objectParametersSlice";

/**
 * @typedef {"COORDINATE"|"HOUSE"|"STREET_SECTION"|"CITY_DISTRICT"|"POSTAL_CODE"|"CITY"|"STATE"|"COUNTRY"} GeoRefPrecision
 */

/**
 * @typedef GeoRefAddress
 * @property {number} lat Latitude
 * @property {number} lon Longitude
 * @property {GeoRefPrecision} precision Precision
 * @property {string} displayNameDE Display Name (DE)
 * @property {string} displayNameEN Display Name (EN)
 * @property {string} [postcode] Post Code (german "PLZ") (optional)
 * @property {string} [municipality] Municipality (german "Gemeinde") (optional)
 * @property {string} [street] Street (optional)
 * @property {string} [municipality_code] Municipality Code (german "GKZ") (optional)
 */

/**
 * @typedef SelectAddressData
 * @property {string} addressQuery
 * @property {string} addressQueryClean
 * @property {number} lat
 * @property {number} lon
 * @property {boolean} queryAmbiguous
 * @property {GeoRefAddress} addressMeta
 */

/**
 * @typedef AddressAmbiguousData
 * @property {GeoRefAddress[]} addresses
 */

export const addressSlice = createSlice({
    name: 'address',
    initialState: {
        /** @type {boolean} */
        initialized: false,
        /** @type {boolean} */
        loading: false,
        /** @type {SelectAddressData|null} */
        addressData: null,
        /** @type {AddressAmbiguousData|null} */
        addressAmbiguousData: null,
        /** @type {ApiQueryError|null} */
        queryError: null,
    },
    reducers: {
        clearAddress: (state) => {
            state.initialized = true;
            state.loading = false;
            state.addressData = null;
            state.addressAmbiguousData = null;
            state.queryError = null;
        },
        setLoading: (state) => {
            state.loading = true;
            state.addressData = null;
            state.addressAmbiguousData = null;
            state.queryError = null;
        },
        setQueryError: (state, action) => {
            const {error, errorArgs, hasContactLink} = action.payload;
            state.initialized = true;
            state.loading = false;
            state.addressData = null;
            state.addressAmbiguousData = null;
            /** @type {ApiQueryError} */
            state.queryError = {error, errorArgs: errorArgs || null, hasContactLink: hasContactLink || false};
        },
        ambiguousAddressSelected: (state, action) => {
            const {addresses} = action.payload;
            state.initialized = true;
            state.loading = false;
            state.addressData = null;
            state.addressAmbiguousData = {addresses};
            state.queryError = null;
        },
        distinctAddressSelected: (state, action) => {
            const {addressQuery, addressMeta, queryAmbiguous} = action.payload;
            state.initialized = true;
            state.loading = false;
            const addressQueryClean = addressMeta ? `${addressMeta.street}, ${addressMeta.postcode} ${addressMeta.municipality}` : addressQuery;
            /** @type {SelectAddressData} */
            state.addressData = {addressQuery, addressQueryClean, queryAmbiguous, addressMeta: addressMeta, lat: addressMeta.lat, lon: addressMeta.lon};
            state.addressAmbiguousData = null;
            state.queryError = null;
        }
    },
    selectors: {
        /** @return {boolean} */
        selectInitialized: (state) => state.initialized,
        /** @return {boolean} */
        selectLoading: (state) => state.loading,
        /** @return {SelectAddressData|null} */
        selectAddressData: (state) => state.addressData,
        /** @return {boolean} */
        selectAddressSelected: (state) => !!state.addressData,
        /** @return {AddressAmbiguousData|null}} */
        selectAddressAmbiguousData: (state) => state.addressAmbiguousData,
        /** @return {ApiQueryError|null} */
        selectError: (state) => state.queryError,
        /**
         * Selector for the value, which should be sent to further API calls as address parameter.
         * @param state redux state
         * @return {string|null} address query String
         */
        selectAddressParam: (state) => {
            if (!state.addressData)
                return null;
            if (state.addressData.queryAmbiguous) {
                // Send Lat/Lon, because address string did not yield a unique address
                return `${state.addressData.lat}/${state.addressData.lon}`
            }
            return state.addressData.addressQuery;
        }
    }
});

export const {
    clearAddress,
    setLoading,
    setQueryError,
    ambiguousAddressSelected,
    distinctAddressSelected
} = addressSlice.actions;

export const {
    selectInitialized,
    selectError,
    selectLoading,
    selectAddressSelected,
    selectAddressData,
    selectAddressAmbiguousData,
    selectAddressParam
} = addressSlice.selectors;


/**
 * Async thunk to query for an address.
 * @param {string} addressQuery address to search for
 * @param {number} [lat] optional Latitude of (ambiguous) address candidate to choose preemptively
 * @param {number} [lon] optional Longitude of (ambiguous) address candidate to choose preemptively
 * @return {RevaThunkAction<void>}
 */
export const searchAddressAsync = (addressQuery, lat, lon) => async (dispatch, getState, extraArgument) => {
    const {env, serviceApi} = extraArgument;
    const geoRefModel = new GeoRefModel(addressQuery);
    if (!geoRefModel.validateAndDispatchError((error, errorArgs) => dispatch(setQueryError({error, errorArgs})))) {
        return;
    }
    dispatch(setLoading());
    const credentials = selectCredentials(getState());
    try {
        if (env.isDevelopmentMode) {
            console.log(`GeoRef query for address: ${addressQuery}`)
        }
        const apiResponsePromise = serviceApi.performGeoRef(credentials, geoRefModel);
        const {ambiguous, addressMeta, addresses} = await geoRefModel.handleResponse(apiResponsePromise);
        if (ambiguous) {
            let selectedAmbiguousAddress = null;
            if (lat && lon) {
                // Try to preemptively choose one of the addresses by given lat and lon coordinates
                selectedAmbiguousAddress = addresses.find((addr) => addr.lat === lat && addr.lon === lon);
            }
            if (selectedAmbiguousAddress) {
                dispatch(lockInDistinctAddress(addressQuery, selectedAmbiguousAddress, true));
            } else {
                dispatch(ambiguousAddressSelected({addresses}))
            }
        } else {
            dispatch(lockInDistinctAddress(addressQuery, addressMeta, false));
        }
    } catch (err) {
        if (err instanceof ErrorI18n) {
            dispatch(setQueryError({error: err.message, errorArgs: {...err.messageArgs}, errorHasContactLink: true}));
        } else {
            dispatch(setQueryError({error: 'general.error.custom', errorArgs: {errorMessage: err.message}, errorHasContactLink: true}));
        }
    }
}

/**
 * Thunk to enter a found distinct address. Also triggers address-dependent queries.
 * @param {string} addressQuery entered address query
 * @param {GeoRefAddress} addressMeta distinct address meta
 * @param {boolean} queryAmbiguous flag if the distinct address was selected from a list of ambiguous addresses
 * @return {RevaThunkAction<void>}
 */
export const lockInDistinctAddress = (addressQuery, addressMeta, queryAmbiguous) => (dispatch) => {
    dispatch(distinctAddressSelected({addressQuery, addressMeta, queryAmbiguous}));
    // immediately start loading LocationInformation, which only requires the address to work
    dispatch(queryLocationInfoAsync());
    dispatch(queryPoisInfoAsync());
    // Reset previously entered object parameters
    dispatch(resetObjectParameters());
}

/**
 * Thunk to remove a selected distinct address. Also clear address-dependent data.
 * @return {RevaThunkAction<void>}
 */
export const removeDistinctAddress = () => (dispatch) => {
    dispatch(clearAddress());
    // Clear data, which depends on the address
    dispatch(clearLocationInfo());
    dispatch(clearPoisInfo());
    dispatch(clearIndicationData());
};

export default addressSlice.reducer
