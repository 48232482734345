import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Chip} from 'primereact/chip';
import {useDispatch, useSelector} from "react-redux";
import {
    lockInDistinctAddress,
    removeDistinctAddress,
    searchAddressAsync,
    selectAddressAmbiguousData,
    selectAddressData,
    selectError,
    selectInitialized,
    selectLoading
} from "../redux/addressSlice";
import {useEffect, useState} from "react";
import UiFragment from "../component/UiFragment";
import I18nErrorMessage from "../component/I18nErrorMessage";
import {Message} from "primereact/message";
import {useTranslation} from "react-i18next";
import OpenLayersMap from "../component/OpenLayersMap";
import {handlePreventDefault} from "../util/events";
import {LayerFactory} from "../util/maps";
import {useLocation, useNavigate} from "react-router-dom";
import {addAddressQuery, getAddressQueryFromLocation} from "../router";
import {InputIcon} from "primereact/inputicon";
import {IconField} from "primereact/iconfield";
import DialogImport from "./DialogImport";
import AddressDetails from "./address/AddressDetails";
import AddressCard from "./address/AddressCard";
import {LAYER_ALKIS, LAYER_BASEMAP, selectCapabilities} from "../redux/wmtsSlice";

/** @typedef {import("@remix-run/router/history").Location} Location */

/**
 * Selection list for address disambiguation.
 * @param {AddressAmbiguousData|null} ambiguousAddresses ambiguous addresses from result
 * @param {string} addressQuery entered query
 * @param {(GeoRefAddress) => void} highlightAmbiguousAddress function to highlight one of the addresses
 * @return {null|JSX.Element}
 * @constructor
 */
export function AmbiguousAddresses({ambiguousAddresses, addressQuery, highlightAmbiguousAddress}) {

    const dispatch = useDispatch();

    const onSelectAddress = (addressCandidate) => {
        dispatch(lockInDistinctAddress(addressQuery, addressCandidate, true));
    }

    if (!ambiguousAddresses)
        return null;

    return (
        <>
            {ambiguousAddresses.addresses.map((addressCandidate, index) => {
                return (
                    <div key={"candidate" + index} className="w-full mb-3">
                        <AddressCard addressMeta={addressCandidate} className="cursor-pointer hover:surface-300"
                                     onSelectAddress={onSelectAddress} onHoverAddress={highlightAmbiguousAddress}/>
                    </div>
                );
            })}
        </>
    )
}


function PageAddress() {

    const {t, i18n} = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();

    const addressQueryFromLocation = getAddressQueryFromLocation(location);
    const defaultAddress = addressQueryFromLocation ? addressQueryFromLocation.addressQuery : "";

    const [addressQuery, setAddressQuery] = useState(defaultAddress); // TODO: Move to Redux, overwrite with searchAddressAsync
    const [highlightedAddressCandidate, setHighlightedAddressCandidate] = useState(null);

    const [importDialogVisible, setImportDialogVisible] = useState(false);

    const addressLoading = useSelector(selectLoading);
    const addressInitialized = useSelector(selectInitialized);

    const addressData = useSelector(selectAddressData);
    const addressSelected = !!addressData;
    const addressSelectedQuery = (addressData ? addressData.addressQueryClean : "");

    const ambiguousAddresses = useSelector(selectAddressAmbiguousData);
    const hasAmbiguousAddresses = !!ambiguousAddresses;
    const highlightedAmbiguousAddress = (hasAmbiguousAddresses && ambiguousAddresses.addresses.length > 0)
        ? (highlightedAddressCandidate || ambiguousAddresses.addresses[0]) : null;

    /** @type {ApiQueryError|null} */
    const queryError = useSelector(selectError);
    const capabilities = useSelector(selectCapabilities);
    const alkisCapabilities = capabilities?.[LAYER_ALKIS];
    const basemapCapabilities = capabilities?.[LAYER_BASEMAP];

    const hasError = !!queryError;

    const dispatch = useDispatch();

    // Adapt "virtual" URL to selected address
    useEffect(() => {
        const targetUrl = "/address" + addAddressQuery(addressData);
        const currentUrl = location.pathname + (location.search ? location.search : "");
        if (addressInitialized && currentUrl !== targetUrl) {
            navigate(targetUrl);
        }
    }, [addressData, addressInitialized, location, navigate]);

    const onEnterAddress = handlePreventDefault(() => {
        if (!addressLoading && !addressSelected) {
            dispatch(searchAddressAsync(addressQuery));
            setHighlightedAddressCandidate(null);
        }
    });

    const onRemoveAddress = () => {
        setAddressQuery("");
        dispatch(removeDistinctAddress());
    };

    /**
     * Calculate zoom level from GeoRef data.
     * @param {GeoRefAddress|null} addressMeta
     * @return {number} zoom level
     */
    const zoomFromCandidate = (addressMeta) => {
        if (!addressMeta)
            return 11;
        if (addressMeta.precision === 'HOUSE')
            return 14;
        return 13;
    };

    /**
     * Create Map Model from address.
     * @param {GeoRefAddress} addressMeta the address metadata
     * @return {null|MapModel}
     */
    const createMapModel = (addressMeta) => {
        if (!addressMeta)
            return null;
        if (basemapCapabilities == null || alkisCapabilities == null)
            return null;
        return {
            view: {
                lat: addressMeta.lat,
                lon: addressMeta.lon,
                zoom: zoomFromCandidate(addressMeta)
            },
            layers: [
                LayerFactory.createOSMLayer("osm", {title: "OSM", type: "base"}),
                LayerFactory.createGeoserverWmtsLayer(LAYER_BASEMAP, undefined, undefined, "page_address_basemapde", undefined, {
                    params: {
                        attributions:'© <a target="_blank" href="https://basemap.de/">Basemap</a>'
                    },
                    title: "Amtliche Hintergrundkarte",
                    type: "base",
                    visible: false,
                    ...basemapCapabilities
                }),
                LayerFactory.createGeoserverWmtsLayer(LAYER_ALKIS, undefined, undefined, "page_address_alkis", undefined, {
                    params: {
                        attributions: "© ALKIS",
                    },
                    title: "Liegenschaftskataster Deutschland",
                    type: "base",
                    visible: false,
                    ...alkisCapabilities
                }),
                LayerFactory.createGeoserverWmsLayer("reva:brd_negativ", undefined),
                LayerFactory.createMarkerLayer(addressMeta, i18n)
            ],
            switcher: {
                enabled: true,
                options: {
                    reverse: true,
                    groupSelectStyle: "group",
                    activationMode: "click",
                    tipLabel: "Select layers", // TODO: i18n
                    collapseLabel: "-"
                }
            }
        };
    };

    /**
     * @type {MapModel|null}
     */
    let mapModel = null;
    if (addressData != null) {
        mapModel = createMapModel(addressData.addressMeta);
    } else if (highlightedAmbiguousAddress != null) {
        mapModel = createMapModel(highlightedAmbiguousAddress);
    }


    return (
        <main role="main" className="flex-grow-1 pt-3 px-4 py-4 w-12">
            <div className="flex flex-column w-full h-full">
                <h2 className="flex-0 flex mt-0">
                    <div className="flex-0 HeaderTextShared">{t('address.address')}:</div>
                    <form className="flex-1 flex" onSubmit={onEnterAddress}>
                        <UiFragment rendered={!addressSelected}>
                            <IconField iconPosition="left" className="flex-1">
                                <InputIcon className="pi pi-search"/>
                                <InputText id="address" placeholder={t('address.placeholder')}
                                           invalid={hasError || hasAmbiguousAddresses}
                                           className="w-full" value={addressQuery} disabled={addressLoading}
                                           onChange={(e) => setAddressQuery(e.target.value)}/>
                            </IconField>

                            <Button icon="pi pi-arrow-circle-right" label={t('address.query')} className="flex-0 ml-3"
                                    loading={addressLoading} onClick={onEnterAddress}/>
                        </UiFragment>
                        <UiFragment rendered={addressSelected}>
                            <Chip className="text-lg" label={addressSelectedQuery} removable
                                  onRemove={onRemoveAddress}/>
                        </UiFragment>
                    </form>
                    <UiFragment rendered={!addressSelected}>
                        <div className="HeaderToolbarRight">
                            <Button icon="pi pi-upload" label={t('address.import')} text
                                    onClick={(e) => {
                                        setImportDialogVisible(true);
                                        e.preventDefault();
                                    }}
                                    className="flex-0"/>
                        </div>
                    </UiFragment>
                </h2>
                <DialogImport visible={importDialogVisible} setVisible={setImportDialogVisible}/>

                <I18nErrorMessage errorObject={queryError}/>

                <UiFragment rendered={addressSelected}>
                    <div className="flex-grow-1 flex-shrink-0 flex">
                        <div className="AddressMetaLane flex-0 pr-3">
                            <AddressDetails address={addressData}/>
                        </div>
                        <div className="flex-1">
                            <div className="OpenLayersMapBackdrop w-full h-full">
                                <OpenLayersMap model={mapModel}/>
                            </div>
                        </div>
                    </div>
                </UiFragment>

                <UiFragment rendered={hasAmbiguousAddresses}>
                    <Message severity="warn" text={t('address.multipleFound')}
                             className="flex-0 mb-3"/>
                    <div className="flex-grow-1 flex-shrink-0 flex">
                        <div className="AddressMetaLane flex-0 relative">
                            <div className="w-full h-full pr-3 absolute overflow-x-auto">
                                <AmbiguousAddresses ambiguousAddresses={ambiguousAddresses} addressQuery={addressQuery}
                                                    highlightAmbiguousAddress={setHighlightedAddressCandidate}/>
                            </div>
                        </div>
                        <div className="flex-1">
                            <div className="OpenLayersMapBackdrop w-full h-full">
                                <OpenLayersMap model={mapModel}/>
                            </div>
                        </div>
                    </div>
                </UiFragment>
            </div>


        </main>
    );
}

export default PageAddress;