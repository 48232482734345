/**
 * Component for an SVG Icon, which references an SVG symbol already existing in DOM.
 * @param {string} svgRef reference to SVN symbol
 * @param {string} [className] optional CSS class names
 * @return {JSX.Element}
 * @constructor
 */
export function SvgIcon({svgRef, className}) {
    return (
        <svg width="1em" height="1em" className={className || null}>
            <use href={svgRef}></use>
        </svg>
    );
}
