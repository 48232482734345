import {createSelector} from "@reduxjs/toolkit";
import {selectAddressParam} from "./addressSlice";
import {selectAllParams, selectSegment} from "./objectParametersSlice";
import {selectSpecInputs} from "./specSlice";
import {selectCredentials, selectPrivilegeValueIndication} from "./authSlice";
import {ValueIndicationModel} from "../model/ValueInidcationModel";
import {ErrorI18n} from "../i18n";
import {setIndicationData, setLoading, setMissingPermissions, setQueryError} from "./valueIndicationSlice";

/** @typedef {import("react-router/dist/lib/hooks").NavigateFunction} NavigateFunction */

// TODO: cancel old Value Indication response, after concurrent new Value Indication request is sent

/**
 * Select the Payload JSOn to be sent to the value indication endpoint.
 * @type {(object) => IndicationInputData}
 */
export const selectCurrentIndicationPayload = createSelector(
    [selectAddressParam, selectSegment, selectAllParams, selectSpecInputs],
    (address, segment, params, inputSpec) => {
        if (!address || !segment || !params || !inputSpec || !inputSpec?.[segment])
            return null;

        // TODO: ObjectId?, Accounting?
        let payload = {address, segment};
        for (const [paramName, meta] of Object.entries(inputSpec[segment])) {
            if (typeof params[paramName] !== "undefined") {
                payload[paramName] = params[paramName];
            }
        }

        return payload;
    }
);

/**
 * Async thunk to run ValueIndication request.
 * @param {IndicationInputData} payload JSON Payload, including address, segment and all params
 * @return {RevaThunkAction<void>}
 */
export const queryValueIndicationAsync = (payload) => async (dispatch, getState, extraArgument) => {
    const {env, serviceApi} = extraArgument;

    if (!payload) {
        return;
    }
    if (!selectPrivilegeValueIndication(getState())) {
        if (env.isDevelopmentMode) {
            console.log("Skipping loading of Value Indication due to missing permissions");
        }
        dispatch(setMissingPermissions());
        return;
    }
    const valueIndicationModel = new ValueIndicationModel(payload, true);
    if (!valueIndicationModel.validateAndDispatchError((error, errorArgs) => dispatch(setQueryError({error, errorArgs})))) {
        return;
    }
    dispatch(setLoading());
    const credentials = selectCredentials(getState());
    try {
        if (env.isDevelopmentMode) {
            const {address, segment} = payload;
            console.log(`Value Indication query for address: ${address} and segment ${segment}`, payload);
        }
        const apiResponsePromise = serviceApi.performValueIndication(credentials, valueIndicationModel);
        const valueIndicationData = await valueIndicationModel.handleResponse(apiResponsePromise);
        dispatch(setIndicationData({requestData: valueIndicationModel.getPayload(), indicationData: valueIndicationData}));
    } catch (err) {
        if (err instanceof ErrorI18n) {
            dispatch(setQueryError({error: err.message, errorArgs: {...err.messageArgs}, errorHasContactLink: true}));
        } else {
            dispatch(setQueryError({error: 'general.error.custom', errorArgs: {errorMessage: err.message}, errorHasContactLink: true}));
        }
    }
}

export const queryValueIndicationWithCurrentParamsAsync = () => async (dispatch, getState) => {
    const payload = selectCurrentIndicationPayload(getState());
    console.log("Payload", payload);
    return dispatch(queryValueIndicationAsync(payload));
}
