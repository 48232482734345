import {TabView} from 'primereact/tabview';
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {matchPath, Outlet, useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectAddressData, selectInitialized} from "../redux/addressSlice";
import {selectDevelopMode} from "../redux/envSlice";
import {addAddressQuery, addAddressQueryFromLocation} from "../router";
import {getTabPanelWithPermission} from "../component/TabPanelWithPermission";
import {selectPrivileges} from "../redux/authSlice";
import {
    checkPermissionRule,
    PERMISSION_RULE_MARKET,
    PERMISSION_RULE_MARKET_TIMELINES
} from "../model/permissions";
import MissingPermissionIndicator from "../component/MissingPermissionIndicator";

/** @type {TabHeaderWithPermissionParams[]} */
const TABS = [
    {
        route: "description",
        headerI18n: "market.category.description",
        disabled: true
    },
    {
        route: "statistics",
        headerI18n: "market.category.statistics",
        disabled: true
    },
    {
        route: "timelines",
        headerI18n: "market.category.timelines",
        permissionRule: PERMISSION_RULE_MARKET_TIMELINES
    },
    {
        route: "market-data",
        headerI18n: "market.category.market_data",
        disabled: true
    }
]
const TAB_ROUTES = TABS.map(tab => tab.route);

function PageMarket() {

    const {t} = useTranslation();

    const navigate = useNavigate();
    const location = useLocation();
    const {pathname} = location;

    const addressData = useSelector(selectAddressData);
    const addressInitialized = useSelector(selectInitialized)
    const isDevelop = useSelector(selectDevelopMode);
    const privileges = useSelector(selectPrivileges);

    /** @type React.ReactElement[] */
    const tabs = TABS.map(tab => getTabPanelWithPermission(tab, t, privileges));

    // Redirect to address page, if this page is shown without a valid address selected
    useEffect(() => {
        if (addressInitialized && !addressData) {
            if (isDevelop) {
                console.log("No valid address selected: redirect to address page");
            }
            navigate("/address" + addAddressQueryFromLocation(location));
        }
    }, [addressInitialized, addressData, navigate, location, isDevelop]);

    let activeIndex = null;
    for (let index = 0; index < TAB_ROUTES.length; index++) {
        if (matchPath("/market/" + TAB_ROUTES[index], pathname)) {
            activeIndex = index;
            break;
        }
    }

    const onTabChange = (index) => {
        if (index >= 0 && index < TAB_ROUTES.length) {
            navigate("/market/" + TAB_ROUTES[index] + addAddressQuery(addressData));
        }
    };

    const hasAnyPermissions = checkPermissionRule(privileges, PERMISSION_RULE_MARKET);
    if (!hasAnyPermissions) {
        return (<MissingPermissionIndicator missingPermissions={true} />)
    }

    return (
        <main role="main" className="flex-grow-1 pt-3 px-4 py-4 w-12">
            <div className="flex flex-column w-full h-full">
                <h2 className="flex-0 flex mt-0">
                    <div className="flex-0 HeaderTextShared">{t('market.market')}:</div>
                    <TabView className="flex-1 HeaderTabView" panelContainerClassName="hidden" activeIndex={activeIndex}
                             onTabChange={(e) => onTabChange(e.index)}>
                        {tabs}
                    </TabView>
                </h2>
                <Outlet/>
            </div>
        </main>
    );
}

export default PageMarket;