import {createSelector, createSlice} from "@reduxjs/toolkit";
import {selectCredentials} from "./authSlice";
import {ErrorI18n} from "../i18n";
import {SpecModel} from "../model/SpecModel";
import {selectSegment} from "./objectParametersSlice";

const specSlice = createSlice({
    name: 'spec',
    initialState: {
        /** @type {boolean} */
        loading: false,
        /** @type {RevaSpec|null} */
        specData: null,
        /** @type {ApiQueryError|null} */
        queryError: null
    },
    reducers: {
        clearSpec: (state) => {
            state.loading = false;
            state.specData = null;
            state.queryError = null;
        },
        setLoading: (state) => {
            state.loading = true;
            state.specData = null;
            state.queryError = null;
        },
        setQueryError: (state, action) => {
            const {error, errorArgs, hasContactLink} = action.payload;
            state.loading = false;
            state.specData = null;
            /** @type {ApiQueryError} */
            state.queryError = {error, errorArgs: errorArgs || null, hasContactLink: hasContactLink || false};
        },
        setSpecData: (state, action) => {
            state.loading = false;
            /** @type {RevaSpec} */
            state.specData = action.payload;
            state.queryError = null;
        }
    },
    selectors: {
        /** @return {boolean} */
        selectLoading: (state) => state.loading,
        /** @return {RevaSpec|null} spec data, or null if no spec loaded */
        selectSpec: (state) => state.specData,
        /** @return {Record<Segment, object>|null} spec data, or null if no spec loaded */
        selectSpecInputs: (state) => state.specData?.inputs?.VALUE,
        /** @return {ApiQueryError|null} query error data, or null if no error */
        selectError: (state) => state.queryError
    }
})

export const {
    clearSpec,
    setLoading,
    setQueryError,
    setSpecData
} = specSlice.actions;

export const {
    selectLoading,
    selectSpec,
    selectSpecInputs,
    selectError
} = specSlice.selectors;

const selectParamFromArg = (state, parameter) => parameter;
export const selectInputParamForCurrentSegment = createSelector(
    [selectParamFromArg, selectSegment, selectSpecInputs],
    (parameter, segment, inputs) => inputs?.[segment]?.[parameter] || null
);
export const selectInputParamForCurrentSegmentCurried = (parameter) => (state) => selectInputParamForCurrentSegment(state, parameter);

/**
 * Async thunk to load the spec.
 * @return {RevaThunkAction<void>}
 */
export const querySpecAsync = () => async (dispatch, getState, extraArgument) => {
    const {env, serviceApi} = extraArgument;

    const specModel = new SpecModel();
    dispatch(setLoading());
    const credentials = selectCredentials(getState());
    try {
        if (env.isDevelopmentMode) {
            console.log(`Loading spec`)
        }
        const apiResponsePromise = serviceApi.loadIndicationSpec(credentials);
        const spec = await specModel.handleResponse(apiResponsePromise);
        if (!spec) {
            dispatch(setQueryError({error: 'general.error.invalid', errorHasContactLink: true}));
            return;
        }
        dispatch(setSpecData(spec));
    } catch (err) {
        if (err instanceof ErrorI18n) {
            dispatch(setQueryError({error: err.message, errorArgs: {...err.messageArgs}, errorHasContactLink: true}));
        } else {
            dispatch(setQueryError({error: 'general.error.custom', errorArgs: {errorMessage: err.message}, errorHasContactLink: true}));
        }
    }
}

export default specSlice.reducer;