import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {selectAddressData} from "../../redux/addressSlice";
import {LayerFactory} from "../../util/maps";
import OpenLayersMap from "../../component/OpenLayersMap";
import {PERMISSION_RULE_LOCATION_MICRO} from "../../model/permissions";
import MissingPermissionIndicator from "../../component/MissingPermissionIndicator";
import {LAYER_WOHNLAGEN_MIKRO_DECORATED, selectCapabilities, selectError, selectLoading} from "../../redux/wmtsSlice";
import LoadingIndicator from "../../component/LoadingIndicator";
import ErrorIndicator from "../../component/ErrorIndicator";

function MicroLocationLegend() {
    return (
        <>
            <h2>Mikrolage</h2>
            <p className="micromacro-location-classes">
                <span className="micromacro-location-class micromacro-location-class-5"></span>
                Sehr gute Lage [81-100]
                <br/>
                <span className="micromacro-location-class micromacro-location-class-4"></span>
                Gute Lage [61-80]
                <br/>
                <span className="micromacro-location-class micromacro-location-class-3"></span>
                Durchschnittliche Lage [41-60]
                <br/>
                <span className="micromacro-location-class micromacro-location-class-2"></span>
                Einfache Lage [21-40]
                <br/>
                <span className="micromacro-location-class micromacro-location-class-1"></span>
                Sehr einfache Lage [0-20]
            </p>
            <h2>Erläuterung</h2>
            <p>
                Grundlage der Wohnlagenklassen sind ca. 12,5 Mio. Objekte der VALUE Marktdatenbank für die Segmente
                EZFH, ETW und Mietwohnungen.
                Die Preisinformationen werden zunächst je Segment über die Zeit (Jahre) und den Raum (Wohnquartiere)
                aggregiert.
            </p>
            <p>
                Die aggregierten Preise werden segmentspezifisch über das jeweilige Bundesmittel als Teil-Index normiert
                und über die Zeit zu einem gewichteten Gesamtindex zusammengefasst.
            </p>
            <p>
                Die Makrolage repräsentiert den so abgeleiteten Index auf Ebene von Raumordnungregionen und Kreisen in
                Relation zum Bund.
                Die Mikrolage den Index auf Quartiersebene in Relation zur Raumordnungregion, dem Kreis- und der
                Gemeindeebene.
                Sowohl Makro- als auch Mikrolageindex werden über eine regionalspezifische Quantilsverteilung auf 5
                Klassen
                (1=sehr einfach bis 5=sehr gut) abgebildet.
            </p>
        </>
    );
}

function MicroLocationMap() {

    const {i18n} = useTranslation();
    const addressData = useSelector(selectAddressData);

    /** @type {SelectCapabilitiesData|null} */
    const capabilities = useSelector(selectCapabilities);

    const layerCapabilities = capabilities?.[LAYER_WOHNLAGEN_MIKRO_DECORATED];

    if (!layerCapabilities || !addressData)
        return null;

    /**
     * Map Model from address.
     * @type {MapModel}
     */
    const mapModel = {
        view: {
            ...addressData,
            zoom: 18,
            resetCenterOnChange: true,
            resetCenterOnZoomChange: true
        },
        layers: [
            LayerFactory.createGeoserverWmtsLayer(LAYER_WOHNLAGEN_MIKRO_DECORATED, addressData, undefined, "location_micro_decorated", undefined, {
                ...layerCapabilities
            }),
            LayerFactory.createMarkerLayer(addressData.addressMeta, i18n)
        ]
    };

    return (
        <div className="OpenLayersMapBackdrop w-full h-full">
            <OpenLayersMap model={mapModel} legend={<MicroLocationLegend/>}/>
        </div>
    );
}

export default function TabMicroLocation() {

    const capabilitiesLoading = useSelector(selectLoading);
    const capabilitiesError = useSelector(selectError);

    return (
        <div className="flex-1">
            <MissingPermissionIndicator permissionRule={PERMISSION_RULE_LOCATION_MICRO}>
                <ErrorIndicator errorObject={capabilitiesError} titleI18nKey="maps.error"/>
                <LoadingIndicator loading={capabilitiesLoading}/>
                <MicroLocationMap/>
            </MissingPermissionIndicator>
        </div>
    );
}