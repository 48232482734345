import {useSelector} from "react-redux";
import {selectIncomeValueAvailable, selectIncomeValueData, selectLoading} from "../../redux/valueIndicationSlice";
import IndicationTable from "./IndicationTable";
import MissingDataIndicator from "../../component/MissingDataIndicator";
import LoadingIndicator from "../../component/LoadingIndicator";

export default function TabIncomeValue() {

    const incomeValueData = useSelector(selectIncomeValueData);
    const incomeValueAvailable = useSelector(selectIncomeValueAvailable);
    const loading = useSelector(selectLoading);

    return (
        <>
            <LoadingIndicator loading={loading} />
            <MissingDataIndicator dataAvailable={incomeValueAvailable || loading}/>
            <IndicationTable endpoint="INCOME_VALUE" indicationDetails={incomeValueData} />
        </>
    );
}