/**
 * Meta-Component like &lt;ui:fragment/&gt; in JSF: renders its children, only if rendered-attribute is true.
 * @param rendered {boolean} rendered attribute
 * @param children {JSX} child JSX elements
 * @return {JSX|null} rendered children
 */
function UiFragment({rendered, children}) {
    if (!rendered)
        return null;
    return children;
}

export default UiFragment;