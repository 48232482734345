import {FailedValidation, ValidationErrorI18n} from './Validation';
import {WebError} from "../api";
import {ErrorI18n} from "../i18n";
import {GeneralModel} from "./GeneralModel";

/**
 * @typedef {object} AuthResponse
 * @property {AuthData} authData
 * @property {string} token
 * @property {string[]} privileges
 */

/**
 * Model for authentication purposes.
 */
export class AuthenticationModel extends GeneralModel {

    /**
     * Model for authentication.
     * @param {string} username the username for login
     * @param {string} password the password for login
     */
    constructor(username, password) {
        super();
        this.username = username;
        this.password = password;
    }

    /**
     * Validate input data.
     */
    validate() {
        if (!this.username && !this.password) {
            throw new ValidationErrorI18n(
                [new FailedValidation("username"), new FailedValidation("password")],
                'login.error.usernameAndPasswordEmpty'
            );
        }
        if (!this.username) {
            throw new ValidationErrorI18n(new FailedValidation("username"), 'login.error.usernameEmpty');
        }
        if (!this.password) {
            throw new ValidationErrorI18n(new FailedValidation("password"), 'login.error.passwordEmpty');
        }
    }

    /**
     * Handle and validate response data.
     * @param apiResponsePromise {Promise<any>} response promise from API call
     * @return {Promise<AuthResponse>} Promise containing the privileges of the login user
     */
    async handleResponse(apiResponsePromise) {
        let responseJson = {};
        try {
            /** @type {object} */
            responseJson = await apiResponsePromise;
        } catch (e) {
            if (e instanceof WebError) {
                switch (e.status) {
                    case 401:
                        switch (e.getCustomErrorMessage()) {
                            case "bad credentials": throw new ErrorI18n('login.error.401');
                            case "User must be in the role rest": throw new ErrorI18n('login.error.missingRoleRest');
                            default:
                        }
                        break;
                    case 503: throw new ErrorI18n('general.error.503');
                    case 500: throw new ErrorI18n('general.error.unspecific');
                    default:
                }
                if (e.getCustomErrorMessage()) {
                    throw new ErrorI18n('general.error.custom', {errorMessage: e.getCustomErrorMessage()});
                }
            }
            throw new ErrorI18n('general.error.invalid');
        }
        return AuthenticationModel.parseResponseJson(responseJson);
    }

    /**
     * Parse response JSON.
     * @param {object} responseJson
     * @return {AuthResponse}
     */
    static parseResponseJson(responseJson) {
        const privileges = Object.entries(responseJson.permissions || {})
            .filter(([, allowed]) => allowed)
            .map(([privilege]) => privilege)
            .sort();
        if (!privileges.includes("geoRef")) {
            throw new ErrorI18n('login.error.missingRoleGeoref');
        }
        const token = responseJson.jwt;
        const authData = {
            username: responseJson.username,
            license: responseJson.license,
            licenseId: responseJson.licenseId
        }
        return {authData, token, privileges};
    }

}