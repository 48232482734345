import {GeneralModel} from "./GeneralModel";
import {ValidationErrorI18n} from "./Validation";
import {WebError} from "../api";
import {ErrorI18n} from "../i18n";
import ApiParamBuilder from "../util/queryParams";

/**
 * @typedef {object} IndicationInputData key-value-map containing all input params for the Value Indication
 * @property {string} segment segment of query
 * @property {string} address address of query
 * @property {string|null} [object_id] optional Object ID
 * @property {string|null} [accounting] optional Accounting flag
 */

/**
 * @typedef {object} ValueIndicationData
 * @property {object} [income_value_details] details for income value indication
 * @property {object} [real_asset_value_details] details for income value indication
 * @property {object} [comparative_value_details] details for income value indication
 * @property {object} [comparative_price_details] details for income value indication
 */

/**
 * @typedef {ValueIndicationData|null} ValueIndicationDataOpt
 */

export class ValueIndicationModel extends GeneralModel {
    /**
     * Create value indication model.
     * @param {IndicationInputData} inputData input data for indication
     * @param {boolean} [loadDetails] flag of details for indication should be loaded
     *
     */
    constructor(inputData, loadDetails) {
        super()
        this.inputData = inputData || {};
        this.loadDetails = !!loadDetails;
    }

    validate() {
        if (!this.inputData.segment) {
            throw new ValidationErrorI18n("segment", "indication.error.missing_segment")
        }
        if (!this.inputData.address) {
            throw new ValidationErrorI18n("address", "indication.error.missing_address")
        }
        if (this.inputData.address.length < 4) {
            throw new ValidationErrorI18n("address", "address.error.too_short")
        }
    }

    /**
     * Handle and validate response data.
     * @param apiResponsePromise {Promise<object>} response promise from API call
     * @return {Promise<ValueIndicationData>} Promise containing result from the indication
     */
    async handleResponse(apiResponsePromise) {
        try {
            return await apiResponsePromise;
        } catch (e) {
            if (e instanceof WebError) {
                switch (e.status) {
                    case 401: throw new ErrorI18n('general.error.401');
                    case 403: throw new ErrorI18n('general.error.403');
                    case 409: throw new ErrorI18n('address.error.409');
                    case 422: throw new ErrorI18n('address.error.422');
                    case 503: throw new ErrorI18n('general.error.503');
                    case 500: throw new ErrorI18n('general.error.unspecific');
                    default:
                }
                if (e.getCustomErrorMessage()) {
                    throw new ErrorI18n('general.error.custom', {errorMessage: e.getCustomErrorMessage()});
                }
            }
            throw new ErrorI18n('general.error.invalid');
        }
    }

    /**
     * Get request payload.
     * @return {object} JSON payload
     */
    getPayload() {
        return this.inputData;
    }

    /**
     * Get additional Query Parameters.
     * @return {string} request query parameters
     */
    getQueryParameters() {
        return new ApiParamBuilder()
            .addParam("details", this.loadDetails)
            .build()
    }

}