import {useSelector} from "react-redux";
import {selectLoading, selectRealAssetValueAvailable, selectRealAssetValueData} from "../../redux/valueIndicationSlice";
import IndicationTable from "./IndicationTable";
import MissingDataIndicator from "../../component/MissingDataIndicator";
import LoadingIndicator from "../../component/LoadingIndicator";

export default function TabRealAssetValue() {

    const realAssetValueData = useSelector(selectRealAssetValueData);
    const realAssetValueAvailable = useSelector(selectRealAssetValueAvailable);
    const loading = useSelector(selectLoading);

    return (
        <>
            <LoadingIndicator loading={loading} />
            <MissingDataIndicator dataAvailable={realAssetValueAvailable || loading}/>
            <IndicationTable endpoint="REAL_ASSET_VALUE" indicationDetails={realAssetValueData}/>
        </>
    );

}