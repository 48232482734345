/**
 * @typedef {object} ParsedJwtToken
 * @property {string} user
 * @property {number} licenseId
 * @property {string} license
 * @property {Date} issuedAt
 * @property {Date} expireDate
 * @property {boolean} expired
 */

/**
 * Parse JWT Token and extract Parameters.
 * @param {string} token
 * @return {null | ParsedJwtToken}
 */
export function parseToken(token) {
    if (!token)
        return null;
    try {
        const parsedSegments = token.split('.');
        if (!Array.isArray(parsedSegments) || parsedSegments.length !== 3) {
            console.error('Invalid JWT token format:', token);
            return null;
        }
        // Index 0 = algorithm, Index 1 = parameters, Index 2 = Signature
        const parameters = JSON.parse(atob(parsedSegments[1]));
        const expireDate = new Date(parameters['exp'] * 1000)
        return {
            user: /** @type {string} */ parameters['sub'],
            licenseId: /** @type {number} */ parameters['lid'],
            license: /** @type {string} */ parameters['lic'],
            issuedAt: new Date(parameters['iat'] * 1000),
            expireDate: expireDate,
            expired: expireDate.valueOf() < (new Date()).valueOf()
        };
    } catch (e) {
        console.error('Error parsing JWT token', e);
        return null;
    }
}